import React, { Component } from 'react'


export default class Header extends Component {
    render() {
        let { surveyLogo, type } = this.props;

        return (
            <>
                {/* <header className=" h-16 flex items-center mb-6 mt-4">
                    <div className=" capitalize w-full px-8 mx-auto flex justify-between  ">
                    <div className="flex items-center"> <img src={surveyLogo} className="w-32" /></div>
                    <div className={"flex items-center "+(type===2?" -mr-12":"")}> <img src="img/logo/happypluslogo.png" className="w-32" /></div>
                    </div>
                </header> */}
            </>
        )
    }
}
