import React, { Component } from 'react';
import Header from '../Components/Header';
import HeatMap from './chart/HeatMap';

class Employee extends Component {
    render() {

        let { getQuestion2OthersResultFilterData, getQuestionOthersMultiResultFilterData, getEMPFilterLabel, getOrgIndexV2Data, getSurveyData, getDemographicIndexMultiFilterEngCompData, surveyLogo, CountRestrict } = this.props;
    

        let FinalData = getQuestionOthersMultiResultFilterData;


        return (
            <div className="mb-12">
               

                    {FinalData && FinalData.length>0?
                        FinalData.map((item,index0)=>
                        <>
                        {/* <div className="text-gray-500 font-bold p-4 text-xl">{item.xAxis}</div> */}
                        {index0?
                            <h3 className="page-break"></h3>
                        :null}

                        <div className='pt-4' >
                        <Header surveyLogo={surveyLogo}/>

                        <div className>
                            <div className="capitalize w-full px-8 py-6 ">
                            <div className="text-indigo-700 opacity-90">
                            </div>
                            <h1 className="text-2xl text-[#3D405B] font-semibold border-b border-[#DDDDDD] pb-4 capitalize">NPS Demographic - {getEMPFilterLabel(item.xAxis)} </h1>
                            </div>
                        </div>
                        </div>


                        {item && item.Data && item.Data.length>0?
                        item.Data.map((itemData, index)=>
                        <>

                            {index%10===0 && index!==0?
                            <>
                            <div className="font-bold px-2 text-left text-gray-700 w-full">Continue...</div>
                            <div className="page-break"/>
                            </>
                            :null}


                            <HeatMap
                                itemData={itemData}
                                axisData={item.xAxis}
                                overallData={getQuestion2OthersResultFilterData}

                            />

                        </>
                        ):null}




                        </>
                        ):null}


                 
            </div>
        )
    }
}
export default Employee