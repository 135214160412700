import React from "react";
import ReactEcharts from 'echarts-for-react';

function HitachiReport() {

    const MultiPieCircle = {
        series: [
            {
                type: 'pie',
                radius: ['65%', '80%'], // Outer circle
                startAngle: 180, // Start angle of the progress (0 degrees is the top)
                // endAngle: 45, // End angle to create a full circle effect
                data: [
                    { value: 60, itemStyle: { color: '#66BB6A', borderRadius: '50%', } },
                    { value: 40, itemStyle: { color: '#66BB6A29', } },

                ],
                label: {
                    show: false, // Hide the label by default 
                    position: 'center',
                    formatter: '50%',
                    fontSize: 16,
                    fontWeight: 'bold',
                    color: '#212121', // Text color
                    fontFamily: 'Poppins, sans-serif', // Title font family
                },
                labelLine: {
                    show: false,
                },
                emphasis: {
                    label: {
                        show: true, // Show the label on hover
                    },
                },
            },
            {
                type: 'pie',
                radius: ['50%', '63%'], // Middle circle
                startAngle: 180, // Start angle of the progress (0 degrees is the top)
                data: [
                    { value: 55, itemStyle: { color: '#FFA726', borderRadius: '50%' } },
                    { value: 45, itemStyle: { color: '#FFA72629' } },
                ],
                label: {
                    show: false, // Hide the label by default
                    position: 'center',
                    formatter: '55%',
                    fontSize: 16,
                    fontWeight: 'bold',
                    color: '#212121', // Text color
                    fontFamily: 'Poppins, sans-serif', // Title font family
                },
                labelLine: {
                    show: false,
                },
                emphasis: {
                    label: {
                        show: true, // Show the label on hover
                    },
                },
            },
            {
                type: 'pie',
                radius: ['35%', '48%'], // Inner circle
                startAngle: 180, // Start angle of the progress (0 degrees is the top)
                data: [
                    { value: 50, itemStyle: { color: '#EF5350', borderRadius: '50%', } },
                    { value: 50, itemStyle: { color: '#F4433629' } },
                ],
                label: {
                    show: false, // Hide the label by default
                    position: 'center',
                    formatter: '60%',
                    fontSize: 16,
                    fontWeight: 'bold',
                    color: '#212121', // Text color
                    fontFamily: 'Poppins, sans-serif', // Title font family
                },
                labelLine: {
                    show: false,
                },
                emphasis: {
                    label: {
                        show: true, // Show the label on hover
                    },
                },
            },
        ],
    };
    // Function to get the chart options
    const getOption = () => {
        return {
            // title: {
            //     text: 'Stacked Horizontal Bar Chart',
            // },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow', // Shadow is for bar chart tooltip
                },
            },
            // legend: {
            //     data: ['Value 1', 'Value 2', 'Value 3'],
            // },

            xAxis: {
                type: 'value',
                axisLabel: {
                    show: false, // Hide X-axis labels (numbers)
                },
                axisLine: {
                    show: false, // Hide the X-axis line
                },
                splitLine: {
                    show: false, // Hide the grid lines on the X-axis
                },
            },
            yAxis: {
                type: 'category',
                data: ['Overall Satisfaction', 'Pride', 'Trust', 'Confidence', 'Delivery on Promise', 'Promote/Passion'],
                axisLabel: {
                    color: '#212121', // Y-axis labels text color 
                    fontWeight: '600', // Make Y-axis labels bold
                    fontSize: 12, // Set font size for Y-axis labels
                    fontFamily: 'Poppins, sans-serif', // Title font family
                },

                axisLine: {
                    show: false, // Hide the X-axis line
                },
                splitLine: {
                    show: false, // Hide the grid lines on the X-axis
                },
            },
            series: [
                {
                    name: 'Extremely Dissatisfied',
                    type: 'bar',
                    stack: 'total', // Stack bars on top of each other
                    label: {
                        show: true,
                        color: '#000', // Series labels text color
                    },
                    data: [11, 17, 22, 25, 10, 15],
                    color: '#EF5350',

                },
                {
                    name: 'Very Dissatisfied',
                    type: 'bar',
                    stack: 'total',
                    label: {
                        show: true,
                        color: '#000', // Series labels text color

                    },
                    data: [17, 17, 22, 25, 10, 15],
                    color: '#EF5350BF',
                },
                {
                    name: 'Somewhat Dissatisfied',
                    type: 'bar',
                    stack: 'total',
                    label: {
                        show: true,
                        color: '#000', // Series labels text color

                    },
                    data: [22, 17, 22, 25, 10, 15],

                    color: '#EF535080',
                },
                {
                    name: 'Somewhat Satisfied',
                    type: 'bar',
                    stack: 'total',
                    label: {
                        show: true,
                        color: '#000', // Series labels text color

                    },
                    data: [25, 17, 22, 25, 10, 15],

                    color: '#66BB6A80',
                },
                {
                    name: 'Very Satisfied',
                    type: 'bar',
                    stack: 'total',
                    label: {
                        show: true,
                        color: '#000', // Series labels text color

                    },
                    data: [10, 17, 22, 25, 10, 15],

                    color: '#66BB6ABF',
                },
                {
                    name: 'Extremely Satisfied',
                    type: 'bar',
                    stack: 'total',
                    label: {
                        show: true,
                        color: '#000', // Series labels text color

                    },
                    data: [15, 17, 22, 25, 10, 15],

                    color: '#66BB6A',
                },
            ],

            grid: {
                left: '6px',
                right: '10px',
                bottom: '0px',
                top: '4px',
                containLabel: true,
            },


        };
    };
    const option = {
        series: [
            {
                type: 'pie',
                radius: ['60%', '90%'], // Adjust thickness of the circular progress bar
                startAngle: 225, // Start angle of the progress (0 degrees is the top)
                endAngle: 45, // End angle to create a full circle effect
                data: [
                    {
                        value: '75',
                        name: 'Progress',
                        itemStyle: {
                            color: '#7E57C2', // Progress color
                            borderRadius: '50%', // Simulate border-radius (but this won't directly apply)
                        },
                    },
                    {
                        value: '100',
                        name: 'Remaining',
                        itemStyle: {
                            color: '#7E57C214', // Background color
                        },
                    },
                ],
                label: {
                    show: true,
                    position: 'center',
                    formatter: `{d}%`, // Display percentage value in the center
                    fontSize: 16,
                    fontWeight: 'bold',
                    color: '#212121', // Text color
                    fontFamily: 'Poppins, sans-serif', // Title font family
                },
                labelLine: {
                    show: false, // Hide the line connecting the label
                },
                emphasis: {
                    scale: false, // Disable scaling on hover
                },
            },
        ],
    };

    const optionCircleSmall = {
        series: [
            {
                type: 'pie',
                radius: ['60%', '80%'], // Adjust thickness of the circular progress bar
                startAngle: 225, // Start angle of the progress (0 degrees is the top)
                endAngle: 45, // End angle to create a full circle effect
                data: [
                    {
                        value: '75',
                        name: 'Progress',
                        itemStyle: {
                            color: '#7E57C2', // Progress color
                            borderRadius: '50%', // Simulate border-radius (but this won't directly apply)
                        },
                    },
                    {
                        value: '100',
                        name: 'Remaining',
                        itemStyle: {
                            color: '#7E57C214', // Background color
                        },
                    },
                ],
                label: {
                    show: true,
                    position: 'center',
                    formatter: `{d}%`, // Display percentage value in the center
                    fontSize: 12,
                    fontWeight: 'bold',
                    color: '#212121', // Text color
                    fontFamily: 'Poppins, sans-serif', // Title font family
                },
                labelLine: {
                    show: false, // Hide the line connecting the label
                },
                emphasis: {
                    scale: false, // Disable scaling on hover
                },
            },
        ],
    };

    const optionPie = {
        title: {
            show: false,
        },
        tooltip: {
            trigger: 'item',
        },
        legend: {
            show: false,
            orient: 'horizontal',
            bottom: 'center',
        },
        series: [
            {
                name: 'Sales',
                type: 'pie',
                radius: '90%',
                data: [
                    {
                        value: 10,
                        name: 'self',
                        itemStyle: {
                            color: '#42A5F5', // Custom color for Product A
                        },
                        label: {
                            show: false,
                            formatter: '10', // Displays the name and value
                            itemStyle: {
                                color: '#fff', // Custom color for Product A
                            },
                        },
                    },
                    {
                        value: 6,
                        name: 'Cross-functional',
                        itemStyle: {
                            color: '#7E57C2', // Custom color for Product A
                        },
                        label: {
                            show: false,
                            formatter: '6', // Displays the name and value
                            itemStyle: {
                                color: '#fff', // Custom color for Product A
                            },
                        },
                    },
                ],
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)',
                    },
                },
            },
        ],
        grid: {
            left: '6px',
            right: '10px',
            bottom: '0px',
            top: '4px',
            containLabel: true,
        },

    };

    const elements = Array.from({ length: 12 });
    const elements10 = Array.from({ length: 5 });
    const elements4 = Array.from({ length: 4 });
    const elements2 = Array.from({ length: 12 });
    const elementsProto2 = Array.from({ length: 3 });
    const elementsPie = Array.from({ length: 3 });
    const tableSix = Array.from({ length: 6 });
    return (
        <div>
            {/* page cover 1 */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>

                <div className="flex flex-col space-y-8 w-full ">
                    <img className="w-48 mx-auto" src="./img/hitachi-logo-1-1.svg" />
                    <h1 className="text-5xl font-semibold capitalize   text-[#E50026]  text-center">
                        Pulse
                    </h1>
                    <p className="text-center text-[#212121]/80 italic font-medium">Cash Operations  | 07 August, 2024</p>
                    <img className="w-full -mx-6" src='./img/pulse-cover-img.webp' />
                    <p className="text-center text-[#212121] font-medium">This report is confidential and should not be distributed without permission
                    </p>
                    <p className="text-sm text-[#212121]/80 text-center">Copyright © 2024 HappyPlus, Inc. All rights reserved.</p>
                </div>

            </section>
            {/* page prototype 2 */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Pulse Report</p>
                    <p className='font-medium italic text-base'>Cash Operations  | 12 August, 2024</p>
                </div>

                <div className="flex flex-col space-y-6 w-full ">
                    <h1 className="text-2xl font-medium capitalize  bg-[#212121]/90 text-white p-4">
                        Respondent summary
                    </h1>
                    <div className="grid grid-cols-3 divide-x-2">
                        {elementsPie.map((_, index) => (
                            <div className="space-y-4 ">
                                <p class="text-xl font-semibold text-[#212121] text-center">Invited</p>
                                <div className="flex justify-center">
                                    <ReactEcharts option={optionPie} style={{ height: '200px', width: '100%' }} className="" />
                                </div>
                                <div className="flex justify-center space-x-4">
                                    <div className="flex items-center ">
                                        <div
                                            className="p-1.5 rounded-sm bg-[#7E57C2]"
                                        />
                                        <p className="ml-1.5">Self</p>
                                    </div>
                                    <div className="flex items-center ">
                                        <div
                                            className="p-1.5 rounded-sm bg-[#42A5F5]"
                                        />
                                        <p className="ml-1.5">Cross-functional</p>
                                    </div>

                                </div>
                            </div>
                        ))}
                    </div>
                    <p class="text-xl font-semibold text-[#212121]">Sub-department Wise</p>
                    <table className="w-full bg-[#F1F5F9] divide-y-2 divide-white">
                        <thead className="divide-x-2 divide-white bg-[#EAEFF4] ">
                            <th className="p-3 text-base font-semibold text-[#212121] text-right w-[30%]"> </th>
                            <th colSpan={3} className="p-3 text-base font-semibold text-[#212121] w-[35%]">Self</th>
                            <th colSpan={3} className="p-3 text-base font-semibold text-[#212121] w-[35%]">Cross-functional</th>
                        </thead>
                        <tbody className="divide-y-2 divide-white">
                            <tr className="text-sm font-medium divide-x-2 divide-white bg-[#EAEFF4] ">
                                <td className="p-3 text-left">Sub-department</td>
                                <td className="p-3 text-center">Invited</td>
                                <td className="p-3 text-center">Completed</td>
                                <td className="p-3 text-center">Completion
                                    Rate %</td>
                                <td className="p-3 text-center">Invited</td>
                                <td className="p-3 text-center">Completed</td>
                                <td className="p-3 text-center">Completion
                                    Rate %</td>
                            </tr>

                            {elementsProto2.map((_, index) => (
                                <tr className="text-sm font-normal divide-x-2 divide-white ">
                                    <td className="p-3  font-normal text-left">Cash operation A</td>
                                    <td className="p-3 text-center">7</td>
                                    <td className="p-3 text-center">3</td>
                                    <td className="p-3 text-center">30%
                                    </td>
                                    <td className="p-3 text-center">4</td>
                                    <td className="p-3 text-center">1</td>
                                    <td className="p-3 text-center">25%
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </section>
            {/* page 4    ICE Index Summary*/}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Pulse Report</p>
                    <p className='font-medium italic text-base'>Cash Operations  | 12 August, 2024</p>
                </div>

                <div className="flex flex-col space-y-4 w-full ">
                    <h1 className="text-2xl font-medium capitalize  bg-[#212121]/90 text-white p-4">
                        ICE Index Summary
                    </h1>

                    <div className='space-x-4  flex items-start'>
                        <div className=" w-60 flex-shrink-0 space-y-6">
                            <p className='text-xl font-semibold text-[#212121] '>ICE Index Score</p>
                            <ReactEcharts option={option} style={{ height: '200px', width: '200px' }} />
                        </div>

                        <div className="w-full space-y-6 border-l pl-4">
                            <p className='text-xl font-semibold text-[#212121] '>ICE Index Themes Score</p>

                            <div className="space-y-2 ">
                                {tableSix.map((_, index) => (
                                    <div className="flex items-center space-x-2 text-sm">
                                        <span className="text-[#212121]/70 w-40 flex-shrink-0 font-medium text-right">Delivery on Promise </span>
                                        <div className="bg-[#7e58c0]/10 w-full h-8 overflow-hidden flex items-center space-x-2">
                                            <div
                                                className="bg-[#7e58c0] w-3/6 h-8"
                                            />
                                            <span className="text-xs font-medium">75%</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <table className="w-full bg-[#F1F5F9] divide-y-2 divide-white">
                        <thead className="bg-[#EAEFF4] ">
                            <th rowSpan={2} className="p-2 px-4 text-base font-semibold text-[#212121] text-left border-r-2 border-white w-36 ">Sub-department</th>
                            <th rowSpan={2} className="p-2 text-base font-semibold text-[#212121] border-r-2 border-white  w-24">ICE Index
                                Score</th>
                            <th rowSpan={1} colSpan={6} className="p-2 py-3 text-base font-semibold text-[#212121]  border-b-2 border-white">Themes Score</th>
                            <tr className="divide-x-2 divide-white ">
                                <th colSpan={1} className="p-2 py-3 text-xs font-semibold text-[#212121] w-20 ">Overall
                                    Satisfaction</th>
                                <th colSpan={1} className="p-2 py-3 text-xs font-semibold text-[#212121] w-20">Pride </th>
                                <th colSpan={1} className="p-2 py-3 text-xs font-semibold text-[#212121] w-20">Trust </th>
                                <th colSpan={1} className="p-2 py-3 text-xs font-semibold text-[#212121] w-20">Confidence </th>
                                <th colSpan={1} className="p-2 py-3 text-xs font-semibold text-[#212121] w-20">Delivery on Promise </th>
                                <th colSpan={1} className="p-2 py-3 text-xs font-semibold text-[#212121] w-20">Promote/ Passion  </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y-2 divide-white">
                            {elementsPie.map((_, index) => (
                                <tr className="text-sm font-normal divide-x-2 divide-white ">
                                    <td className="p-2 px-4  font-normal text-left">Cash operation A</td>
                                    <td className="p-2 text-center">
                                        <div className="flex justify-center">
                                            <ReactEcharts option={optionCircleSmall} style={{ height: '100px', width: '100px' }} />
                                        </div>
                                    </td>
                                    <td className="p-2 text-center">100%</td>
                                    <td className="p-2 text-center">100%</td>
                                    <td className="p-2 text-center">100%</td>
                                    <td className="p-2 text-center">100%</td>
                                    <td className="p-2 text-center">100%</td>
                                    <td className="p-2 text-center">100%</td>


                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            </section>
            {/* page 5 ICE Index Themes Distribution */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Pulse Report</p>
                    <p className='font-medium italic text-base'>Cash Operations  | 12 August, 2024</p>
                </div>

                <div className="flex flex-col space-y-4 w-full ">
                    <h1 className="text-2xl font-medium capitalize  bg-[#212121]/90 text-white p-4">
                        ICE Index Themes Distribution
                    </h1>

                    <div className="space-y-4 ">

                        <div className="flex items-center  justify-center bg-[#F1F5F9] p-2 px-4 ">
                            <div className="flex flex-wrap justify-start gap-2 text-xs text-[#212121]/70   ">
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF5350]"

                                    />
                                    <p className="ml-1.5">Extremely Dissatisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF5350BF]"
                                    />
                                    <p className="ml-1.5">Very Dissatisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF535080]"
                                    />
                                    <p className="ml-1.5">Somewhat Dissatisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6A80]"
                                    />
                                    <p className="ml-1.5">Somewhat Satisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6ABF]"
                                    />
                                    <p className="ml-1.5">Very Satisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6A]"
                                    />
                                    <p className="ml-1.5">Extremely Satisfied</p>
                                </div>
                            </div>

                        </div>

                        {elements4.map((_, index) => (
                            <div className="space-y-4 ">
                                <p className="text-lg font-semibold text-[#212121] bg-[#F1F5F9] p-2 px-4"> Cash Operation A</p>
                                <div className="grid grid-cols-1 gap-1">
                                    {tableSix.map((_, index) => (
                                        <div className="flex items-center space-x-4">
                                            <span className="text-sm w-40 flex-shrink-0 text-right">Overall Satisfaction</span>
                                            <div className="w-full flex items-center text-sm ">
                                                <div className="w-[10%] bg-[#EF5350] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[20%] bg-[#EF5350BF] h-6 flex items-center justify-center font-medium">20%</div>
                                                <div className="w-[30%] bg-[#EF535080] h-6 flex items-center justify-center font-medium">30%</div>
                                                <div className="w-[10%] bg-[#66BB6A80] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[10%] bg-[#66BB6ABF] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[20%] bg-[#66BB6A] h-6 flex items-center justify-center font-medium">20%</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}


                    </div>
                </div>

            </section>


            {/* page 7 Top 3 Themes */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Pulse Report</p>
                    <p className='font-medium italic text-base'>Cash Operations  | 12 August, 2024</p>
                </div>

                <div className="flex flex-col space-y-4 w-full ">
                    <h1 className="text-2xl font-medium capitalize  bg-[#212121]/90 text-white p-4">
                        Top 3 Themes
                    </h1>
                    <div className="space-y-4">
                        <div className="flex items-center  justify-center bg-[#F1F5F9] p-2 px-4 ">
                            <div className="flex flex-wrap justify-start gap-2 text-xs text-[#212121]/70   ">
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF5350]"

                                    />
                                    <p className="ml-1.5">Extremely Dissatisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF5350BF]"
                                    />
                                    <p className="ml-1.5">Very Dissatisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF535080]"
                                    />
                                    <p className="ml-1.5">Somewhat Dissatisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6A80]"
                                    />
                                    <p className="ml-1.5">Somewhat Satisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6ABF]"
                                    />
                                    <p className="ml-1.5">Very Satisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6A]"
                                    />
                                    <p className="ml-1.5">Extremely Satisfied</p>
                                </div>
                            </div>
                            <div className="flex flex-wrap justify-start gap-2 text-xs text-[#212121]/70 border-l-2 pl-4  ">
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#42A5F5]"
                                    />
                                    <p className="ml-1.5">Less than 6 months</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#9575CD]"
                                    />
                                    <p className="ml-1.5">6 months to 1 year</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#FFA726]"
                                    />
                                    <p className="ml-1.5">1 to 3 years</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6A]"
                                    />
                                    <p className="ml-1.5">3 to 5 years</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF5350]"
                                    />
                                    <p className="ml-1.5">6 to 10 years</p>
                                </div>
                            </div>
                        </div>
                        {elementsPie.map((_, index) => (
                            <div className="space-y-4 ">
                                <p className="text-lg font-semibold text-[#212121] bg-[#F1F5F9] p-2 px-4">{index + 1}.  Department Satisfaction</p>
                                <div className="grid grid-cols-2 gap-6">
                                    <div className="space-y-2 ">
                                        <p className="font-semibold text-base">Department</p>
                                        <div>
                                            <span className="text-sm">Self</span>
                                            <div className="w-full flex items-center text-sm ">
                                                <div className="w-[10%] bg-[#EF5350] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[20%] bg-[#EF5350BF] h-6 flex items-center justify-center font-medium">20%</div>
                                                <div className="w-[30%] bg-[#EF535080] h-6 flex items-center justify-center font-medium">30%</div>
                                                <div className="w-[10%] bg-[#66BB6A80] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[10%] bg-[#66BB6ABF] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[20%] bg-[#66BB6A] h-6 flex items-center justify-center font-medium">20%</div>
                                            </div>
                                        </div>
                                        <div>
                                            <span className="text-sm">Cross-functional</span>
                                            <div className="w-full flex items-center text-sm ">
                                                <div className="w-[10%] bg-[#EF5350] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[20%] bg-[#EF5350BF] h-6 flex items-center justify-center font-medium">20%</div>
                                                <div className="w-[30%] bg-[#EF535080] h-6 flex items-center justify-center font-medium">30%</div>
                                                <div className="w-[10%] bg-[#66BB6A80] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[10%] bg-[#66BB6ABF] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[20%] bg-[#66BB6A] h-6 flex items-center justify-center font-medium">20%</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="space-y-2 ">
                                        <p className="font-semibold text-base">Tenure</p>
                                        <div>
                                            <span className="text-sm">Self</span>
                                            <div className="w-full flex items-center text-sm ">
                                                <div className="w-[25%] bg-[#42A5F5] h-6 flex items-center justify-center font-medium">25%</div>
                                                <div className="w-[20%] bg-[#9575CD] h-6 flex items-center justify-center font-medium">20%</div>
                                                <div className="w-[15%] bg-[#FFA726] h-6 flex items-center justify-center font-medium">15%</div>
                                                <div className="w-[10%] bg-[#66BB6A] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[30%] bg-[#EF5350] h-6 flex items-center justify-center font-medium">30%</div>
                                            </div>
                                        </div>
                                        <div>
                                            <span className="text-sm">Cross-functional</span>
                                            <div className="w-full flex items-center text-sm ">
                                                <div className="w-[25%] bg-[#42A5F5] h-6 flex items-center justify-center font-medium">25%</div>
                                                <div className="w-[20%] bg-[#9575CD] h-6 flex items-center justify-center font-medium">20%</div>
                                                <div className="w-[15%] bg-[#FFA726] h-6 flex items-center justify-center font-medium">15%</div>
                                                <div className="w-[10%] bg-[#66BB6A] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[30%] bg-[#EF5350] h-6 flex items-center justify-center font-medium">30%</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        ))}


                    </div>
                </div>
            </section>

            {/* page 8 Bottom 3 Themes */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Pulse Report</p>
                    <p className='font-medium italic text-base'>Cash Operations  | 12 August, 2024</p>
                </div>

                <div className="flex flex-col space-y-4 w-full ">
                    <h1 className="text-2xl font-medium capitalize  bg-[#212121]/90 text-white p-4">
                        Bottom 3 Themes
                    </h1>
                    <div className="space-y-4">
                        <div className="flex items-center  justify-center bg-[#F1F5F9] p-2 px-4 ">
                            <div className="flex flex-wrap justify-start gap-2 text-xs text-[#212121]/70   ">
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF5350]"

                                    />
                                    <p className="ml-1.5">Extremely Dissatisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF5350BF]"
                                    />
                                    <p className="ml-1.5">Very Dissatisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF535080]"
                                    />
                                    <p className="ml-1.5">Somewhat Dissatisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6A80]"
                                    />
                                    <p className="ml-1.5">Somewhat Satisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6ABF]"
                                    />
                                    <p className="ml-1.5">Very Satisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6A]"
                                    />
                                    <p className="ml-1.5">Extremely Satisfied</p>
                                </div>
                            </div>
                            <div className="flex flex-wrap justify-start gap-2 text-xs text-[#212121]/70 border-l-2 pl-4  ">
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#42A5F5]"
                                    />
                                    <p className="ml-1.5">Less than 6 months</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#9575CD]"
                                    />
                                    <p className="ml-1.5">6 months to 1 year</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#FFA726]"
                                    />
                                    <p className="ml-1.5">1 to 3 years</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6A]"
                                    />
                                    <p className="ml-1.5">3 to 5 years</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF5350]"
                                    />
                                    <p className="ml-1.5">6 to 10 years</p>
                                </div>
                            </div>
                        </div>
                        {elementsPie.map((_, index) => (
                            <div className="space-y-4 ">
                                <p className="text-lg font-semibold text-[#212121] bg-[#F1F5F9] p-2 px-4">{index + 1}.  Department Satisfaction</p>
                                <div className="grid grid-cols-2 gap-6">
                                    <div className="space-y-2 ">
                                        <p className="font-semibold text-base">Department</p>
                                        <div>
                                            <span className="text-sm">Self</span>
                                            <div className="w-full flex items-center text-sm ">
                                                <div className="w-[10%] bg-[#EF5350] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[20%] bg-[#EF5350BF] h-6 flex items-center justify-center font-medium">20%</div>
                                                <div className="w-[30%] bg-[#EF535080] h-6 flex items-center justify-center font-medium">30%</div>
                                                <div className="w-[10%] bg-[#66BB6A80] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[10%] bg-[#66BB6ABF] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[20%] bg-[#66BB6A] h-6 flex items-center justify-center font-medium">20%</div>
                                            </div>
                                        </div>
                                        <div>
                                            <span className="text-sm">Cross-functional</span>
                                            <div className="w-full flex items-center text-sm ">
                                                <div className="w-[10%] bg-[#EF5350] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[20%] bg-[#EF5350BF] h-6 flex items-center justify-center font-medium">20%</div>
                                                <div className="w-[30%] bg-[#EF535080] h-6 flex items-center justify-center font-medium">30%</div>
                                                <div className="w-[10%] bg-[#66BB6A80] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[10%] bg-[#66BB6ABF] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[20%] bg-[#66BB6A] h-6 flex items-center justify-center font-medium">20%</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="space-y-2 ">
                                        <p className="font-semibold text-base">Tenure</p>
                                        <div>
                                            <span className="text-sm">Self</span>
                                            <div className="w-full flex items-center text-sm ">
                                                <div className="w-[25%] bg-[#42A5F5] h-6 flex items-center justify-center font-medium">25%</div>
                                                <div className="w-[20%] bg-[#9575CD] h-6 flex items-center justify-center font-medium">20%</div>
                                                <div className="w-[15%] bg-[#FFA726] h-6 flex items-center justify-center font-medium">15%</div>
                                                <div className="w-[10%] bg-[#66BB6A] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[30%] bg-[#EF5350] h-6 flex items-center justify-center font-medium">30%</div>
                                            </div>
                                        </div>
                                        <div>
                                            <span className="text-sm">Cross-functional</span>
                                            <div className="w-full flex items-center text-sm ">
                                                <div className="w-[25%] bg-[#42A5F5] h-6 flex items-center justify-center font-medium">25%</div>
                                                <div className="w-[20%] bg-[#9575CD] h-6 flex items-center justify-center font-medium">20%</div>
                                                <div className="w-[15%] bg-[#FFA726] h-6 flex items-center justify-center font-medium">15%</div>
                                                <div className="w-[10%] bg-[#66BB6A] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[30%] bg-[#EF5350] h-6 flex items-center justify-center font-medium">30%</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        ))}


                    </div>
                </div>
            </section>

            {/* page 9 Bottom 3 Themes */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Pulse Report</p>
                    <p className='font-medium italic text-base'>Cash Operations  | 12 August, 2024</p>
                </div>

                <div className="flex flex-col space-y-4 w-full ">
                    <h1 className="text-2xl font-medium capitalize  bg-[#212121]/90 text-white p-4">
                        Top 3 Themes <span className="text-lg">(Cash Operation A)</span>
                    </h1>
                    <div className="space-y-4">
                        <div className="flex items-center  justify-center bg-[#F1F5F9] p-2 px-4 ">
                            <div className="flex flex-wrap justify-start gap-2 text-xs text-[#212121]/70   ">
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF5350]"

                                    />
                                    <p className="ml-1.5">Extremely Dissatisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF5350BF]"
                                    />
                                    <p className="ml-1.5">Very Dissatisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF535080]"
                                    />
                                    <p className="ml-1.5">Somewhat Dissatisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6A80]"
                                    />
                                    <p className="ml-1.5">Somewhat Satisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6ABF]"
                                    />
                                    <p className="ml-1.5">Very Satisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6A]"
                                    />
                                    <p className="ml-1.5">Extremely Satisfied</p>
                                </div>
                            </div>
                            <div className="flex flex-wrap justify-start gap-2 text-xs text-[#212121]/70 border-l-2 pl-4  ">
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#42A5F5]"
                                    />
                                    <p className="ml-1.5">Less than 6 months</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#9575CD]"
                                    />
                                    <p className="ml-1.5">6 months to 1 year</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#FFA726]"
                                    />
                                    <p className="ml-1.5">1 to 3 years</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6A]"
                                    />
                                    <p className="ml-1.5">3 to 5 years</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF5350]"
                                    />
                                    <p className="ml-1.5">6 to 10 years</p>
                                </div>
                            </div>
                        </div>
                        {elementsPie.map((_, index) => (
                            <div className="space-y-4 ">
                                <p className="text-lg font-semibold text-[#212121] bg-[#F1F5F9] p-2 px-4">{index + 1}.  Department Satisfaction</p>
                                <div className="grid grid-cols-2 gap-6">
                                    <div className="space-y-2 ">
                                        <p className="font-semibold text-base">Department</p>
                                        <div>
                                            <span className="text-sm">Self</span>
                                            <div className="w-full flex items-center text-sm ">
                                                <div className="w-[10%] bg-[#EF5350] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[20%] bg-[#EF5350BF] h-6 flex items-center justify-center font-medium">20%</div>
                                                <div className="w-[30%] bg-[#EF535080] h-6 flex items-center justify-center font-medium">30%</div>
                                                <div className="w-[10%] bg-[#66BB6A80] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[10%] bg-[#66BB6ABF] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[20%] bg-[#66BB6A] h-6 flex items-center justify-center font-medium">20%</div>
                                            </div>
                                        </div>
                                        <div>
                                            <span className="text-sm">Cross-functional</span>
                                            <div className="w-full flex items-center text-sm ">
                                                <div className="w-[10%] bg-[#EF5350] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[20%] bg-[#EF5350BF] h-6 flex items-center justify-center font-medium">20%</div>
                                                <div className="w-[30%] bg-[#EF535080] h-6 flex items-center justify-center font-medium">30%</div>
                                                <div className="w-[10%] bg-[#66BB6A80] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[10%] bg-[#66BB6ABF] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[20%] bg-[#66BB6A] h-6 flex items-center justify-center font-medium">20%</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="space-y-2 ">
                                        <p className="font-semibold text-base">Tenure</p>
                                        <div>
                                            <span className="text-sm">Self</span>
                                            <div className="w-full flex items-center text-sm ">
                                                <div className="w-[25%] bg-[#42A5F5] h-6 flex items-center justify-center font-medium">25%</div>
                                                <div className="w-[20%] bg-[#9575CD] h-6 flex items-center justify-center font-medium">20%</div>
                                                <div className="w-[15%] bg-[#FFA726] h-6 flex items-center justify-center font-medium">15%</div>
                                                <div className="w-[10%] bg-[#66BB6A] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[30%] bg-[#EF5350] h-6 flex items-center justify-center font-medium">30%</div>
                                            </div>
                                        </div>
                                        <div>
                                            <span className="text-sm">Cross-functional</span>
                                            <div className="w-full flex items-center text-sm ">
                                                <div className="w-[25%] bg-[#42A5F5] h-6 flex items-center justify-center font-medium">25%</div>
                                                <div className="w-[20%] bg-[#9575CD] h-6 flex items-center justify-center font-medium">20%</div>
                                                <div className="w-[15%] bg-[#FFA726] h-6 flex items-center justify-center font-medium">15%</div>
                                                <div className="w-[10%] bg-[#66BB6A] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[30%] bg-[#EF5350] h-6 flex items-center justify-center font-medium">30%</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        ))}


                    </div>
                </div>
            </section>
            {/* page 10 Bottom 3 Themes */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Pulse Report</p>
                    <p className='font-medium italic text-base'>Cash Operations  | 12 August, 2024</p>
                </div>

                <div className="flex flex-col space-y-4 w-full ">
                    <h1 className="text-2xl font-medium capitalize  bg-[#212121]/90 text-white p-4">
                        Bottom 3 Themes <span className="text-lg">(Cash Operation A)</span>
                    </h1>
                    <div className="space-y-4">
                        <div className="flex items-center  justify-center bg-[#F1F5F9] p-2 px-4 ">
                            <div className="flex flex-wrap justify-start gap-2 text-xs text-[#212121]/70   ">
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF5350]"

                                    />
                                    <p className="ml-1.5">Extremely Dissatisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF5350BF]"
                                    />
                                    <p className="ml-1.5">Very Dissatisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF535080]"
                                    />
                                    <p className="ml-1.5">Somewhat Dissatisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6A80]"
                                    />
                                    <p className="ml-1.5">Somewhat Satisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6ABF]"
                                    />
                                    <p className="ml-1.5">Very Satisfied</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6A]"
                                    />
                                    <p className="ml-1.5">Extremely Satisfied</p>
                                </div>
                            </div>
                            <div className="flex flex-wrap justify-start gap-2 text-xs text-[#212121]/70 border-l-2 pl-4  ">
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#42A5F5]"
                                    />
                                    <p className="ml-1.5">Less than 6 months</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#9575CD]"
                                    />
                                    <p className="ml-1.5">6 months to 1 year</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#FFA726]"
                                    />
                                    <p className="ml-1.5">1 to 3 years</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#66BB6A]"
                                    />
                                    <p className="ml-1.5">3 to 5 years</p>
                                </div>
                                <div className="flex items-center ">
                                    <div
                                        className="p-1.5 rounded-sm bg-[#EF5350]"
                                    />
                                    <p className="ml-1.5">6 to 10 years</p>
                                </div>
                            </div>
                        </div>
                        {elementsPie.map((_, index) => (
                            <div className="space-y-4 ">
                                <p className="text-lg font-semibold text-[#212121] bg-[#F1F5F9] p-2 px-4">{index + 1}.  Department Satisfaction</p>
                                <div className="grid grid-cols-2 gap-6">
                                    <div className="space-y-2 ">
                                        <p className="font-semibold text-base">Department</p>
                                        <div>
                                            <span className="text-sm">Self</span>
                                            <div className="w-full flex items-center text-sm ">
                                                <div className="w-[10%] bg-[#EF5350] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[20%] bg-[#EF5350BF] h-6 flex items-center justify-center font-medium">20%</div>
                                                <div className="w-[30%] bg-[#EF535080] h-6 flex items-center justify-center font-medium">30%</div>
                                                <div className="w-[10%] bg-[#66BB6A80] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[10%] bg-[#66BB6ABF] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[20%] bg-[#66BB6A] h-6 flex items-center justify-center font-medium">20%</div>
                                            </div>
                                        </div>
                                        <div>
                                            <span className="text-sm">Cross-functional</span>
                                            <div className="w-full flex items-center text-sm ">
                                                <div className="w-[10%] bg-[#EF5350] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[20%] bg-[#EF5350BF] h-6 flex items-center justify-center font-medium">20%</div>
                                                <div className="w-[30%] bg-[#EF535080] h-6 flex items-center justify-center font-medium">30%</div>
                                                <div className="w-[10%] bg-[#66BB6A80] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[10%] bg-[#66BB6ABF] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[20%] bg-[#66BB6A] h-6 flex items-center justify-center font-medium">20%</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="space-y-2 ">
                                        <p className="font-semibold text-base">Tenure</p>
                                        <div>
                                            <span className="text-sm">Self</span>
                                            <div className="w-full flex items-center text-sm ">
                                                <div className="w-[25%] bg-[#42A5F5] h-6 flex items-center justify-center font-medium">25%</div>
                                                <div className="w-[20%] bg-[#9575CD] h-6 flex items-center justify-center font-medium">20%</div>
                                                <div className="w-[15%] bg-[#FFA726] h-6 flex items-center justify-center font-medium">15%</div>
                                                <div className="w-[10%] bg-[#66BB6A] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[30%] bg-[#EF5350] h-6 flex items-center justify-center font-medium">30%</div>
                                            </div>
                                        </div>
                                        <div>
                                            <span className="text-sm">Cross-functional</span>
                                            <div className="w-full flex items-center text-sm ">
                                                <div className="w-[25%] bg-[#42A5F5] h-6 flex items-center justify-center font-medium">25%</div>
                                                <div className="w-[20%] bg-[#9575CD] h-6 flex items-center justify-center font-medium">20%</div>
                                                <div className="w-[15%] bg-[#FFA726] h-6 flex items-center justify-center font-medium">15%</div>
                                                <div className="w-[10%] bg-[#66BB6A] h-6 flex items-center justify-center font-medium">10%</div>
                                                <div className="w-[30%] bg-[#EF5350] h-6 flex items-center justify-center font-medium">30%</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        ))}


                    </div>
                </div>
            </section>

            {/* page 11 eNPS */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Pulse Report</p>
                    <p className='font-medium italic text-base'>Cash Operations  | 12 August, 2024</p>
                </div>

                <div className="flex flex-col space-y-4 w-full ">
                    <h1 className="text-2xl font-medium    bg-[#212121]/90 text-white p-4">
                        eNPS
                    </h1>

                    <div className='space-x-10  flex items-center'>
                        <div className=" w-60 flex-shrink-0 space-y-6">
                            {/* <p className='text-xl font-semibold text-[#212121] '>ICE Index Score</p> */}
                            <ReactEcharts option={MultiPieCircle} style={{ height: '270px', width: '270px' }} />
                        </div>

                        <div className="w-full space-y-6">
                            <p className='text-xl font-bold text-[#212121] '>eNPS Score: 10%</p>
                            <p className='text-xl font-semibold text-[#212121] '>eNPS = % of Promoters - % of Detractors</p>
                            <div className="flex justify-start space-x-6 ">
                                <div className="flex flex-col items-center gap-2">
                                    <p className="font-medium">Detractors</p>
                                    <div className="text-white flex items-center divide-x divide-white ">
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#EF5350]">1</span>
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#EF5350]">2</span>
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#EF5350]">3</span>
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#EF5350]">4</span>
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#EF5350]">5</span>
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#EF5350]">6</span>
                                    </div>
                                </div>
                                <div className="flex flex-col items-center gap-2">
                                    <p className="font-medium">Passive</p>
                                    <div className="text-white flex items-center divide-x divide-white ">
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#FFA726]">7</span>
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#FFA726]">8</span>
                                    </div>
                                </div>
                                <div className="flex flex-col items-center gap-2">
                                    <p className="font-medium">Promoters</p>
                                    <div className="text-white flex items-center divide-x divide-white ">
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#66BB6A]">9</span>
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#66BB6A]">10</span>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                    <div className="">
                        <table className="w-full">
                            <thead className="border-b-[8px] border-white">
                                <th className="w-[30%] px-2 my-1"></th>
                                <th className="bg-[#F1F5F9] w-[70%] h-8 px-2  " colSpan={2}>
                                    <div className="text-sm font-medium flex justify-between">
                                        <span>-100</span>
                                        <span>0</span>
                                        <span>100</span>
                                    </div>
                                </th>
                            </thead>
                            <tbody>
                                {tableSix.map((_, index) => (
                                    <tr>
                                        <td className="text-sm text-right p-2 py-1">
                                            Business Finance, Finance & Accounts and Legal

                                        </td>
                                        <td className="py-1">
                                            <div className="bg-[#EF5350]/10 w-full h-8 overflow-hidden flex items-center space-x-2 justify-end">
                                                <span className="text-xs font-medium">75%</span>
                                                <div className="bg-[#EF5350] w-3/6 h-8"></div>

                                            </div>

                                        </td>
                                        <td className="py-1">
                                            <div className="bg-[#66BB6A]/10 w-full h-8 overflow-hidden flex items-center space-x-2">
                                                <div className="bg-[#66BB6A] w-3/6 h-8"></div>
                                                <span className="text-xs font-medium">75%</span>
                                            </div>

                                        </td>

                                    </tr>
                                ))}
                            </tbody>

                        </table>

                    </div>
                </div>

            </section>

            {/* page 12 eNPS Cash Operation A */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Pulse Report</p>
                    <p className='font-medium italic text-base'>Cash Operations  | 12 August, 2024</p>
                </div>

                <div className="flex flex-col space-y-4 w-full ">
                    <h1 className="text-2xl font-medium    bg-[#212121]/90 text-white p-4">
                        eNPS <span className="text-lg">(Cash Operation A)</span>
                    </h1>

                    <div className='space-x-10  flex items-center'>
                        <div className=" w-60 flex-shrink-0 space-y-6">
                            <ReactEcharts option={MultiPieCircle} style={{ height: '270px', width: '270px' }} />
                        </div>

                        <div className="w-full space-y-6  ">
                            <p className='text-xl font-bold text-[#212121] '>eNPS Score: 10%</p>
                            <p className='text-xl font-semibold text-[#212121] '>eNPS = % of Promoters - % of Detractors</p>
                            <div className="flex justify-start space-x-6 ">
                                <div className="flex flex-col items-center gap-2">
                                    <p className="font-medium">Detractors</p>
                                    <div className="text-white flex items-center divide-x divide-white ">
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#EF5350]">1</span>
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#EF5350]">2</span>
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#EF5350]">3</span>
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#EF5350]">4</span>
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#EF5350]">5</span>
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#EF5350]">6</span>
                                    </div>
                                </div>
                                <div className="flex flex-col items-center gap-2">
                                    <p className="font-medium">Passive</p>
                                    <div className="text-white flex items-center divide-x divide-white ">
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#FFA726]">7</span>
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#FFA726]">8</span>
                                    </div>
                                </div>
                                <div className="flex flex-col items-center gap-2">
                                    <p className="font-medium">Promoters</p>
                                    <div className="text-white flex items-center divide-x divide-white ">
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#66BB6A]">9</span>
                                        <span className="w-8 flex items-center justify-center h-8 bg-[#66BB6A]">10</span>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                    <div className="">
                        <table className="w-full">
                            <thead className="border-b-[8px] border-white">
                                <th className="w-[30%] px-2 my-1"></th>
                                <th className="bg-[#F1F5F9] w-[70%] h-8 px-2  " colSpan={2}>
                                    <div className="text-sm font-medium flex justify-between">
                                        <span>-100</span>
                                        <span>0</span>
                                        <span>100</span>
                                    </div>
                                </th>
                            </thead>
                            <tbody>
                                {tableSix.map((_, index) => (
                                    <tr>
                                        <td className="text-sm text-right p-2 py-1">
                                            Business Finance, Finance & Accounts and Legal

                                        </td>
                                        <td className="py-1">
                                            <div className="bg-[#EF5350]/10 w-full h-8 overflow-hidden flex items-center space-x-2 justify-end">
                                                <span className="text-xs font-medium">75%</span>
                                                <div className="bg-[#EF5350] w-3/6 h-8"></div>

                                            </div>

                                        </td>
                                        <td className="py-1">
                                            <div className="bg-[#66BB6A]/10 w-full h-8 overflow-hidden flex items-center space-x-2">
                                                <div className="bg-[#66BB6A] w-3/6 h-8"></div>
                                                <span className="text-xs font-medium">75%</span>
                                            </div>

                                        </td>

                                    </tr>
                                ))}
                            </tbody>

                        </table>

                    </div>
                </div>

            </section>

            {/* page 13  Themes Summary */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Pulse Report</p>
                    <p className='font-medium italic text-base'>Cash Operations  | 12 August, 2024</p>
                </div>

                <div className="flex flex-col space-y-4 w-full ">
                    <h1 className="text-2xl font-medium    bg-[#212121]/90 text-white p-4">
                        Themes Summary
                    </h1>

                    <div className="pt-8">
                        <table className="w-full">
                            <thead className="text-sm">
                                <th className="w-[25%]"></th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14 "> Cash Operations </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Team Ownership/ Commitment </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Team Efficiency </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Team Proactiveness </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Team Collaboration </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Cash Operations </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Cash Operations </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Cash Operations </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Cash Operations </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Cash Operations </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Cash Operations </th>
                            </thead>
                            <tbody className="divide-y divide-white ">
                                {elements.map((_, index) => (
                                    <tr key={index} className="text-center text-sm font-medium text-[#212121] divide-x divide-white">
                                        <td className="text-sm text-right p-2 font-normal">
                                            Business Finance, Finance & Accounts & Legal
                                        </td>
                                        <td className="bg-[#EF5350] py-2 h-14">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2  w-14 ">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2  w-14 ">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2  w-14 ">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2  w-14 ">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2  w-14 ">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2  w-14 ">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2  w-14 ">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2  w-14 ">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2  w-14 ">
                                            75%
                                        </td>

                                    </tr>
                                ))}



                            </tbody>

                        </table>

                    </div>
                </div>

            </section>
            {/* page 14  Themes Summary Cash Operation A */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Pulse Report</p>
                    <p className='font-medium italic text-base'>Cash Operations  | 12 August, 2024</p>
                </div>

                <div className="flex flex-col space-y-4 w-full ">
                    <h1 className="text-2xl font-medium    bg-[#212121]/90 text-white p-4">
                        Themes Summary <span className="text-lg">(Cash Operation A)</span>
                    </h1>

                    <div className="pt-8">
                        <table className="w-full">
                            <thead className="text-sm">
                                <th className="w-[25%]"></th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14 "> Cash Operations </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Team Ownership/ Commitment </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Team Efficiency </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Team Proactiveness </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Team Collaboration </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Cash Operations </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Cash Operations </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Cash Operations </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Cash Operations </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Cash Operations </th>
                                <th className="text-sm font-semibold writing-mode rotate-180 p-2 text-left h-40  w-14"> Cash Operations </th>
                            </thead>
                            <tbody className="divide-y divide-white">
                                {elements.map((_, index) => (
                                    <tr key={index} className="text-center text-sm font-medium text-[#212121] divide-x divide-white">
                                        <td className="text-sm text-right p-2 font-normal">
                                            Business Finance, Finance & Accounts & Legal
                                        </td>
                                        <td className="bg-[#EF5350] py-2 h-14">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2  w-14 ">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2  w-14 ">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2  w-14 ">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2  w-14 ">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2  w-14 ">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2  w-14 ">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2  w-14 ">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2  w-14 ">
                                            75%
                                        </td>
                                        <td className="bg-[#EF5350] py-2  w-14 ">
                                            75%
                                        </td>

                                    </tr>
                                ))}



                            </tbody>

                        </table>

                    </div>
                </div>

            </section>

            {/* page 15 Manager score */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Pulse Report</p>
                    <p className='font-medium italic text-base'>Cash Operations  | 12 August, 2024</p>
                </div>

                <div className="flex flex-col space-y-4 w-full ">
                    <h1 className="text-2xl font-medium capitalize  bg-[#212121]/90 text-white p-4">
                        Manager  Score
                    </h1>

                    <div className='space-x-4  flex items-start'>
                        <div className=" w-60 flex-shrink-0 space-y-6">
                            <p className='text-xl font-semibold text-[#212121] '>Manager  Score</p>
                            <ReactEcharts option={option} style={{ height: '200px', width: '200px' }} />
                        </div>

                        <div className="w-full space-y-6 border-l pl-4">
                            <p className='text-xl font-semibold text-[#212121] '>Manager  Themes Score</p>

                            <div className="space-y-2 ">
                                {elements10.map((_, index) => (
                                    <div className="flex items-center space-x-2 text-sm">
                                        <span className="text-[#212121]/70 w-40 flex-shrink-0 font-medium text-right">Delivery on Promise </span>
                                        <div className="bg-[#7e58c0]/10 w-full h-8 overflow-hidden flex items-center space-x-2">
                                            <div
                                                className="bg-[#7e58c0] w-3/6 h-8"
                                            />
                                            <span className="text-xs font-medium">75%</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <p className="text-xl font-semibold text-[#212121] pt-4">Manager Themes Score Sub-department wise</p>

                    <table className="w-full bg-[#F1F5F9] divide-y-2 divide-white">
                        <thead className="bg-[#EAEFF4] ">
                            <th rowSpan={2} className="p-2 px-4 text-base font-semibold text-[#212121] text-left border-r-2 border-white w-36 ">Sub-department</th>
                            <th rowSpan={2} className="p-2 text-base font-semibold text-[#212121] border-r-2 border-white  w-24">Manager
                                Score</th>
                            <th rowSpan={1} colSpan={5} className="p-2 py-3 text-base font-semibold text-[#212121]  border-b-2 border-white">Themes Score</th>
                            <tr className="divide-x-2 divide-white ">
                                <th colSpan={1} className="p-2 py-3 text-xs font-semibold text-[#212121] w-20 ">Role Model
                                </th>
                                <th colSpan={1} className="p-2 py-3 text-xs font-semibold text-[#212121] w-20">Empower </th>
                                <th colSpan={1} className="p-2 py-3 text-xs font-semibold text-[#212121] w-20">Feedback & Development
                                </th>
                                <th colSpan={1} className="p-2 py-3 text-xs font-semibold text-[#212121] w-20">Information Communication
                                </th>
                                <th colSpan={1} className="p-2 py-3 text-xs font-semibold text-[#212121] w-20">Listen & Nurtures </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y-2 divide-white">
                            {elementsPie.map((_, index) => (
                                <tr className="text-sm font-normal divide-x-2 divide-white ">
                                    <td className="p-2 px-4  font-normal text-left">Cash operation A</td>
                                    <td className="p-2 text-center">
                                        <div className="flex justify-center">
                                            <ReactEcharts option={optionCircleSmall} style={{ height: '100px', width: '100px' }} />
                                        </div>
                                    </td>
                                    <td className="p-2 text-center">100%</td>
                                    <td className="p-2 text-center">100%</td>
                                    <td className="p-2 text-center">100%</td>
                                    <td className="p-2 text-center">100%</td>
                                    <td className="p-2 text-center">100%</td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            </section>

            {/* page 16    Open Ended Feedback */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Pulse Report</p>
                    <p className='font-medium italic text-base'>Cash operation | 12 August, 2024</p>
                </div>

                <div className="flex flex-col space-y-4 w-full ">
                    <h1 className="text-2xl font-medium    bg-[#212121]/90 text-white p-4">
                        Open Ended Feedback
                    </h1>
                    <p className="text-sm text-[#212121] italic">This section provides verbatim comments from all respondents. You will gain the most value if you pay attention to the frequently occurring topics and suggestions. Try to view the information objectively and reconcile it with the information in the previous rating sections.</p>
                    <div className="space-y-8 ">

                        <div className="space-y-4  ">
                            <p className="text-lg font-semibold text-[#212121]">1. Do you have any additional comments or suggestions for improving the services provided by the department?</p>

                            <div className="space-y-2 ">
                                {elements10.map((_, index) => (
                                    <div className="w-full flex  space-x-4 items-end">
                                        <div className="text-[#3F51B5]">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 24 24" fill="none">
                                                <path d="M20.4842 3.51579C18.3132 1.34211 15.3132 0 12 0C5.37368 0 0 5.37368 0 12C0 15.3132 1.34211 18.3132 3.51579 20.4842C5.68947 22.6553 8.68684 24 12 24C15.3132 24 18.3132 22.6579 20.4842 20.4842C22.6553 18.3105 24 15.3132 24 12C24 8.68684 22.6579 5.68684 20.4842 3.51579ZM11.9974 3.69474C14.2921 3.69211 16.15 5.55263 16.15 7.84737C16.1474 10.1026 14.2605 11.9921 12.0053 11.9921C9.71053 11.9947 7.85263 10.1342 7.85263 7.83947C7.85263 5.58684 9.74211 3.69737 11.9974 3.69474ZM18.8947 18.8947C18.0632 19.7263 17.0842 20.4079 16 20.8947C14.7789 21.4447 13.4263 21.75 12 21.75C10.5737 21.75 9.22105 21.4447 8 20.8947C6.91579 20.4053 5.93421 19.7237 5.10526 18.8947C4.68684 18.4763 4.30526 18.0184 3.96579 17.5289C4.57105 15.5105 6.45 14.0289 8.66053 14.0289H15.3395C17.55 14.0289 19.4289 15.5105 20.0342 17.5289C19.6947 18.0184 19.3132 18.4737 18.8947 18.8947Z" fill="currentColor" />
                                            </svg>
                                        </div>
                                        <div className="bg-[#3F51B51A] w-full rounded-t-xl rounded-r-xl px-4 py-3 text-sm ">
                                            <p>Standardization of finishing materials & design of projects.</p>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                        <div className="space-y-4  ">
                            <p className="text-lg font-semibold text-[#212121]">2. What is something this department can improve upon?</p>

                            <div className="space-y-2 ">
                                {elements10.map((_, index) => (
                                    <div className="w-full flex  space-x-4 items-end">
                                        <div className="text-[#3F51B5]">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 24 24" fill="none">
                                                <path d="M20.4842 3.51579C18.3132 1.34211 15.3132 0 12 0C5.37368 0 0 5.37368 0 12C0 15.3132 1.34211 18.3132 3.51579 20.4842C5.68947 22.6553 8.68684 24 12 24C15.3132 24 18.3132 22.6579 20.4842 20.4842C22.6553 18.3105 24 15.3132 24 12C24 8.68684 22.6579 5.68684 20.4842 3.51579ZM11.9974 3.69474C14.2921 3.69211 16.15 5.55263 16.15 7.84737C16.1474 10.1026 14.2605 11.9921 12.0053 11.9921C9.71053 11.9947 7.85263 10.1342 7.85263 7.83947C7.85263 5.58684 9.74211 3.69737 11.9974 3.69474ZM18.8947 18.8947C18.0632 19.7263 17.0842 20.4079 16 20.8947C14.7789 21.4447 13.4263 21.75 12 21.75C10.5737 21.75 9.22105 21.4447 8 20.8947C6.91579 20.4053 5.93421 19.7237 5.10526 18.8947C4.68684 18.4763 4.30526 18.0184 3.96579 17.5289C4.57105 15.5105 6.45 14.0289 8.66053 14.0289H15.3395C17.55 14.0289 19.4289 15.5105 20.0342 17.5289C19.6947 18.0184 19.3132 18.4737 18.8947 18.8947Z" fill="currentColor" />
                                            </svg>
                                        </div>
                                        <div className="bg-[#3F51B51A] w-full rounded-t-xl rounded-r-xl px-4 py-3 text-sm ">
                                            <p>Standardization of finishing materials & design of projects.</p>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>
                </div>

            </section>

            {/* page 17    Open Ended Feedback */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Pulse Report</p>
                    <p className='font-medium italic text-base'>Cash operation | 12 August, 2024</p>
                </div>

                <div className="flex flex-col space-y-4 w-full ">
                    <h1 className="text-2xl font-medium    bg-[#212121]/90 text-white p-4">
                        Open Ended Feedback <span className="text-lg">(Cash Operation A)</span>
                    </h1>
                    <p className="text-sm text-[#212121] italic">This section provides verbatim comments from all respondents. You will gain the most value if you pay attention to the frequently occurring topics and suggestions. Try to view the information objectively and reconcile it with the information in the previous rating sections.</p>
                    <div className="space-y-8 ">

                        <div className="space-y-4  ">
                            <p className="text-lg font-semibold text-[#212121]">1. Do you have any additional comments or suggestions for improving the services provided by the department?</p>

                            <div className="space-y-2 ">
                                {elements10.map((_, index) => (
                                    <div className="w-full flex  space-x-4 items-end">
                                        <div className="text-[#3F51B5]">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 24 24" fill="none">
                                                <path d="M20.4842 3.51579C18.3132 1.34211 15.3132 0 12 0C5.37368 0 0 5.37368 0 12C0 15.3132 1.34211 18.3132 3.51579 20.4842C5.68947 22.6553 8.68684 24 12 24C15.3132 24 18.3132 22.6579 20.4842 20.4842C22.6553 18.3105 24 15.3132 24 12C24 8.68684 22.6579 5.68684 20.4842 3.51579ZM11.9974 3.69474C14.2921 3.69211 16.15 5.55263 16.15 7.84737C16.1474 10.1026 14.2605 11.9921 12.0053 11.9921C9.71053 11.9947 7.85263 10.1342 7.85263 7.83947C7.85263 5.58684 9.74211 3.69737 11.9974 3.69474ZM18.8947 18.8947C18.0632 19.7263 17.0842 20.4079 16 20.8947C14.7789 21.4447 13.4263 21.75 12 21.75C10.5737 21.75 9.22105 21.4447 8 20.8947C6.91579 20.4053 5.93421 19.7237 5.10526 18.8947C4.68684 18.4763 4.30526 18.0184 3.96579 17.5289C4.57105 15.5105 6.45 14.0289 8.66053 14.0289H15.3395C17.55 14.0289 19.4289 15.5105 20.0342 17.5289C19.6947 18.0184 19.3132 18.4737 18.8947 18.8947Z" fill="currentColor" />
                                            </svg>
                                        </div>
                                        <div className="bg-[#3F51B51A] w-full rounded-t-xl rounded-r-xl px-4 py-3 text-sm ">
                                            <p>Standardization of finishing materials & design of projects.</p>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                        <div className="space-y-4  ">
                            <p className="text-lg font-semibold text-[#212121]">2. What is something this department can improve upon?</p>

                            <div className="space-y-2 ">
                                {elements10.map((_, index) => (
                                    <div className="w-full flex  space-x-4 items-end">
                                        <div className="text-[#3F51B5]">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 24 24" fill="none">
                                                <path d="M20.4842 3.51579C18.3132 1.34211 15.3132 0 12 0C5.37368 0 0 5.37368 0 12C0 15.3132 1.34211 18.3132 3.51579 20.4842C5.68947 22.6553 8.68684 24 12 24C15.3132 24 18.3132 22.6579 20.4842 20.4842C22.6553 18.3105 24 15.3132 24 12C24 8.68684 22.6579 5.68684 20.4842 3.51579ZM11.9974 3.69474C14.2921 3.69211 16.15 5.55263 16.15 7.84737C16.1474 10.1026 14.2605 11.9921 12.0053 11.9921C9.71053 11.9947 7.85263 10.1342 7.85263 7.83947C7.85263 5.58684 9.74211 3.69737 11.9974 3.69474ZM18.8947 18.8947C18.0632 19.7263 17.0842 20.4079 16 20.8947C14.7789 21.4447 13.4263 21.75 12 21.75C10.5737 21.75 9.22105 21.4447 8 20.8947C6.91579 20.4053 5.93421 19.7237 5.10526 18.8947C4.68684 18.4763 4.30526 18.0184 3.96579 17.5289C4.57105 15.5105 6.45 14.0289 8.66053 14.0289H15.3395C17.55 14.0289 19.4289 15.5105 20.0342 17.5289C19.6947 18.0184 19.3132 18.4737 18.8947 18.8947Z" fill="currentColor" />
                                            </svg>
                                        </div>
                                        <div className="bg-[#3F51B51A] w-full rounded-t-xl rounded-r-xl px-4 py-3 text-sm ">
                                            <p>Standardization of finishing materials & design of projects.</p>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>
                </div>

            </section>




            {/*htiachi  Overall */}
            {/* page 3 */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Pulse Report</p>
                    <p className='font-medium italic text-base'>Overall | 12 August, 2024</p>
                </div>

                <div className="flex flex-col space-y-6 w-full ">
                    <h1 className="text-2xl font-medium capitalize  bg-[#212121]/90 text-white p-4">
                        Respondent summary
                    </h1>
                    <div className="grid grid-cols-3 gap-4">
                        <div className="flex items-center p-4  cursor-pointer bg-[#7E57C2]/10 space-x-4">
                            <div className="p-2 bg-[#673AB7] text-[#fff] ">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon icon-tabler icon-tabler-mail-check"
                                    width={50}
                                    height={50}
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M11 19h-6a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v6" />
                                    <path d="M3 7l9 6l9 -6" />
                                    <path d="M15 19l2 2l4 -4" />
                                </svg>
                            </div>
                            <div className="flex items-center w-full ">
                                <div className="space-y-1">
                                    <p className="block text-base text-[#212112] w-full capitalize font-medium ">
                                        Invited
                                    </p>
                                    <h1 className="text-2xl font-medium w-full text-[#212121]">100</h1>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center p-4  cursor-pointer bg-[#4CAF50]/10 space-x-4">
                            <div className="p-2 bg-[#4CAF50] text-[#fff] ">
                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-rosette-discount-check"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7c.412 .41 .97 .64 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1c0 .58 .23 1.138 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1" /><path d="M9 12l2 2l4 -4" /></svg>
                            </div>
                            <div className="flex items-center w-full ">
                                <div className="space-y-1">
                                    <p className="block text-base text-[#212112] w-full capitalize font-medium ">
                                        Completed
                                    </p>
                                    <h1 className="text-2xl font-medium w-full text-[#212121]">55</h1>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center p-4  cursor-pointer bg-[#4CAF50]/10 space-x-4">
                            <div className="p-2 bg-[#7CB342] text-[#fff] ">
                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-circle-percentage"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" /><path d="M9 15.075l6 -6" /><path d="M9 9.105v.015" /><path d="M15 15.12v.015" /></svg>
                            </div>
                            <div className="flex items-center w-full ">
                                <div className="space-y-1">
                                    <p className="block text-base text-[#212112] w-full capitalize font-medium ">
                                        Completion Rate %
                                    </p>
                                    <h1 className="text-2xl font-medium w-full text-[#212121]">55%</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <table className="w-full bg-[#F1F5F9] divide-y-2 divide-white">
                        <thead className="divide-x-2 divide-white bg-[#EAEFF4]">
                            <th className="p-2  px-4 text-sm font-semibold text-[#212121] text-left w-[30%]">Department</th>
                            <th className="p-2 text-sm font-semibold text-[#212121] w-[15%]">Invited</th>
                            <th className="p-2 text-sm font-semibold text-[#212121] w-[15%]">Completed</th>
                            <th className="p-2 text-sm font-semibold text-[#212121] w-[30%]">Completion Rate %</th>
                        </thead>
                        <tbody className="divide-y-2 divide-white">
                            {elements2.map((_, index) => (
                                <tr className="text-sm font-normal divide-x-2 divide-white ">
                                    <td className="p-2 px-4  font-normal text-left">Product & Digital Transformation</td>
                                    <td className="p-2 text-center">10</td>
                                    <td className="p-2 text-center">5</td>
                                    <td className="p-2 w-fit">
                                        <div
                                            className="flex items-center justify-center space-x-2  "
                                        >
                                            <div className="bg-[#E5E7EB] w-48 h-5 overflow-hidden">
                                                <div
                                                    className="bg-[#7CB342] w-[50%] h-5"
                                                />
                                            </div>
                                            <span>50%</span>
                                        </div>



                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                </div>

            </section>

            {/* page 6 */}
            <section className=' w-full p-6 px-6 flex flex-col justify-between page-break'>
                {/* report header */}
                <div className='flex items-center justify-between border-b py-4 text-[#212121]/80 mb-6'>
                    <p className='font-medium italic text-base '>Pulse Report</p>
                    <p className='font-medium italic text-base'>Overall | 12 August, 2024</p>
                </div>

                <div className="flex flex-col space-y-4 w-full ">
                    <h1 className="text-2xl font-medium capitalize  bg-[#212121]/90 text-white p-4">
                        ICE Index Summary
                    </h1>

                    <div className='space-x-4  flex items-start'>
                        <div className=" w-60 flex-shrink-0 space-y-6">
                            <p className='text-xl font-semibold text-[#212121] '>ICE Index Score</p>
                            <ReactEcharts option={option} style={{ height: '200px', width: '200px' }} />
                        </div>

                        <div className="w-full space-y-6 border-l pl-4">
                            <p className='text-xl font-semibold text-[#212121] '>ICE Index Themes Score</p>

                            <div className="space-y-2 ">
                                {tableSix.map((_, index) => (
                                    <div className="flex items-center space-x-2 text-sm">
                                        <span className="text-[#212121]/70 w-40 flex-shrink-0 font-medium text-right">Delivery on Promise </span>
                                        <div className="bg-[#7e58c0]/10 w-full h-8 overflow-hidden flex items-center space-x-2">
                                            <div
                                                className="bg-[#7e58c0] w-3/6 h-8"
                                            />
                                            <span className="text-xs font-medium">75%</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="space-y-4 ">
                        <div className="space-y-6 ">
                            <p className='text-xl font-semibold text-[#212121]'>ICE Index Themes Distribution</p>

                            <div className="flex items-center justify-center  ">
                                <div className="flex flex-wrap justify-start gap-4 text-xs text-[#212121]/70   ">
                                    <div className="flex items-center ">
                                        <div
                                            className="p-1.5 rounded-sm bg-[#EF5350]"

                                        />
                                        <p className="ml-1.5">Extremely Dissatisfied</p>
                                    </div>
                                    <div className="flex items-center ">
                                        <div
                                            className="p-1.5 rounded-sm bg-[#EF5350BF]"
                                        />
                                        <p className="ml-1.5">Very Dissatisfied</p>
                                    </div>
                                    <div className="flex items-center ">
                                        <div
                                            className="p-1.5 rounded-sm bg-[#EF535080]"
                                        />
                                        <p className="ml-1.5">Somewhat Dissatisfied</p>
                                    </div>
                                    <div className="flex items-center ">
                                        <div
                                            className="p-1.5 rounded-sm bg-[#66BB6A80]"
                                        />
                                        <p className="ml-1.5">Somewhat Satisfied</p>
                                    </div>
                                    <div className="flex items-center ">
                                        <div
                                            className="p-1.5 rounded-sm bg-[#66BB6ABF]"
                                        />
                                        <p className="ml-1.5">Very Satisfied</p>
                                    </div>
                                    <div className="flex items-center ">
                                        <div
                                            className="p-1.5 rounded-sm bg-[#66BB6A]"
                                        />
                                        <p className="ml-1.5">Extremely Satisfied</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ReactEcharts option={getOption()} style={{ height: '300px', width: '100%' }} />


                    </div>
                </div>

            </section>

            {/* Department Report pages  */}
        </div>
    );
}
export default HitachiReport;