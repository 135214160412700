import React, { Component } from "react";
import { connect } from "react-redux";
import ReactEcharts from "echarts-for-react";
import OverallDistLabels from "../Components/OverallDistLabels";

class StackChart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  openChartList = () => {
    if (this.state.showChartList) {
      this.setState({ showChartList: false });
    } else {
      this.setState({ showChartList: true });
    }
  };

  handleChartType = (item) => {
    this.setState({ chartTypeName: item });
  };

  render() {
    let {
      getOverallDriver,
      getIndexDriverData,
      getIndexDriver2Data,
      GetParameterLabel,
      year1,
      year2,
      indexTemplate
    } = this.props;

    //     PerformanceCount: 83.9396628216504
    // PresenceCount: 67.3469387755102
    // PrideCount: 91.03815439219166

    let getIndexDriverList = getIndexDriverData
      ? Object.keys(getIndexDriverData)
      : [];

    let nameList = [];
    let scoreList = [];
    let score2List = [];
    let Overall = [];

    let valueCount = 0;
    if (getIndexDriverList && getIndexDriverList.length > 0) {
      getIndexDriverList.forEach((item) => {
        if (
          item &&
          item !== "count" &&
          item !== "name" &&
          parseInt(item) !== 1 &&
          parseInt(item) !== 2 &&
          parseInt(item) !== 3 &&
          parseInt(item) !== 4 &&
          parseInt(item) !== 5 &&
          parseInt(item) !== 6 &&
          parseInt(item) !== 7 &&
          parseInt(item) !== 8
        ) {
          valueCount++;
          nameList.push({
            value: GetParameterLabel(item.replace("Count", "")),
            textStyle: { fontWeight: "bold" },
          });
          scoreList.push({
            value:
              getIndexDriverData &&
              getIndexDriverData[item] &&
              getIndexDriverData[item].toFixed(2)
                ? getIndexDriverData[item].toFixed(2)
                : 0,
            itemStyle: { color: "#0099ff" },
        
          });

          score2List.push({
            value:
              getIndexDriver2Data &&
              getIndexDriver2Data[item] &&
              getIndexDriver2Data[item].toFixed(2)
                ? getIndexDriver2Data[item].toFixed(2)
                : 0,
            itemStyle: { color: "#9576CD" },  
          });

          Overall.push({
            name: GetParameterLabel(item.replace("Count", "")),
            score:
              getOverallDriver &&
              getOverallDriver[item] &&
              getOverallDriver[item].toFixed(2)
                ? getOverallDriver[item].toFixed(2)
                : 0,
          });
        }
      });
    }

    console.log("getIndexDriver2Data---->", getIndexDriver2Data);

    let series = [];

    if (year1) {
      series.push({
        name: "Score",
        type: "bar",
        barWidth: "20px",
        label: {
          show: true,
          position: "insideRight",
          color: "#f2f2f2"
        },
        data: scoreList.reverse(),
      });
    }

    if (year2) {
      series.push({
        name: "Score",
        type: "bar",
        barWidth: "20px",
        label: {
          show: true,
          position: "insideRight",
          color: "#f2f2f2"
        },
        data: score2List.reverse(),
      });
    }

    return (
      <> 

        <div className="stack-body w-full mt-6">
          {true ? (
            <>
              <ReactEcharts
                style={{ height: indexTemplate===1?"500px":"300px", marginTop: "-60px" }}
                option={{
                  tooltip: {
                    trigger: "axis",
                    axisPointer: {
                      type: "shadow",
                    },
                    formatter: function (a, b, c, d) {
                      return a && a[0] && a[0].name
                        ? a[0].name + " = " + a[0].value + "%"
                        : "";
                    },
                  },
                  legend: {
                    data: [],
                  },
                  grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "3%",
                    containLabel: true,
                  },
                  xAxis: {
                    type: "value",
                    splitLine: {
                      lineStyle: {
                        color: "#f2f2f2",
                      },
                    },
                    axisLine: {
                      lineStyle: {
                        color: "#0099ff",
                      },
                    },
                    max: 100,
                  },
                  yAxis: {
                    type: "category",
                    data: nameList.reverse(),
                    axisLine: {
                      lineStyle: {
                        color: "#e6e6e6",
                      },
                    },
                  },
                  axisLabel: {
                    color: "#3D405B",
                    interval: 0,
                    fontWeight: 'normal',
                    formatter: function (value) {
                      if (value.length > 15) {
                        // return value.substring(0, 15) + "..";
                        return value;
                      } else {
                        return value;
                      }
                    },
                  },
                  series: series,
                }}
              />
            </>
          ) : null}
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(StackChart);
