import React, { Component } from "react";
// import EmpChart from './Chart/EmpChart';
// import BarDistribution from './Chart/BarDistribution';
import Header from "../Components/Header";

class Employee extends Component {
  render() {
    let {
      getEMPFilterLabel,
      setPDF,
      getSurveyData,
      getOrgIndexV2Data,
      getDemographicOrgReportData,
      GetParameterLabel,
      surveyLogo,
      CountRestrict,
      getOrgDriverOverallData
    } = this.props;

    let OrgCoreDriverData = {};
    if (
      getOrgIndexV2Data &&
      getSurveyData &&
      getSurveyData.orgList &&
      getSurveyData.orgList.length > 0
    ) {
      getSurveyData.orgList.forEach((org) => {
        OrgCoreDriverData[org.name] = getOrgIndexV2Data[org.name];
      });
    }

    let valueList = [];
    if (OrgCoreDriverData) {
      valueList = Object.keys(OrgCoreDriverData).map((key) => [
        key,
        OrgCoreDriverData[key],
      ]);
    }

    //////console.log("current--->getDemographicOrgReportData-->",getDemographicOrgReportData)
    //////console.log("current--->valueList-->",valueList)
    // if(getDemographicOrgReportData && getDemographicOrgReportData.length>0){
    //     getDemographicOrgReportData.forEach((item)=>{
    //         let tempData = {
    //             "xAxis":item.xAxis,
    //             "data":[]
    //         }
    //         if(item && item.Data && item.Data.length>0){
    //             item.Data.forEach((inner)=>{
    //                 tempData["data"].push([inner.name,inner[2]])
    //             })
    //         }
    //         FinalData.push(tempData)
    //     });
    // }

    let FinalData = [];
    if (getDemographicOrgReportData && getDemographicOrgReportData.length > 0) {
      getDemographicOrgReportData.forEach((item) => {
        let tempData = {
          xAxis: item.xAxis,
          Data: [],
        };
        let innerList = [];
        if (item && item.Data && item.Data.length > 0) {
          item.Data.forEach((inner) => {
            innerList.push(inner);
          });
        }

        // innerList.sort((a,b)=>a.name.charCodeAt(0) - b.name.charCodeAt(0))
        innerList.sort(
          (a, b) =>
            (a && a.name && a.name.toString()
              ? a.name.toString().charCodeAt(0)
              : "") -
            (b && b.name && b.name.toString()
              ? b.name.toString().charCodeAt(0)
              : "")
        );

        tempData["Data"] = innerList;
        FinalData.push(tempData);
      });
    }

    function properValueFormat(element, item) {
      return item &&
        item[0] &&
        element &&
        element[item[0] + "Avg"] &&
        element[item[0] + "Avg"].toFixed(2)
        ? element[item[0] + "Avg"].toFixed(2)
        : 0;
    }

    function calcOverall(itemNamw) {
      return getOrgDriverOverallData &&
        getOrgDriverOverallData[itemNamw] &&
        getOrgDriverOverallData[itemNamw].toFixed(2)
        ? getOrgDriverOverallData[itemNamw].toFixed(2)
        : 0;
    }

    function getColorCode(overall,score){
      if(parseFloat(overall)<=parseFloat(score)){
        return "#4CAF50"
      }else{
        return "#F44336"
      }

      
    }


    return (
      <div>
        {/* <header className="bg-indigo-700">
    <div className=" capitalize w-5/6 mx-auto py-6 ">
      <div className="text-white opacity-90">
        <p className="flex items-center">Emerging Areas of Strength for </p>
      </div>
      <h1 className="text-2xl text-white font-medium pb-2">Highest Scoring Experiences, top 10 items </h1>
      <div>
                        <img className="absolute top-4 right-0 w-40" src={logo} />
                    </div>
    </div>

  </header> */}

        {/* <header className="bg-indigo-700 relative">
                    <div className=" capitalize w-5/6 mx-auto py-6 space-y-1">
                        <h1 className="text-2xl text-white font-medium">
                        Organization Core Across Demographics
                        </h1>
                    </div>
                    <div>
                        <img className="absolute top-4 right-0 w-40" src={logo} />
                    </div>
                </header> */}

        {/* <div className='pt-4' >
          <Header surveyLogo={surveyLogo} />

          <div className>
            <div className="capitalize w-full px-8 py-6 ">
              <div className="text-indigo-700 opacity-90">
              </div>
              <h1 className="text-xl text-gray-800 font-semibold pb-2">Organization Core Across Demographics </h1>
            </div>
          </div>
        </div> */}

        {/* <header className="bg-indigo-700 relative">
  <div className="text-white opacity-90">
        <p className="flex items-center">Emerging Areas of Strength for </p>
      </div>
      <h1 className="text-2xl text-white font-medium pb-2">Highest Scoring Experiences, top 10 items </h1>
                    <div>
                        <img className="absolute top-4 right-0 w-40" src={logo} />
                    </div>
                </header> */}

        {/* <div>
{getDemographicOrgReportData && getDemographicOrgReportData.length>0?
  getDemographicOrgReportData.map((item)=>
  <main className=" mx-auto w-5/6 py-4">
    <h1 className="text-xl font-medium text-blue-500 capitalize">{item.xAxis}</h1>
    <div className="space-y-6">

    {item && item.Data && item.Data.length>0?
        item.Data.map((element,index)=>
        <>
        <div className=" flex-row border w-full items-start my-2">
          <div className="bg-gray-100 flex  text-gray-500 px-3 text-left">{element.name}</div>

          <div className="p-4 text-gray-500 flex flex-wrap gap-2 w-full ">
            {valueList && valueList.length>0?
              valueList.map((item)=>
              <div className="bg-gray-100 p-1 px-2 text-sm border rounded-md ">{GetParameterLabel(item[0])} ({properValueFormat(element,item)})</div>
            ):null}
          </div>
          </div>
        </>
        ):null}
        
      
    </div>
  </main>
  ):null}
</div> */}

        <div>
          {FinalData && FinalData.length > 0
            ? FinalData.map((item, index2) => (
                <main className="w-full px-8">
                  {/* <h1 className="text-blue-500 text-xl font-medium capitalize pt-4 pb-2 " style={{ color: "#2196f3" }}>{getEMPFilterLabel(item.xAxis)}</h1> */}
                  <div className="space-y-3">
                    {item && item.Data && item.Data.length > 0
                      ? item.Data.map((element, index) => (
                          <>
                            {index % 2 === 0 ? (
                              <>
                                {index ? (
                                  <div      className="font-normal p-2 text-sm text-left text-[#3D405B]/70 ">
                                    Continue...
                                  </div>
                                ) : null}

                                <div className="page-break "></div>

                                <div className="pt-2 -mx-10">
                                  <Header surveyLogo={surveyLogo} />

                                  <div className>
                                    <div className="capitalize w-full px-8   ">
                                      <h1 className="text-2xl text-[#3D405B] font-semibold border-b border-[#DDDDDD] pb-4 capitalize">
                                        Organization Core Across Demographics
                                      </h1>
                                    </div>
                                  </div>

                                  {/* <h1
                                    className="text-lg text-[#3D405B] font-medium capitalize pt-4 pb-2 mx-8"
                                 
                                  >
                                    {getEMPFilterLabel(item.xAxis)}
                                  </h1> */}
                                </div>
                              </>
                            ) : null}

                            {setPDF ? (
                              <>
                                <table className="w-full border">
                                  <tr className="text-left text-[#3D405B] capitalize  border-b bg-[#F1F5F9] text-sm border-l border-t">
                                    <th className="w-[30%] font-medium p-2  text-left border-r">
                                      {getEMPFilterLabel(item.xAxis)}
                                    </th>
                                    <th className="w-[70%] font-medium p-2  text-left border-r">
                                      Score
                                    </th>
                                  </tr>

                                  <tbody>
                                    <tr className="border-b text-[#3D405B] text-sm border-l">
                                      <td className=" border-r   px-4 font-medium bg-[#ffffff]">
                                        {element.name}
                                      </td>
                                      <td className="bg-[#ffffff]">
                                        <div className="p-4 text-[#3D405B] grid grid-cols-2 gap-2 w-full ">
                                          {valueList && valueList.length > 0
                                            ? valueList.map((item) => (
                                                <div className="bg-white p-2 text-sm border rounded-md font-medium space-y-1 ">
                                                  <p>
                                                    {GetParameterLabel(item[0])}
                                                    <span className="px-1 text-[#F44336] font-semibold" style={{
                                                      color:getColorCode(calcOverall(item[0]),properValueFormat(element,item))
                                                    }}>
                                                      (
                                                      {CountRestrict(
                                                        element.count
                                                      )
                                                        ? "NA"
                                                        : properValueFormat(
                                                            element,
                                                            item
                                                          )}
                                                      )
                                                    </span>
                                                  </p>
                                                  <p className="text-xs text-[#3D405B]/70 ">
                                                    Overall {"("+calcOverall(item[0])+")"}
                                                  </p>
                                                </div>
                                              ))
                                            : null}
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </>
                            ) : (
                              <>
                                <table
                                  id={
                                    "orgDMG" +
                                    item.xAxis.toString() +
                                    element.name.toString()
                                  }
                                >
                                  <thead>
                                    <tr>
                                      <th
                                        data-pptx-min-width="2.6"
                                        style={{
                                          width: "50%",
                                          fontSize: 10,
                                          background: "#e6e6e6",
                                        }}
                                      >
                                        {element.name +
                                          " - " +
                                          getEMPFilterLabel(item.xAxis)}
                                      </th>
                                      <th
                                        data-pptx-min-width="3.6"
                                        style={{
                                          width: "15%",
                                          fontSize: 10,
                                          background: "#e6e6e6",
                                        }}
                                      >
                                        {""}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td
                                        data-pptx-min-width="2.6"
                                        style={{
                                          width: "50%",
                                          fontSize: 10,
                                          background: "#e6e6e6",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Drivers
                                      </td>
                                      <td
                                        data-pptx-min-width="3.6"
                                        style={{
                                          width: "15%",
                                          fontSize: 10,
                                          background: "#e6e6e6",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Scores
                                      </td>
                                    </tr>
                                    {valueList && valueList.length > 0
                                      ? valueList.map((item, index) => (
                                          <tr>
                                            <td
                                              data-pptx-min-width="2.6"
                                              style={{
                                                width: "50%",
                                                fontSize: 10,
                                                background:
                                                  index % 2 === 0
                                                    ? "#c2e4ff"
                                                    : "#ffffff",
                                              }}
                                            >
                                              {GetParameterLabel(item[0])}
                                            </td>
                                            <td
                                              data-pptx-min-width="2.6"
                                              style={{
                                                width: "10%",
                                                fontSize: 10,
                                                background:
                                                  index % 2 === 0
                                                    ? "#c2e4ff"
                                                    : "#ffffff",
                                              }}
                                            >
                                              {CountRestrict(element.count)
                                                ? "NA"
                                                : properValueFormat(
                                                    element,
                                                    item
                                                  ) + " %"}
                                            </td>
                                          </tr>
                                        ))
                                      : null}
                                  </tbody>
                                </table>
                              </>
                            )}
                          </>
                        ))
                      : null}
                  </div>
                </main>
              ))
            : null}
        </div>
      </div>
    );
  }
}
export default Employee;
