import React, { Component } from 'react';
import { connect } from 'react-redux';
import OverallReport from './Components/OverallReport/OverallReport';
import moment from "moment";

import LoadConfirmModal from './LoadConfirmModal';
// import RWAnalysis from './Components/RWAnalysis/RWAnalysis';

import pptxgen from "pptxgenjs";

class StrengthAndWeaknesses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionType: 1,


      ReportFilterObj: {
        "level": "Overall",
        "value": ""
      },

      showExport: false,


      currentSelectValue: '',
      FilterValues: [],
      showFilter: false,
      showValue: '',
      isSelectTypeOverall: false,
      errorMsg: ""

    }
  }
  componentDidMount() {

  }

  onOptionType = (val) => {
    this.setState({ optionType: val })
    // if(val===2){
    //   let { onWeaknessQuestionCall } = this.props;
    //   onWeaknessQuestionCall();
    // }
    // else{
    //   let { onStrengthQuestionCall } = this.props;
    //   onStrengthQuestionCall();
    // }
  }

  selectReportFilter = (val, type) => {
    let { ReportFilterObj } = this.state;

    if (type === "overall") {
      ReportFilterObj["level"] = val;
      ReportFilterObj["value"] = "";
    }
    else if (type === "level") {
      ReportFilterObj["level"] = val;
      ReportFilterObj["value"] = "";
    }
    else if (type === "value") {
      ReportFilterObj["value"] = val;
    }
    this.setState({ ReportFilterObj })

  }

  loadIndexReport = (a, b, isClose) => {
    this.setState({ showExport: false });

    let { onLoadIndex } = this.props;
    let { isSelectTypeOverall, FilterValues, currFilterName } = this.state;
    if (!isClose) {
      let count = 0;
      if (FilterValues && FilterValues.length > 0) {
        FilterValues.forEach((item) => {
          if (item && item["level"] && item["valueList"][0] !== ["All"]) {
            count++
          }
        });
      }
      if (count) {
        this.setState({ errorMsg: "" });
        onLoadIndex(a, b, FilterValues, true, currFilterName)
      }
      else {
        this.setState({ errorMsg: "Please select filter values!" });
      }

      setTimeout(() => {
        this.setState({ errorMsg: "" });
      }, 5000)
    }
    else {

      onLoadIndex(a, b, FilterValues, true, currFilterName, isClose)
      this.setState({
        currentSelectValue: '',
        FilterValues: [],
        showFilter: false,
        showValue: '',
        isSelectTypeOverall: false,
        errorMsg: ""
      });

    }


    setTimeout(() => {
      this.setState({ showExport: true });
    }, 5000)

  }

  handleSelectTypeOverall = (value) => {
    this.setState({ isSelectTypeOverall: value });
  }

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }


  handleMultifilterIndexScore2 = (finalFilter, name) => {
    // let { handleMultifilterBehaviour } = this.props;
    this.setState({ FilterValues: finalFilter, currFilterName: name });
    // handleMultifilterBehaviour(finalFilter, true, name);

  }


  onSwitchOptionScreen = () => {

    let {
      GetParameterLabel,
      getIndexData,
      getRespondantDetailsData,
      getAllBenchMarkData,
      CountRestrict,
      getQuestionData,
      getOrgIndexV2Data,
      getSurveyData,
      getEngagementRWAData,
      getQuestionFavorabilityData,
      getQuestionFavorability2Data,
      loadReport,
      getDemographicIndexMultiFilterData,
      getDemographicOrgReportData,
      getQuestionFilterData,



      getHappinessQuestion,
      getEngagementQuestion,
      getHopeQuestion,
      getStressQuestion,
      getManagerQuestion,
      getLeaderQuestion,

      getHappinessIndexV2Data,
      getEngagementIndexV2Data,
      getHopeIndexV2Data,
      getStressIndexV2Data,
      getManagerIndexV2Data,
      getLeaderIndexV2Data,


      getHappinessIndexDriverData,
      getEngagementIndexDriverData,
      getHopeIndexDriverData,
      getStressIndexDriverData,
      getManagerIndexDriverData,
      getLeaderIndexDriverData,


      DMGLevelsList,
      DMGValuesList,

      isFilter,

      surveyLogo,

      getDemographicIndexMultiFilterEngCompData,

      getQuestionCommentsData,

      getEMPFilterLabel,
      getIndexByFilterDMGData,

      getOverallIndexData,

      //Overall
      getHappinessQuestionOverall,
      getEngagementQuestionOverall,
      getStressQuestionOverall,
      getManagerQuestionOverall,
      getLeaderQuestionOverall,
      getOrgQuestionOverall,


      getOrgDriverOverallData,

      getHappinessDistributionOverall,
      getEngagementDistributionOverall,
      getStressDistributionOverall,


      getHappinessDriverOverall,
      getEngagementDriverOverall,
      getStressDriverOverall,


      getDemographicIndexEngCompOverallData,

      getQuestionOthersResultData,
      getQuestionOthersMultiResultFilterData,

      getQuestion2OthersResultFilterData




    } = this.props;

    let { optionType, ReportFilterObj, showExport,

      currentSelectValue,
      FilterValues,
      showFilter,
      showValue,
      isSelectTypeOverall,

      errorMsg


    } = this.state;




    switch (optionType) {

      case 1: return <OverallReport

        isSelectTypeOverall={isSelectTypeOverall}
        handleSelectTypeOverall={this.handleSelectTypeOverall}
        currentSelectValue={currentSelectValue}
        FilterValues={FilterValues}
        showFilter={showFilter}
        showValue={showValue}
        closeFilter={this.closeFilter}
        handleMultifilterIndexScore2={this.handleMultifilterIndexScore2}
        errorMsg={errorMsg}

        loadIndexReport={this.loadIndexReport}


        getIndexData={getIndexData}
        getRespondantDetailsData={getRespondantDetailsData}
        getAllBenchMarkData={getAllBenchMarkData}
        CountRestrict={CountRestrict}
        getQuestionData={getQuestionData}
        GetParameterLabel={GetParameterLabel}
        getOrgIndexV2Data={getOrgIndexV2Data}
        getSurveyData={getSurveyData}
        getEngagementRWAData={getEngagementRWAData}
        getQuestionFavorabilityData={getQuestionFavorabilityData}
        getQuestionFavorability2Data={getQuestionFavorability2Data}
        loadReport={loadReport}
        getDemographicIndexMultiFilterData={getDemographicIndexMultiFilterData}
        getDemographicOrgReportData={getDemographicOrgReportData}
        getQuestionFilterData={getQuestionFilterData}


        getHappinessQuestion={getHappinessQuestion}
        getEngagementQuestion={getEngagementQuestion}
        getHopeQuestion={getHopeQuestion}
        getStressQuestion={getStressQuestion}
        getManagerQuestion={getManagerQuestion}
        getLeaderQuestion={getLeaderQuestion}


        getHappinessIndexV2Data={getHappinessIndexV2Data}
        getEngagementIndexV2Data={getEngagementIndexV2Data}
        getStressIndexV2Data={getStressIndexV2Data}
        getManagerIndexV2Data={getManagerIndexV2Data}


        getHappinessIndexDriverData={getHappinessIndexDriverData}
        getEngagementIndexDriverData={getEngagementIndexDriverData}
        getHopeIndexDriverData={getHopeIndexDriverData}
        getStressIndexDriverData={getStressIndexDriverData}
        getManagerIndexDriverData={getManagerIndexDriverData}
        getLeaderIndexDriverData={getLeaderIndexDriverData}

        DMGLevelsList={DMGLevelsList}
        DMGValuesList={DMGValuesList}

        selectReportFilter={this.selectReportFilter}

        ReportFilterObj={ReportFilterObj}

        isFilter={isFilter}


        showExport={showExport}

        surveyLogo={surveyLogo}

        getDemographicIndexMultiFilterEngCompData={getDemographicIndexMultiFilterEngCompData}

        getQuestionCommentsData={getQuestionCommentsData}

        getEMPFilterLabel={getEMPFilterLabel}

        getIndexByFilterDMGData={getIndexByFilterDMGData}

        getOverallIndexData={getOverallIndexData}

        handleExportPPT={this.handleExportPPT}




        //Overall
        getHappinessQuestionOverall={getHappinessQuestionOverall}
        getEngagementQuestionOverall={getEngagementQuestionOverall}
        getStressQuestionOverall={getStressQuestionOverall}
        getManagerQuestionOverall={getManagerQuestionOverall}
        getLeaderQuestionOverall={getLeaderQuestionOverall}
        getOrgQuestionOverall={getOrgQuestionOverall}

        getOrgDriverOverallData={getOrgDriverOverallData}


        getHappinessDistributionOverall={getHappinessDistributionOverall}
        getEngagementDistributionOverall={getEngagementDistributionOverall}
        getStressDistributionOverall={getStressDistributionOverall}

        getHappinessDriverOverall={getHappinessDriverOverall}
        getEngagementDriverOverall={getEngagementDriverOverall}
        getStressDriverOverall={getStressDriverOverall}

        getDemographicIndexEngCompOverallData={getDemographicIndexEngCompOverallData}

        getQuestionOthersResultData={getQuestionOthersResultData}

        getQuestionOthersMultiResultFilterData={getQuestionOthersMultiResultFilterData}

        getQuestion2OthersResultFilterData={getQuestion2OthersResultFilterData}

      />

      default: return <div>Default Option</div>
    }
  }








  //ppt export code

  handleExportPPT = () => {
    let pres = new pptxgen();

    let { FilterValues } = this.state;
    let {
      GetParameterLabel, getIndexData, getRespondantDetailsData, getAllBenchMarkData, CountRestrict, getQuestionData, getOrgIndexV2Data,
      getSurveyData, getEngagementRWAData, getQuestionFavorabilityData, getQuestionFavorability2Data, loadReport, getDemographicIndexMultiFilterData,
      getDemographicOrgReportData, getQuestionFilterData, getHappinessQuestion, getEngagementQuestion, getHopeQuestion, getStressQuestion,
      getManagerQuestion, getLeaderQuestion, getHappinessIndexV2Data, getEngagementIndexV2Data, getHopeIndexV2Data, getStressIndexV2Data,
      getManagerIndexV2Data, getLeaderIndexV2Data, getHappinessIndexDriverData, getEngagementIndexDriverData, getHopeIndexDriverData,
      getStressIndexDriverData, getManagerIndexDriverData, getLeaderIndexDriverData, DMGLevelsList, DMGValuesList, isFilter, surveyLogo,
      getDemographicIndexMultiFilterEngCompData, getQuestionCommentsData, getEMPFilterLabel, getIndexByFilterDMGData, getOverallIndexData
      , getOrgDriverOverallData,


      //Overall
      getHappinessQuestionOverall,
      getEngagementQuestionOverall,
      getStressQuestionOverall,
      getManagerQuestionOverall,
      getLeaderQuestionOverall,
      getOrgQuestionOverall,


      getHappinessDistributionOverall,
      getEngagementDistributionOverall,
      getStressDistributionOverall,

      getHappinessDriverOverall,
      getEngagementDriverOverall,
      getStressDriverOverall,

      getDemographicIndexEngCompOverallData,

      getQuestionOthersResultData,

      getQuestion2OthersResultFilterData,


    } = this.props;


    //Introduction
    // let slide = pres.addSlide();


    //Summary Page Code
    let IndexValidList = getOverallIndexData ? Object.keys(getOverallIndexData) : [];
    let IndexNumber = 0
    let SummaryName = "";
    let getIndexV2Data = "";
    let getIndexDriverData = ""
    let getIndexQuestionData = ""
    let getIndexOverallQuesData = ""
    let getOverallDistribution = ""
    let getOverallDriver = ""


    let OutPutData1234 = []
    let OutPutData56 = []
    if (IndexValidList && IndexValidList.length > 0) {
      IndexValidList.forEach((item) => {
        ////////console.log("--------before IndexNumber-->",item)
        if (item && getOverallIndexData && getOverallIndexData[item]) {
          ////////console.log("--------after IndexNumber-->",item)

          IndexNumber = parseInt(item);

          if ((parseInt(item) !== 5 && parseInt(item) !== 6 && parseInt(item) !== 4)) {
            if (parseInt(item) === 1) {
              SummaryName = "Happiness";
              getIndexV2Data = getHappinessIndexV2Data;
              getIndexDriverData = getHappinessIndexDriverData;
              getIndexQuestionData = getHappinessQuestion;
              getIndexOverallQuesData = getHappinessQuestionOverall;
              getOverallDistribution = getHappinessDistributionOverall;
              getOverallDriver = getHappinessDriverOverall;
            }
            else if (parseInt(item) === 2) {
              SummaryName = "Engagement";
              getIndexV2Data = getEngagementIndexV2Data;
              getIndexDriverData = getEngagementIndexDriverData;
              getIndexQuestionData = getEngagementQuestion;
              getIndexOverallQuesData = getEngagementQuestionOverall;
              getOverallDistribution = getEngagementDistributionOverall;
              getOverallDriver = getEngagementDriverOverall;
            }
            else if (parseInt(item) === 3) {
              SummaryName = "Hope";
              getIndexV2Data = getHopeIndexV2Data;
              getIndexDriverData = getHopeIndexDriverData;
              getIndexQuestionData = getHopeQuestion;
              getIndexOverallQuesData = [];
              getOverallDistribution = [];
              getOverallDriver = [];
            }
            else if (parseInt(item) === 4) {
              SummaryName = "Stress";
              getIndexV2Data = getStressIndexV2Data;
              getIndexDriverData = getStressIndexDriverData;
              getIndexQuestionData = getStressQuestion;
              getIndexOverallQuesData = getStressQuestionOverall;
              getOverallDistribution = getStressDistributionOverall;
              getOverallDriver = getStressDriverOverall;
            }


            if (parseInt(item) === 1 || parseInt(item) === 2 || parseInt(item) === 3 || parseInt(item) === 4) {
              OutPutData1234.push({
                "IndexNumber": IndexNumber,
                "SummaryName": SummaryName,
                "getIndexV2Data": getIndexV2Data,
                "getIndexDriverData": getIndexDriverData,
                "getIndexQuestionData": getIndexQuestionData,
                "getIndexOverallQuesData": getIndexOverallQuesData,
                "getOverallDistribution": getOverallDistribution,
                "getOverallDriver": getOverallDriver
              });
            }



          }
          else if (parseInt(item) === 5) {
            SummaryName = "Manager";
            getIndexV2Data = getManagerIndexV2Data;
            getIndexDriverData = getManagerIndexDriverData;
            getIndexQuestionData = getManagerQuestion;
            getIndexOverallQuesData = getManagerQuestionOverall;

            OutPutData56.push({
              "IndexNumber": IndexNumber,
              "SummaryName": SummaryName,
              "getIndexV2Data": getIndexV2Data,
              "getIndexDriverData": getIndexDriverData,
              "getIndexQuestionData": getIndexQuestionData,
              "getIndexOverallQuesData": getIndexOverallQuesData
            });
          }
          else if (parseInt(item) === 6) {
            SummaryName = "Leader";
            getIndexV2Data = getLeaderIndexV2Data;
            getIndexDriverData = getLeaderIndexDriverData;
            getIndexQuestionData = getLeaderQuestion;
            getIndexOverallQuesData = getLeaderQuestionOverall;

            OutPutData56.push({
              "IndexNumber": IndexNumber,
              "SummaryName": SummaryName,
              "getIndexV2Data": getIndexV2Data,
              "getIndexDriverData": getIndexDriverData,
              "getIndexQuestionData": getIndexQuestionData,
              "getIndexOverallQuesData": getIndexOverallQuesData
            });

          }


        }
      });
    }


    //1.Introduction
    let slideIntro = pres.addSlide();
    slideIntro.addImage({ path: surveyLogo, x: 0.4, y: 0.2, w: 1, h: 1 });
    slideIntro.addImage({ path: "img/logo/happypluslogo.png", x: 8, y: 0.3, w: 1, h: 0.5 });
    slideIntro.addImage({ path: "img/overallreport/employee_engagement.png", x: 5, y: 1, w: 4, h: 4 });

    let survey = getSurveyData && getSurveyData.name ? getSurveyData.name : "";
    slideIntro.addText(survey, { x: 0.4, y: 2.2, w: 4, color: "3b83f6", fontSize: 40 });

    let y02 = 0
    if (FilterValues && FilterValues.length > 0) {
      FilterValues.forEach((item, index01) => {
        let DMGName = (item && item["level"] ? getEMPFilterLabel(item["level"]) + "-" : "") + (item && item["valueList"] && item["valueList"].length > 0 ? item["valueList"].map((element) => element).join(",") : "");
        slideIntro.addText(DMGName, { x: 0.4, y: 3.5 + (index01 / 2), color: "000000", fontSize: 20 });
        if ((index01 + 1) === FilterValues.length) {
          y02 = 3.5 + ((index01 / 2))
        }
      })
    }
    let Data = moment().format("DD/MM/YYYY")
    slideIntro.addText("Date: " + Data, { x: 0.4, y: y02 ? (y02 + 0.5) : 3.5, color: "000000", fontSize: 20 });







    //2.Summary
    let SlideObj = {}

    if (OutPutData1234 && OutPutData1234.length > 0) {
      OutPutData1234.forEach((item, index) => {
        if (item["IndexNumber"] !== 4) {
          let slideDym = SlideObj['Slide' + (index + 1).toString()];
          slideDym = pres.addSlide();
          //Summary Heading
          let SummaryName = item["SummaryName"];
          slideDym.addText(SummaryName + " Summary", { x: 0.1, y: 0.2, color: "333333" });
          //Summary Index Score
          let indexTemplate = item["IndexNumber"];
          let indexValue = getIndexData && getIndexData[indexTemplate] && parseFloat(getIndexData[indexTemplate].toFixed(2)) ? parseFloat(getIndexData[indexTemplate].toFixed(2)) : 0;
          let indexOverallValue = getOverallIndexData && getOverallIndexData[indexTemplate] && parseFloat(getOverallIndexData[indexTemplate].toFixed(2)) ? parseFloat(getOverallIndexData[indexTemplate].toFixed(2)) : 0;


          //INDEX DONUT
          let datapie = [
            {
              name: SummaryName,
              labels: ["Overall : " + indexOverallValue + "%", "Score: " + indexValue + "%"],
              values: [100 - indexValue, indexValue]
            }
          ];



          let optpie = {
            x: 1.0,
            y: 0.3,
            w: 3.0,
            h: 2.1,

            barDir: "col",
            bar3DShape: "coneToMax",
            chartColors: ["FFFFFF", "3399ff"],

            catAxisLabelColor: "1a0000",
            catAxisLabelFontFace: "Times",

            catAxisLabelFontSize: 8,
            valAxisLabelFontSize: 8,

            catAxisOrientation: "minMax",

            dataBorder: { pt: "1", color: "F1F1F1" },
            dataLabelColor: "FFFFFF",
            dataLabelFontFace: "Arial",
            dataLabelFontSize: 10,
            dataLabelPosition: "ctr",
            holeSize: 60,
            showLegend: true,
            legendPos: "r",
            legendColor: "000000",
            showTitle: true,
            titleColor: "000000",
            title: SummaryName + " Score",
            titleFontSize: 10
          };


          slideDym.addChart(pres.ChartType.doughnut, datapie, optpie);






          //Summary Responses

          let ParticipationObj = {
            total: 0,
            completed: 0,
            mailSent: 0,
            clicked: 0,
            participated: 0,
            performance: 0,
            perfPlusSign: true
          }

          if (getRespondantDetailsData && getRespondantDetailsData[0]) {
            let apiData = getRespondantDetailsData[0]
            ParticipationObj['total'] = apiData['total'] ? apiData['total'] : 0;
            ParticipationObj['completed'] = apiData['completed'] ? apiData['completed'] : 0;
            ParticipationObj['mailSent'] = apiData['mailSent'] ? apiData['mailSent'] : 0;
            ParticipationObj['clicked'] = apiData['clicked'] ? apiData['clicked'] : 0;
            ParticipationObj['participated'] = parseFloat(apiData['completed'] && apiData['total'] ? (apiData['completed'] * 100 / apiData['total']).toFixed(2) : 0);



          }

          //RESPONSE DONUT
          let dataChartAreaLine = [
            {
              name: "Response Rate",
              labels: ["Response Rate: " + ParticipationObj['participated'], "Total : " + ParticipationObj['mailSent'], "Responses: " + ParticipationObj['completed']],
              values: [100 - ParticipationObj['participated'], ParticipationObj['participated']],
            }


          ];
          let optsChartBar4 = {


            x: 5.5,
            y: 0.3,
            w: 3.0,
            h: 2.1,


            barDir: "col",
            bar3DShape: "coneToMax",
            chartColors: ["FFFFFF", "5cd65c", "5cd65c"],

            catAxisLabelColor: "1a0000",
            catAxisLabelFontFace: "Times",

            catAxisLabelFontSize: 8,
            valAxisLabelFontSize: 8,

            catAxisOrientation: "minMax",

            dataBorder: { pt: "1", color: "F1F1F1" },
            dataLabelColor: "FFFFFF",
            dataLabelFontFace: "Arial",
            dataLabelFontSize: 10,
            dataLabelPosition: "ctr",
            holeSize: 60,
            showLegend: true,
            legendPos: "r",
            legendColor: "000000",
            showTitle: true,
            titleColor: "000000",
            title: "Response Rate",
            titleFontSize: 10,

          };

          slideDym.addChart(pres.ChartType.doughnut, dataChartAreaLine, optsChartBar4);



          let getOverallDistribution = item["getOverallDistribution"];
          let getOverallDriver = item["getOverallDriver"];



          // Summary Distribution

          let HappinessLabels = ['Unhappy', 'ModeratelyHappy', 'Happy', 'Happiest'];
          let HappinessLabelsColors = ["e53935", "ffc107", "00cd7e", "ff4081"]

          let EngagementLabels = ['ActivelyDisengaged', 'Passive', 'ModeratelyEngaged', 'HighlyEngaged'];
          let EngagementLabelsColors = ["d12e2e", "f4ae34", "a2e37d", "00cc66"]

          let StressLabels = ['NeedImmediateattention', 'OftenStressed', 'ModeratelyWell', 'WellManagedStess'];
          let StressLabelsColors = ["e53935", "ffc107", "00cd7e", "ff4081"]


          function GetValueList(list, DistributionData) {
            let valueList = [];
            if (list && list.length > 0) {
              list.forEach((item1) => {
                valueList.push(DistributionData && DistributionData[item1] && parseFloat(DistributionData[item1]) ? parseFloat(DistributionData[item1]) : 0)
              });
            }
            return valueList
          }

          let DistributionLabels = [];
          let DistributionColors = [];
          let DistributionValues = [];
          let DistributionOverall = "";


          function getOverallDist(itemY) {
            let OverallDist = getOverallDistribution && getOverallDistribution[itemY] && parseFloat(getOverallDistribution[itemY]) ? parseFloat(getOverallDistribution[itemY]) : 0
            return " - " + OverallDist.toFixed(2) + "%"
          }


          // slideDym.addText(SummaryName+" Summary", { x: 0.1, y: 0.2, color: "333333" });



          if (parseInt(indexTemplate) === 1) {
            DistributionLabels = HappinessLabels;
            DistributionColors = HappinessLabelsColors;
            DistributionValues = GetValueList(HappinessLabels, getHappinessIndexV2Data);
            DistributionOverall = HappinessLabels.map((itemX) => ("" + itemX + getOverallDist(itemX))).join(", ");

          }
          else if (parseInt(indexTemplate) === 2) {
            DistributionLabels = EngagementLabels;
            DistributionColors = EngagementLabelsColors;
            DistributionValues = GetValueList(EngagementLabels, getEngagementIndexV2Data);
            DistributionOverall = EngagementLabels.map((itemX) => ("" + itemX + getOverallDist(itemX))).join(", ");


          }
          else if (parseInt(indexTemplate) === 4) {
            DistributionLabels = StressLabels;
            DistributionColors = StressLabelsColors;
            DistributionValues = GetValueList(StressLabels, getStressIndexV2Data);
            DistributionOverall = StressLabels.map((itemX) => ("" + itemX + getOverallDist(itemX))).join(", ");

          }


          //////console.log("@@@@@@@@ DistributionOverall------->",DistributionOverall)



          let dataChartAreabar = [
            {
              name: SummaryName + " Distribution",
              labels: DistributionLabels,
              values: DistributionValues
            }

          ]

          let optsChartBar = {
            x: 1.0,
            y: 2.5,
            w: 3.5,
            h: 3.0,

            barDir: "col",
            bar3DShape: "coneToMax",
            chartColors: DistributionColors,

            catAxisLabelColor: "1a0000",
            catAxisLabelFontFace: "Times",

            catAxisLabelFontSize: 8,
            valAxisLabelFontSize: 8,

            catAxisOrientation: "minMax",

            dataBorder: { pt: "1", color: "F1F1F1" },
            dataLabelColor: "FFFFFF",
            dataLabelFontFace: "Arial",
            dataLabelFontSize: 10,
            dataLabelPosition: "ctr",
            barGrouping: "stacked",

            showLegend: false,
            legendPos: "b",
            legendColor: "000000",
            showTitle: true,
            titleColor: "000000",
            title: SummaryName + " Distribution Overall: " + DistributionOverall,
            titleFontSize: 9,
            showValue: true,
            valGridLine: { color: 'F1F1F1' }
          };

          slideDym.addChart(pres.ChartType.bar, dataChartAreabar, optsChartBar);






          //Summary Drivers

          function DriverScore(DriverData) {
            let Labels = [];
            let Parameters = [];
            let Scores = [];
            let Colors = [];

            let getIndexDriverList = DriverData ? Object.keys(DriverData) : []

            let valueCount = 0
            if (getIndexDriverList && getIndexDriverList.length > 0) {
              getIndexDriverList.forEach((item) => {
                if (item && item !== "count" && item !== "name" && (parseInt(item) !== 1 && parseInt(item) !== 2 && parseInt(item) !== 3 && parseInt(item) !== 4 && parseInt(item) !== 5 && parseInt(item) !== 6 && parseInt(item) !== 7 && parseInt(item) !== 8)) {
                  valueCount++

                  Parameters.push(item.replace("Count", ""));
                  Labels.push(GetParameterLabel(item.replace("Count", "")));
                  Scores.push((parseFloat(DriverData && DriverData[item] && DriverData[item].toFixed(2) ? DriverData[item].toFixed(2) : 0)));
                  Colors.push("2196f3")
                }

              });
            }
            return {
              "Parameters": Parameters,
              "Labels": Labels,
              "Scores": Scores,
              "Colors": Colors
            }
          }


          function getOverallDriverFn(itemY) {
            let OverallDriver = getOverallDriver && getOverallDriver[itemY] && parseFloat(getOverallDriver[itemY]) ? parseFloat(getOverallDriver[itemY]) : 0
            return " - " + OverallDriver.toFixed(2) + "%"
          }

          //////console.log("SSS getOverallDriver---->",getOverallDriver)

          //////console.log("SSS dataline[]---->",DriverScore(item["getIndexDriverData"])["Labels"])

          let dataline = [

            {
              name: SummaryName + " Driver",
              labels: DriverScore(item["getIndexDriverData"])["Labels"],
              values: DriverScore(item["getIndexDriverData"])["Scores"],
            }

          ];

          let DriverOverall = DriverScore(item["getIndexDriverData"])["Parameters"].map((itemX) => ("" + itemX + getOverallDriverFn(itemX + "Count"))).join(", ");



          let optline = {
            x: 5.5,
            y: 2.5,
            w: 3.5,
            h: 3.0,


            barDir: "bar",
            bar3DShape: "coneToMax",
            chartColors: DriverScore(item["getIndexDriverData"])["Colors"],

            catAxisLabelColor: "1a0000",
            catAxisLabelFontFace: "Times",

            catAxisLabelFontSize: 8,
            valAxisLabelFontSize: 8,

            catAxisOrientation: "minMax",

            dataBorder: { pt: "1", color: "F1F1F1" },
            dataLabelColor: "FFFFFF",
            dataLabelFontFace: "Arial",
            dataLabelFontSize: 10,
            dataLabelPosition: "ctr",

            showLegend: false,
            legendPos: "b",
            legendColor: "000000",
            showTitle: true,
            titleColor: "000000",
            title: SummaryName + " Driver Overall: " + DriverOverall,
            titleFontSize: 9,
            showValue: true,
            valGridLine: { color: 'F1F1F1' }


          };


          slideDym.addChart(pres.ChartType.bar, dataline, optline);


          //Index Table Behavior




          if (parseInt(indexTemplate) !== 4) {

            let iDLabel = "Behaviors" + (indexTemplate).toString();
            pres.tableToSlides(iDLabel, {
              addText: { text: SummaryName + " Behaviors Item", options: { x: 0.1, y: 0.2, color: "333333" } }
            });

            //Index Table Items
            let iDLabel2 = "OrgCoreItems" + (indexTemplate).toString();

            pres.tableToSlides(iDLabel2, {
              addText: { text: SummaryName + " Item Level Detail Analysis", options: { x: 0.1, y: 0.2, color: "333333" } }
            });

          }









        }
      });
    }



    function calcOverall(itemNamw) {
      return getOrgDriverOverallData && getOrgDriverOverallData[itemNamw] && getOrgDriverOverallData[itemNamw].toFixed(2) ? getOrgDriverOverallData[itemNamw].toFixed(2) : 0
    }


    //Employee Experience Across Organizational Core
    let SlideX2 = pres.addSlide();

    SlideX2.addText("Employee Experience Across Organizational Core", { x: 0.1, y: 0.2, color: "333333" });

    //Organization Drivers
    let OrgCoreDriverData = {};
    if (getOrgIndexV2Data && getSurveyData && getSurveyData.orgList && getSurveyData.orgList.length > 0) {
      getSurveyData.orgList.forEach((org) => {
        OrgCoreDriverData[org.name] = getOrgIndexV2Data[isFilter ? org.name : org.name];
      })
    }

    //////console.log("YYYY----getOrgIndexV2Data----->",getOrgIndexV2Data);
    //////console.log("YYYY----getSurveyData.orgList----->",getSurveyData.orgList);

    let isAllow = getOrgIndexV2Data ? (CountRestrict(getOrgIndexV2Data && getOrgIndexV2Data["count"] ? getOrgIndexV2Data["count"] : 0) ? false : true) : false;

    let valueList = []
    if (OrgCoreDriverData) {
      valueList = Object.keys(OrgCoreDriverData).map((key) => [key, (isAllow ? OrgCoreDriverData[key] : "0")]);
    }
    //////console.log("YYYY----OrgCoreDriverData----->",OrgCoreDriverData);

    valueList.sort((b, a) => a[1] - b[1]);

    //////console.log("YYYY----valueList----->",valueList);

    let getLabels3 = [];
    let getScores3 = [];
    let getColors3 = []

    if (valueList && valueList.length > 0) {
      valueList.forEach((item) => {
        getLabels3.push(GetParameterLabel(item[0]) + " (Overall: " + calcOverall(item[0]) + "%) ");
        getScores3.push(item[1]);
        getColors3.push("2196f3")

      });
    }
    let dataline3 = [


      {
        name: "Organization Score Drivers",
        labels: getLabels3,
        values: getScores3,
      }


    ];
    let optline3 = {
      x: 0.5,
      y: 0.4,
      w: 8.5,
      h: 5.1,


      barDir: "bar",
      bar3DShape: "coneToMax",
      chartColors: getColors3,

      catAxisLabelColor: "1a0000",
      catAxisLabelFontFace: "Times",

      catAxisLabelFontSize: 8,
      valAxisLabelFontSize: 8,

      catAxisOrientation: "minMax",
      // dataLabelFormatCode:'#,##0',hide labels?
      dataLabelColor: "0088CC",
      // catLabelFormatCode:'#,##0' ,
      dataLabelPosition: "bestFit",

      dataBorder: { pt: "1", color: "F1F1F1" },
      dataLabelColor: "FFFFFF",
      dataLabelFontFace: "Arial",
      dataLabelFontSize: 10,
      dataLabelPosition: "ctr",


      showLegend: false,
      legendPos: "b",
      legendColor: "000000",
      showTitle: false,
      titleColor: "000000",
      title: "",
      titleFontSize: 10,
      showValue: true,

      valGridLine: { color: 'F1F1F1' }

    };


    SlideX2.addChart(pres.ChartType.bar, dataline3, optline3);





    //Strength
    pres.tableToSlides("Strength", {
      addText: { text: "Emerging Areas Of Strength For Highest Scoring Experiences", options: { x: 0.1, y: 0.2, color: "333333" } }
    });

    //Weakness
    pres.tableToSlides("Weakness", {
      addText: { text: "Emerging Areas Of Weakness/Focus For Lowest Scoring Experiences", options: { x: 0.1, y: 0.2, color: "333333" } }
    });




    //EngagementORG
    let FinalData = []
    if (getDemographicIndexMultiFilterData && getDemographicIndexMultiFilterData.length > 0) {
      getDemographicIndexMultiFilterData.forEach((item) => {



        ////////console.log("0-----0000--->",item)

        let tempData = {
          "xAxis": item.xAxis,
          "data": []
        }
        let innerList = []
        if (item && item.Data && item.Data.length > 0) {
          item.Data.forEach((inner) => {

            let score = CountRestrict(inner.count) ? 0 : inner[2];

            innerList.push([inner.name, score])
          })
        }

        innerList.sort((a, b) => (a && a[0] && a[0].toString() ? (a[0]).toString().charCodeAt(0) : "") - (b && b[0] && a[0].toString() ? (b[0]).toString().charCodeAt(0) : ""))
        tempData["data"] = innerList
        FinalData.push(tempData)
      });




    }



    let SlideObjY = {}

    if (FinalData && FinalData.length > 0) {
      FinalData.forEach((item5, index2) => {

        // let SlideYDym=SlideObj['SlideY'+(index2+1).toString()];
        // SlideYDym = pres.addSlide();


        let slideDym = SlideObjY['Slide' + (index2 + 1).toString()];

        slideDym = pres.addSlide();




        let name = "Engagement Across Demographics - " + getEMPFilterLabel(item5.xAxis);
        slideDym.addText(name, { x: 0.1, y: 0.2, color: "333333", color: "333333" });


        let getLabels3x = [];
        let getScores3x = [];
        let getColors3x = []

        valueList = item5.data;
        if (valueList && valueList.length > 0) {
          valueList.forEach((item) => {
            getLabels3x.push(item[0]);
            getScores3x.push(item[1]);
            getColors3x.push("2196f3")

          });
        }
        let dataline3 = [


          {
            name: item5.xAxis,
            labels: getLabels3x,
            values: getScores3x,
          }


        ];
        let optline3 = {
          x: 0.5,
          y: 0.4,
          w: 8.5,
          h: getScores3x && getScores3x.length > 2 ? 5.1 : 2.5,


          barDir: "bar",
          bar3DShape: "coneToMax",
          chartColors: getColors3x,

          catAxisLabelColor: "1a0000",
          catAxisLabelFontFace: "Times",

          catAxisLabelFontSize: 8,
          valAxisLabelFontSize: 8,

          catAxisOrientation: "minMax",
          // dataLabelFormatCode:'#,##0',hide labels?
          dataLabelColor: "0088CC",
          // catLabelFormatCode:'#,##0' ,
          dataLabelPosition: "bestFit",

          dataBorder: { pt: "1", color: "F1F1F1" },
          dataLabelColor: "FFFFFF",
          dataLabelFontFace: "Arial",
          dataLabelFontSize: 10,
          dataLabelPosition: "ctr",

          showLegend: false,
          legendPos: "b",
          legendColor: "000000",
          showTitle: false,
          titleColor: "000000",
          title: "",
          titleFontSize: 10,
          showValue: true,
          valGridLine: { color: 'F1F1F1' }
        };


        slideDym.addChart(pres.ChartType.bar, dataline3, optline3);



      });
    }








    //orgDMG
    let FinalData3 = []
    if (getDemographicOrgReportData && getDemographicOrgReportData.length > 0) {
      getDemographicOrgReportData.forEach((item) => {
        let tempData = {
          "xAxis": item.xAxis,
          "Data": []
        }
        let innerList = []
        if (item && item.Data && item.Data.length > 0) {
          item.Data.forEach((inner) => {

            innerList.push(inner)
          })
        }

        // innerList.sort((a,b)=>a.name.charCodeAt(0) - b.name.charCodeAt(0))
        innerList.sort((a, b) => (a && a.name && a.name.toString() ? (a.name).toString().charCodeAt(0) : "") - (b && b.name && b.name.toString() ? (b.name).toString().charCodeAt(0) : ""))

        tempData["Data"] = innerList
        FinalData3.push(tempData)
      });
    }


    if (FinalData3 && FinalData3.length > 0) {
      FinalData3.forEach((item5, index2) => {

        // let SlideYDym=SlideObj['SlideY'+(index2+1).toString()];
        // SlideYDym = pres.addSlide();



        //////console.log("item5---->",item5)


        if (item5 && item5.Data.length > 0) {
          item5.Data.forEach((itemInner) => {

            let iDLabel = "orgDMG" + (item5.xAxis).toString() + (itemInner.name).toString();

            pres.tableToSlides(iDLabel, {
              addText: { text: ("Organization Core Across Demographics - " + getEMPFilterLabel(item5.xAxis)), options: { x: 0.1, y: 0.2, color: "333333" } }
            });

          })
        }








      })
    }







    // Manager & Leader
    let SlideX = {}

    if (OutPutData56 && OutPutData56.length > 0) {
      OutPutData56.forEach((item, index) => {
        if (index === 0 || true) {

          let slideDym = SlideX['Slide' + (index + 1).toString()];

          slideDym = pres.addSlide();

          //Heading
          let SummaryName = item["SummaryName"];
          slideDym.addText(SummaryName + " Play A Pivotal Role In Driving Employee Experience", { x: 0.1, y: 0.2, color: "333333" });

          //Index Score
          let indexTemplate = item["IndexNumber"];
          let indexValue = getIndexData && getIndexData[indexTemplate] && parseFloat(getIndexData[indexTemplate].toFixed(2)) ? parseFloat(getIndexData[indexTemplate].toFixed(2)) : 0;
          let indexOverallValue = getOverallIndexData && getOverallIndexData[indexTemplate] && parseFloat(getOverallIndexData[indexTemplate].toFixed(2)) ? parseFloat(getOverallIndexData[indexTemplate].toFixed(2)) : 0;

          let datapie = [

            {
              name: SummaryName,
              labels: ["Overall : " + indexOverallValue + "%", "Score: " + indexValue + "%"],
              values: [100 - indexValue, indexValue]
            }

          ];
          let optpie = {
            x: 1.0,
            y: 0.3,
            w: 2.5,
            h: 2.3,

            barDir: "col",
            bar3DShape: "coneToMax",
            chartColors: ["FFFFFF", "3399ff"],

            catAxisLabelColor: "1a0000",
            catAxisLabelFontFace: "Times",

            catAxisLabelFontSize: 8,
            valAxisLabelFontSize: 8,

            catAxisOrientation: "minMax",

            dataBorder: { pt: "1", color: "F1F1F1" },
            dataLabelColor: "FFFFFF",
            dataLabelFontFace: "Arial",
            dataLabelFontSize: 10,
            dataLabelPosition: "ctr",
            holeSize: 60,
            showLegend: true,
            legendPos: "r",
            legendColor: "000000",
            showTitle: true,
            titleColor: "000000",
            title: SummaryName + " Score",
            titleFontSize: 10
          };
          slideDym.addChart(pres.ChartType.doughnut, datapie, optpie);




          //Index Drivers

          function DriverScore(DriverData) {
            let Labels = [];
            let Scores = [];
            let Colors = [];

            let getIndexDriverList = DriverData ? Object.keys(DriverData) : []

            let valueCount = 0
            if (getIndexDriverList && getIndexDriverList.length > 0) {
              getIndexDriverList.forEach((item) => {
                if (item && item !== "count" && item !== "name" && (parseInt(item) !== 1 && parseInt(item) !== 2 && parseInt(item) !== 3 && parseInt(item) !== 4 && parseInt(item) !== 5 && parseInt(item) !== 6 && parseInt(item) !== 7 && parseInt(item) !== 8)) {
                  valueCount++

                  Labels.push(GetParameterLabel(item.replace("Avg", "")));
                  Scores.push((parseFloat(DriverData && DriverData[item] && DriverData[item].toFixed(2) ? DriverData[item].toFixed(2) : 0)));
                  Colors.push("2196f3")
                }

              });
            }
            return {
              "Labels": Labels,
              "Scores": Scores,
              "Colors": Colors
            }
          }


          let dataline = [


            {
              name: SummaryName + " Driver",
              labels: DriverScore(item["getIndexDriverData"])["Labels"],
              values: DriverScore(item["getIndexDriverData"])["Scores"],
            }


          ];


          let optline = {
            x: 5.5,
            y: 0.3,
            w: 3.5,
            h: 3.0,


            barDir: "bar",
            bar3DShape: "coneToMax",
            chartColors: DriverScore(item["getIndexDriverData"])["Colors"],

            catAxisLabelColor: "1a0000",
            catAxisLabelFontFace: "Times",

            catAxisLabelFontSize: 8,
            valAxisLabelFontSize: 8,

            catAxisOrientation: "minMax",

            dataBorder: { pt: "1", color: "F1F1F1" },
            dataLabelColor: "FFFFFF",
            dataLabelFontFace: "Arial",
            dataLabelFontSize: 10,
            dataLabelPosition: "ctr",

            showLegend: false,
            legendPos: "b",
            legendColor: "000000",
            showTitle: true,
            titleColor: "000000",
            title: SummaryName + " Driver ",
            titleFontSize: 10,
            showValue: true,
            valGridLine: { color: 'F1F1F1' }
          };


          slideDym.addChart(pres.ChartType.bar, dataline, optline);







          //Manager Behaviour


          let iDLabel = "Behavior2" + (indexTemplate).toString();
          pres.tableToSlides(iDLabel, {
            addText: { text: SummaryName + " Behaviors Item", options: { x: 0.1, y: 0.2, color: "333333" } }
          });



        }
      }
      )
    }




    pres.tableToSlides("OrgCoreItems8", {
      addText: { text: "Organization Core Item Level Detail Analysis", options: { x: 0.1, y: 0.2, color: "333333" } }
    });



    let FinalData2 = []
    if (getDemographicIndexMultiFilterEngCompData && getDemographicIndexMultiFilterEngCompData.length > 0) {
      getDemographicIndexMultiFilterEngCompData.forEach((item) => {
        let tempData = {
          "xAxis": item.xAxis,
          "Data": []
        }
        let innerList = []
        if (item && item.Data && item.Data.length > 0) {
          item.Data.forEach((inner) => {

            let score = CountRestrict(inner.count) ? "NaN" : inner[2];

            innerList.push([inner.name, score])
          })
        }

        innerList.sort((a, b) => (a && a[0] && a[0].toString() ? (a[0]).toString().charCodeAt(0) : "") - (b && b[0] && a[0].toString() ? (b[0]).toString().charCodeAt(0) : ""))
        tempData["Data"] = innerList
        FinalData2.push(tempData)
      });
    }





    //Engagement Comparison
    function calcEngCompOverall(axisItem, dataItem) {
      let overallX = 0;
      if (getDemographicIndexEngCompOverallData && getDemographicIndexEngCompOverallData.length > 0) {
        let getIndex1 = getDemographicIndexEngCompOverallData.findIndex(prev => prev.xAxis === axisItem);
        if (getIndex1 !== -1) {
          let overallData = getDemographicIndexEngCompOverallData[getIndex1];
          let innerOverallList = overallData && overallData.Data ? overallData.Data : [];
          let getIndex2 = innerOverallList.findIndex(prev => prev.name === dataItem);
          let innerData = innerOverallList[getIndex2];
          overallX = innerData && innerData[2] ? innerData[2] : 0

        }
      }
      // //////console.log("item-ssssss--------->",item)
      return overallX && overallX.toFixed(2) ? overallX.toFixed(2).toString() : 0
    }


    let SlideObjY2 = {}

    if (FinalData2 && FinalData2.length > 0) {
      FinalData2.forEach((item5, index2) => {

        // let SlideYDym=SlideObj['SlideY'+(index2+1).toString()];
        // SlideYDym = pres.addSlide();


        let slideDym = SlideObjY2['Slide' + (index2 + 1).toString()];

        slideDym = pres.addSlide();
        let name = "Engagement Comparison - " + getEMPFilterLabel(item5.xAxis);
        slideDym.addText(name, { x: 0.1, y: 0.2, color: "333333" });


        let getLabels3x = [];
        let getScores3x = [];
        let getColors3x = []

        valueList = item5.Data;
        if (valueList && valueList.length > 0) {
          valueList.forEach((item) => {
            getLabels3x.push(item[0] + " (Overall - " + calcEngCompOverall(item5.xAxis, item[0]) + "%) ");
            getScores3x.push(item[1]);
            getColors3x.push("2196f3")

          });
        }
        let dataline3 = [


          {
            name: item5.xAxis,
            labels: getLabels3x,
            values: getScores3x,
          }


        ];
        let optline3 = {
          x: 0.5,
          y: 0.4,
          w: 8.5,
          h: getScores3x && getScores3x.length > 2 ? 5.1 : 2.5,


          barDir: "bar",
          bar3DShape: "coneToMax",
          chartColors: getColors3x,



          catAxisLabelColor: "1a0000",
          catAxisLabelFontFace: "Times",

          catAxisLabelFontSize: 8,
          valAxisLabelFontSize: 8,

          catAxisOrientation: "minMax",
          // dataLabelFormatCode:'#,##0',hide labels?
          dataLabelColor: "0088CC",
          // catLabelFormatCode:'#,##0' ,
          dataLabelPosition: "bestFit",

          dataBorder: { pt: "1", color: "F1F1F1" },
          dataLabelColor: "FFFFFF",
          dataLabelFontFace: "Arial",
          dataLabelFontSize: 10,
          dataLabelPosition: "ctr",

          showLegend: false,
          legendPos: "b",
          legendColor: "000000",
          showTitle: false,
          titleColor: "000000",
          title: "",
          titleFontSize: 10,
          showValue: true,
          valGridLine: { color: 'F1F1F1' }
        };


        slideDym.addChart(pres.ChartType.bar, dataline3, optline3);



      });
    }

    pres.writeFile("report");

  }


  render() {
    let { showReportDMGModal, getRespondantDetailsData, onLoadDMGReportConfirmation } = this.props;
    let { optionType } = this.state;

    let showLoadButton = getRespondantDetailsData && getRespondantDetailsData[0] && getRespondantDetailsData[0].mailSent && getRespondantDetailsData[0].mailSent >= 5 ? true : false

    return (

      <main className="flex-1 w-full bg-[#f9fafb] overflow-hidden overflow-y-auto h-[calc(100vh-7rem)] ">
        {showReportDMGModal ?
          <>
            <LoadConfirmModal
              showLoadButton={showLoadButton}
              onLoadDMGReportConfirmation={onLoadDMGReportConfirmation}
            />
          </>
          :
          <>
            {this.onSwitchOptionScreen()}
          </>
        }
      </main>


    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(StrengthAndWeaknesses);
