import React, { Component } from "react";
// import EmpChart from './Chart/EmpChart';
// import BarDistribution from './Chart/BarDistribution';
import Header from "../Components/Header";
import ReactWordcloud from "react-wordcloud";
import "tippy.js/dist/tippy.css";

class TextWorldCloud extends Component {
  render() {
    let {
      surveyLogo,
      questionData

    } = this.props;


    const options = {
      rotations: 2,
      rotationAngles: [0, -90],
      padding: 5,
      fontWeight: 600,
      fontSizes: [20, 50],
    };



    let words = [];
    let wordCloudData = questionData && questionData.response ? questionData.response : "";
    if (wordCloudData && wordCloudData.list && wordCloudData.list.length > 0) {
      wordCloudData.list.forEach((item) => {
        words.push({
          text: item[0],
          value: item[1],
        });
      });
    }


    let newWords = []
    if (words && words.length > 0) {
      for (var i = 0; i < words.length; i++) {
        let ele = words[i];
        newWords.push(ele);
      }
    }



    let newV2Words = []

    if (newWords && newWords.length > 0) {
      for (var i = 0; i < newWords.length; i++) {
        let ele = newWords[i];
        newV2Words.push(ele);
      }
    }


    return (
      <div>
        {/* Bottom 10 Focus Areas For Lowest Scoring Experiences8888 */}

        <div className="pt-4 page-break">
          <Header surveyLogo={surveyLogo} />

          <div className>
            <div className="w-full px-8 py-6 capitalize ">
              <h1 className="text-2xl text-[#3D405B] font-semibold border-b border-[#DDDDDD] pb-4 capitalize">
                {questionData && questionData.name ? questionData.name : ""}
              </h1>
            </div>
          </div>
        </div>

        <main className="w-full px-8 mt-4">
          {true ? (
            <>
              <ReactWordcloud
                callbacks={{}}
                options={options}
                words={newV2Words}
              />
            </>
          ) : (
            <div className="p-4 text-3xl text-center text-gray-400">
              Data not available!
            </div>
          )}
        </main>
      </div>
    );
  }
}
export default TextWorldCloud;
