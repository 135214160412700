import React, { Component } from "react";
import { connect } from "react-redux";
import Select from 'react-select';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import moment from "moment";
import Summary from "./SubComponent/Summary/Summary";
import SummaryTwo from "./SubComponent/Summary/SummaryTwo";
// import RWAIndex from './SubComponent/RWAIndex/RWAIndex';
import Strength from "./SubComponent/Strength/Strength";
import Weakness from "./SubComponent/Weakness/Weakness";
import ManagerOverall from "./SubComponent/ManagerOverall/ManagerOverall";
import Behaviors from "./SubComponent/Behaviors/Behaviors";
import OrgCoreDriver from "./SubComponent/OrgCoreDrivers/OrgCoreDrivers";
import EngagementDMG from "../../../Report/Components/OverallReport/SubComponent/EngagementDMG/EngagementDMG";
import OrgDMG from "../../../Report/Components/OverallReport/SubComponent/OrgDMG/OrgDMG";
import OrgCoreItems from "../../../Report/Components/OverallReport/SubComponent/OrgCoreItems/OrgCoreItems";
import Header from "./SubComponent/Components/Header";
import PageTemplate from "./PageTemplate";
import ReportIntro from "./SubComponent/ReportIntro/ReportIntro";
import EngagementComparison from "./SubComponent/EngagementComparison/EngagementComparison";
// import QuestionComments from './SubComponent/QuestionComments/QuestionComments';
import FilterSummary from "./SubComponent/FilterSummary/FilterSummary";
import Filter from "./Filter";
import NPSChart from "./SubComponent/NPS/NPSChart";
import NPSDmg from "./SubComponent/NPS/NPSDmg";
import { FaRegFileExcel, FaRegFilePdf } from "react-icons/fa";


// let ref = React.useRef(null);
// let pdfExportComponent = ref;

class OverallReport extends Component {
  constructor(props) {
    super(props);
    this.pdfExportComponent = React.createRef();

    this.state = {
      optionType: 1,

      ReportFilterObj: {
        level: "Overall",
        value: "",
      },

      showExport: false,

      currentSelectValue: "",
      FilterValues: [],
      showFilter: false,
      showValue: "",
      isSelectTypeOverall: false,
      errorMsg: "",
      setPDF: true,
    };
  }
  componentDidMount() { }

  handleExportWithComponent = (event) => {
    this.setState({ setPDF: true }, () => {
      this.pdfExportComponent.current.save();
    });
  };

  render() {
    let { setPDF } = this.state;
    let {
      getQuestionFilterData,
      getDemographicOrgReportData,
      getDemographicIndexMultiFilterData,
      loadReport,
      getQuestionFavorability2Data,
      getRespondantDetailsData,
      GetParameterLabel,
      CountRestrict,
      getOrgIndexV2Data,
      getSurveyData,
      getEngagementRWAData,
      getQuestionFavorabilityData,
      getIndexData,
      getAllBenchMarkData,
      loadIndexReport,
      showExport,
      handleExportPPT,

      getQuestionOthersMultiResultFilterData,

      getHappinessQuestion,
      getEngagementQuestion,
      getHopeQuestion,
      getStressQuestion,
      getManagerQuestion,
      getLeaderQuestion,

      getHappinessIndexV2Data,
      getEngagementIndexV2Data,
      getHopeIndexV2Data,
      getStressIndexV2Data,
      getManagerIndexV2Data,
      getLeaderIndexV2Data,

      getHappinessIndexDriverData,
      getEngagementIndexDriverData,
      getHopeIndexDriverData,
      getStressIndexDriverData,
      getManagerIndexDriverData,
      getLeaderIndexDriverData,

      DMGLevelsList,
      DMGValuesList,
      ReportFilterObj,

      isFilter,

      surveyLogo,

      getDemographicIndexMultiFilterEngCompData,

      getEMPFilterLabel,

      // currentSelectValue,
      FilterValues,
      showFilter,
      showValue,
      closeFilter,
      handleMultifilterIndexScore2,

      isSelectTypeOverall,
      // handleSelectTypeOverall,

      errorMsg,

      getIndexByFilterDMGData,

      getOverallIndexData,

      //Overall
      getHappinessQuestionOverall,
      getEngagementQuestionOverall,
      getStressQuestionOverall,
      getManagerQuestionOverall,
      getLeaderQuestionOverall,
      getOrgQuestionOverall,

      getOrgDriverOverallData,

      getHappinessDistributionOverall,
      getEngagementDistributionOverall,
      getStressDistributionOverall,

      getHappinessDriverOverall,
      getEngagementDriverOverall,
      getStressDriverOverall,

      getDemographicIndexEngCompOverallData,

      getQuestionOthersResultData,

      getQuestion2OthersResultFilterData,
    } = this.props;

    let allowAPI = true;

    function getIndexNew(numberIndex) {
      let check = false;
      let IndexValidList = getOverallIndexData
        ? Object.keys(getOverallIndexData)
        : [];
      let getIndex = IndexValidList.findIndex(
        (prev) => prev.toString() === numberIndex.toString()
      );
      //////console.log("IndexValidList--->", IndexValidList)
      //////console.log("numberIndex--->", numberIndex)

      if (getIndex !== -1) {
        check = true;
      }
      return check;
    }

    let IndexNewValueList = [];
    if (getIndexNew(2)) {
      IndexNewValueList.push("2");
    }
    if (getIndexNew(1)) {
      IndexNewValueList.push("1");
    }
    if (getIndexNew(4)) {
      IndexNewValueList.push("4");
    }
    if (getIndexNew(5)) {
      IndexNewValueList.push("5");
    }
    if (getIndexNew(6)) {
      IndexNewValueList.push("6");
    }
    //////console.log("IndexNewValueList--->", IndexNewValueList)

    let IndexNumber = 0;
    let SummaryName = "";
    let getIndexV2Data = "";
    let getIndexDriverData = "";
    let getIndexQuestionData = "";
    let getIndexOverallQuesData = "";
    let getOverallDistribution = "";
    let getOverallDriver = "";

    let OutPutData1234 = [];
    let OutPutData56 = [];

    if (IndexNewValueList && IndexNewValueList.length > 0) {
      IndexNewValueList.forEach((item) => {
        ////////console.log("--------before IndexNumber-->",item)
        if (item && getOverallIndexData && getOverallIndexData[item]) {
          ////////console.log("--------after IndexNumber-->",item)

          IndexNumber = parseInt(item);

          if (parseInt(item) !== 5 && parseInt(item) !== 6) {
            if (parseInt(item) === 1) {
              SummaryName = "Happiness";
              getIndexV2Data = getHappinessIndexV2Data;
              getIndexDriverData = getHappinessIndexDriverData;
              getIndexQuestionData = getHappinessQuestion;
              getIndexOverallQuesData = getHappinessQuestionOverall;
              getOverallDistribution = getHappinessDistributionOverall;
              getOverallDriver = getHappinessDriverOverall;
            } else if (parseInt(item) === 2) {
              SummaryName = "Engagement";
              getIndexV2Data = getEngagementIndexV2Data;
              getIndexDriverData = getEngagementIndexDriverData;
              getIndexQuestionData = getEngagementQuestion;
              getIndexOverallQuesData = getEngagementQuestionOverall;
              getOverallDistribution = getEngagementDistributionOverall;
              getOverallDriver = getEngagementDriverOverall;
            } else if (parseInt(item) === 3) {
              SummaryName = "Hope";
              getIndexV2Data = getHopeIndexV2Data;
              getIndexDriverData = getHopeIndexDriverData;
              getIndexQuestionData = getHopeQuestion;
              getIndexOverallQuesData = [];
              getOverallDistribution = [];
              getOverallDriver = [];
            } else if (parseInt(item) === 4) {
              SummaryName = "Stress";
              getIndexV2Data = getStressIndexV2Data;
              getIndexDriverData = getStressIndexDriverData;
              getIndexQuestionData = getStressQuestion;
              getIndexOverallQuesData = getStressQuestionOverall;
              getOverallDistribution = getStressDistributionOverall;
              getOverallDriver = getStressDriverOverall;
            }

            if (
              parseInt(item) === 1 ||
              parseInt(item) === 2 ||
              parseInt(item) === 3 ||
              parseInt(item) === 4
            ) {
              OutPutData1234.push({
                IndexNumber: IndexNumber,
                SummaryName: SummaryName,
                getIndexV2Data: getIndexV2Data,
                getIndexDriverData: getIndexDriverData,
                getIndexQuestionData: getIndexQuestionData,
                getIndexOverallQuesData: getIndexOverallQuesData,
                getOverallDistribution: getOverallDistribution,
                getOverallDriver: getOverallDriver,
              });
            }
          } else if (parseInt(item) === 5) {
            SummaryName = "Manager";
            getIndexV2Data = getManagerIndexV2Data;
            getIndexDriverData = getManagerIndexDriverData;
            getIndexQuestionData = getManagerQuestion;
            getIndexOverallQuesData = getManagerQuestionOverall;

            OutPutData56.push({
              IndexNumber: IndexNumber,
              SummaryName: SummaryName,
              getIndexV2Data: getIndexV2Data,
              getIndexDriverData: getIndexDriverData,
              getIndexQuestionData: getIndexQuestionData,
              getIndexOverallQuesData: getIndexOverallQuesData,
            });
          } else if (parseInt(item) === 6) {
            SummaryName = "Leader";
            getIndexV2Data = getLeaderIndexV2Data;
            getIndexDriverData = getLeaderIndexDriverData;
            getIndexQuestionData = getLeaderQuestion;
            getIndexOverallQuesData = getLeaderQuestionOverall;

            OutPutData56.push({
              IndexNumber: IndexNumber,
              SummaryName: SummaryName,
              getIndexV2Data: getIndexV2Data,
              getIndexDriverData: getIndexDriverData,
              getIndexQuestionData: getIndexQuestionData,
              getIndexOverallQuesData: getIndexOverallQuesData,
            });
          }
        }
      });
    }
    ////////console.log("IndexValidList",IndexValidList)

    // let temp = [DMGLevelsList, DMGValuesList]

    ////////console.log("ReportFilterObj---->",ReportFilterObj)

    // let level = ReportFilterObj && ReportFilterObj["level"] ? ReportFilterObj["level"] : "";
    // let DMGValuesObjList = DMGValuesList && DMGValuesList[level] ? DMGValuesList[level] : []

    let QueTemplate = 6;

    function FormattFun(inputData, isSummary) {
      let LIST = [];
      let prevTemList = [];
      if (inputData && inputData.length > 0) {
        inputData.forEach((ques) => {
          let templateId = ques.total[0]._id.qtemplate;
          if (QueTemplate === templateId) {
            let temp = {
              name:
                ques && ques.question[0] && ques.question[0].name
                  ? ques.question[0].name
                  : "",
              answerList: [],
              templateId: templateId,
            };
            if (ques && ques.total && ques.total.length > 0) {
              ques.total.forEach((item) => {
                let ans = {
                  count: item.count,
                  answerText: item._id.answer,
                  heading:
                    item && item._id && item._id.heading
                      ? item._id.heading
                      : "",
                  parentId:
                    item && item._id && item._id.parentId
                      ? item._id.parentId
                      : "",
                  template: item._id.qtemplate,
                  QuesId: item._id.questionId,
                };
                temp["answerList"].push(ans);
              });
            }

            if (isSummary) {
              let getIndex = prevTemList.findIndex(
                (prev) => prev === templateId
              );
              if (getIndex === -1) {
                LIST.push(temp);
                prevTemList.push(templateId);
              }
            } else {
              LIST.push(temp);
            }
          }
        });
      }
      return LIST;
    }

    let OthersQuesList = FormattFun(getQuestionOthersResultData, true);

    //console.log('---------- OthersQuesList-6---------: ', OthersQuesList);

    let item =
      OthersQuesList && OthersQuesList.length > 0 ? OthersQuesList[0] : {};

    let AnswerObj = {};
    if (item && item.answerList && item.answerList.length > 0) {
      ////console.log('item.answerList (STAR)',item.answerList)

      item.answerList.forEach((ans) => {
        if (ans && ans.answerText) {
          if (AnswerObj && AnswerObj[ans.answerText]) {
            AnswerObj[ans.answerText] += ans.count;
          } else {
            AnswerObj[ans.answerText] = ans.count;
          }
        }
      });
    }

    //console.log("FilterValues----", FilterValues)

    let name = "NPS";


    const colourOptions = [
      { value: 'Question Wise Demographic Score', label: 'Question Wise Demographic Score' },
      { value: 'Question Wise Top Bottom Score', label: 'Question Wise Top Bottom Score' },
    ]

    return (
      <>
        {/* {!loadReport ? (
          <div className=" bg-[#f9fafb] ">
            <main
              className="flex-1 w-full overflow-hidden overflow-y-auto  bg-[#f9fafb]  "
              style={{ height: "calc(100vh - 8rem)" }}
            >
              <div className="w-full p-6">
                <div className=" p-6  -m-6  bg-gradient-to-l from-sky-500  to-[#2196f3] h-60 space-y-6 flex flex-col ">
                  <h1 className="text-2xl font-medium text-white lg:text-3xl ">
                    Reports
                  </h1>
                  <p className="text-white">
                    This analytical report uses qualitative and quantitative statistical methods to analyze company data and empower the managers and business leaders to make data-driven decisions based on evidence and analytics.
                  </p>
                </div>

                <div className="-mt-10 md:-mt-16">
                  <div className="grid grid-cols-1 gap-10 p-4 bg-white border rounded-md lg:grid-cols-2 md:p-8 ">
                    <div className="flex items-center w-full h-full p-6 border ">
                      <div className="w-full space-y-6">
                        <div className="space-y-3 ">
                          <div className="flex items-center space-x-4 ">
                            <img
                              alt="card"
                              src="/img/modules/assessment.svg"
                              class="w-12 h-12"
                            />
                            <div className="text-[#3D405B]">
                              <h1 className="text-xl font-medium md:text-2xl">
                                Report
                              </h1>
                              <p className="text-sm font-normal md:text-base ">
                                Select Filter Name and it's values
                              </p>
                            </div>
                          </div>

                          <div />
                        </div>

                        {!isSelectTypeOverall ? (
                          <div className="rounded-md">
                            <Filter
                              showFilter={showFilter}
                              showValue={showValue}
                              closeFilter={closeFilter}
                              EmpFilterData2={DMGLevelsList}
                              getIndexFilterData={getIndexByFilterDMGData}
                              handleMultifilterIndexScore={
                                handleMultifilterIndexScore2
                              }
                              getEMPFilterLabel={getEMPFilterLabel}
                            />
                          </div>
                        ) : (
                          <div className=""
                          />
                        )}
                        <div className="space-y-4 ">
                          <p className="text-red-500 ">{errorMsg}</p>
                          <button
                            onClick={() => loadIndexReport(2, !loadReport)}
                            className="cursor-pointer bg-[#2196F3] text-white space-x-1 text-base  border-[#2196F3] flex justify-center items-center px-4 py-2.5 rounded-md w-full "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon icon-tabler icon-tabler-circle-plus"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                              <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
                              <rect x="9" y="3" width="6" height="4" rx="2" />
                              <line x1="9" y1="12" x2="9.01" y2="12" />
                              <line x1="13" y1="12" x2="15" y2="12" />
                              <line x1="9" y1="16" x2="9.01" y2="16" />
                              <line x1="13" y1="16" x2="15" y2="16" />
                            </svg>
                            <span> VIEW REPORT</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-end w-full">
                      <img src="/img/report.png" className="w-5/6 mx-auto" />
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        ) : (
          <div className="fixed top-0 left-0 z-20 w-full h-screen p-6 bg-black bg-opacity-40 2xl:p-10">
            <div className="mx-auto overflow-hidden overflow-y-auto bg-white rounded-md App 2xl:w-3/6 xl:w-4/6 lg:w-5/6 customscroll5">
              <div className="justify-between px-4 py-2 space-y-2 border-b border-gray-200 md:flex md:space-y-0">
                <div className="flex items-center space-x-4">
                  <span
                    onClick={() => loadIndexReport(0, !loadReport, true)}
                    className="p-2 text-blue-500 duration-150 rounded-full cursor-pointer material-symbols-outlined bg-blue-50 hover:bg-blue-100 trasition"
                    style={{ fontSize: "18px" }}
                  >
                    arrow_back
                  </span>

                  <h1 className="text-lg font-semibold md:text-xl line-clamp-1 text-slate-800">
                    {"Survey Report"}
                  </h1>
                </div>

                <div className="flex justify-between md:space-x-2">
                  {showExport ? (
                    <>
                      <button
                        className="flex  space-x-1 items-center p-2 px-3 text-sm border text-[#212121]/80 bg-white rounded-md cursor-pointer "
                        onClick={() => {
                          this.setState({ setPDF: true }, () => {
                            this.handleExportWithComponent();
                          });
                        }}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-file-type-pdf"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4" /><path d="M5 18h1.5a1.5 1.5 0 0 0 0 -3h-1.5v6" /><path d="M17 18h2" /><path d="M20 15h-3v6" /><path d="M11 15v6h1a2 2 0 0 0 2 -2v-2a2 2 0 0 0 -2 -2h-1z" /></svg>
                        <span>  Download PDF</span>
                      </button>
                      <button
                        className="flex items-center p-2 px-3 text-sm border text-[#212121]/80 bg-white rounded-md cursor-pointer "
                        onClick={() => {
                          this.setState({ setPDF: true }, () => {
                            this.handleExportWithComponent();
                          });
                        }}
                      >
                        Download Excel
                      </button>
                    </>
                  ) : (
                    <div className="text-gray-500 fontbold">
                      Loading Report....
                    </div>
                  )}
                </div>
              </div>
              <div
                className="overflow-y-auto divide-y "
                style={{ height: "calc(100vh - 8rem)" }}
              >
                <PDFExport ref={this.pdfExportComponent}
                  pageTemplate={(pageObj) => <PageTemplate pageNum={pageObj.pageNum} surveyLogo={surveyLogo} />}
                  scale={0.7}
                  forcePageBreak=".page-break"
                  paperSize="A4"
                  margin={0}
                  author="Happyplus Team"
                  landscape={false}
                  fileName={`${ReportFilterObj && ReportFilterObj["value"]
                    ? (getSurveyData && getSurveyData.name
                      ? getSurveyData.name +
                      (getSurveyData && getSurveyData.createdAt
                        ? moment
                          .unix(getSurveyData.createdAt / 1000)
                          .format(" - YYYY")
                        : "")
                      : "Dashboard Report") + ReportFilterObj["value"]
                    : getSurveyData && getSurveyData.name
                      ? getSurveyData.name +
                      (getSurveyData && getSurveyData.createdAt
                        ? moment
                          .unix(getSurveyData.createdAt / 1000)
                          .format(" - YYYY")
                        : "")
                      : "Dashboard Report"
                    }`}

                >
                  <ReportIntro
                    getSurveyData={getSurveyData}
                    surveyLogo={surveyLogo}
                    ReportFilterObj={ReportFilterObj}
                    FilterValues={FilterValues}
                    getEMPFilterLabel={getEMPFilterLabel}
                  />

                  <h3 className="page-break"></h3>

                  <FilterSummary
                    getEMPFilterLabel={getEMPFilterLabel}
                    FilterValues={FilterValues}
                  />
                  <h3 className="page-break"></h3>

                  {allowAPI ? (
                    <>
                      {OutPutData1234 && OutPutData1234.length > 0
                        ? OutPutData1234.map((item, index) => (
                          <>
                            {index !== 0 ? (
                              <h3 className="page-break"></h3>
                            ) : null}

                            <Summary
                              SummaryName={item["SummaryName"]}
                              getIndexV2Data={item["getIndexV2Data"]}
                              getIndexData={getIndexData}
                              getRespondantDetailsData={
                                getRespondantDetailsData
                              }
                              getAllBenchMarkData={getAllBenchMarkData}
                              getEngagementIndexDriverData={
                                item["getIndexDriverData"]
                              }
                              indexTemplate={item["IndexNumber"]}
                              isFilter={isFilter}
                              surveyLogo={surveyLogo}
                              GetParameterLabel={GetParameterLabel}
                              getOverallIndexData={getOverallIndexData}
                              getOverallDistribution={
                                item["getOverallDistribution"]
                              }
                              getOverallDriver={item["getOverallDriver"]}
                            />


                            <SummaryTwo
                              SummaryName={item["SummaryName"]}
                              getIndexV2Data={item["getIndexV2Data"]}
                              getIndexData={getIndexData}
                              getRespondantDetailsData={
                                getRespondantDetailsData
                              }
                              getAllBenchMarkData={getAllBenchMarkData}
                              getEngagementIndexDriverData={
                                item["getIndexDriverData"]
                              }
                              indexTemplate={item["IndexNumber"]}
                              isFilter={isFilter}
                              surveyLogo={surveyLogo}
                              GetParameterLabel={GetParameterLabel}
                              getOverallIndexData={getOverallIndexData}
                              getOverallDistribution={
                                item["getOverallDistribution"]
                              }
                              getOverallDriver={item["getOverallDriver"]}
                            />

                            {item["IndexNumber"] !== 4 ? (
                              <>
                                <Behaviors
                                  SummaryName={item["SummaryName"]}
                                  getQuestionData={
                                    item["getIndexQuestionData"]
                                  }
                                  GetParameterLabel={GetParameterLabel}
                                  CountRestrict={CountRestrict}
                                  surveyLogo={surveyLogo}
                                  indexTemplate={item["IndexNumber"]}
                                  setPDF={setPDF}
                                  getQuesOverallData={
                                    item["getIndexOverallQuesData"]
                                  }
                                />

                                <OrgCoreItems
                                  SummaryName={item["SummaryName"]}
                                  getIndexData={getIndexData}
                                  indexTemplate={item["IndexNumber"]}
                                  GetParameterLabel={GetParameterLabel}
                                  CountRestrict={CountRestrict}
                                  getQuestionFilterData={
                                    item["getIndexQuestionData"]
                                  }
                                  surveyLogo={surveyLogo}
                                  setPDF={setPDF}
                                  getQuesOverallData={
                                    item["getIndexOverallQuesData"]
                                  }
                                />
                              </>
                            ) : null}
                          </>
                        ))
                        : null}

                      {item &&
                        item.answerList &&
                        item.answerList[0] &&
                        item.answerList[0].template &&
                        item.answerList[0].template === 6 ? (
                        <>
                          <h3 className="page-break"></h3>

                          <div className="pt-4">
                            <Header surveyLogo={surveyLogo} />

                            <div className>
                              <div className="w-full px-8 py-6 capitalize ">
                                <div className="text-indigo-700 opacity-90"></div>
                                <h1 className="pb-2 text-xl font-semibold text-gray-800">
                                  NPS Summary
                                </h1>
                              </div>
                            </div>
                          </div>

                          {item &&
                            item.answerList &&
                            item.answerList[0] &&
                            item.answerList[0].template &&
                            item.answerList[0].template === 6 ? (
                            <NPSChart
                              AnswerObj={AnswerObj}
                              name={name}
                              templateId={6}
                              questionName={item.name}
                              stage={1}
                              index={1}
                            />
                          ) : null}

                          <h3 className="page-break"></h3>

                          <NPSDmg
                            getDemographicIndexMultiFilterEngCompData={
                              getDemographicIndexMultiFilterEngCompData
                            }
                            surveyLogo={surveyLogo}
                            GetParameterLabel={GetParameterLabel}
                            getEMPFilterLabel={getEMPFilterLabel}
                            CountRestrict={CountRestrict}
                            getQuestionOthersMultiResultFilterData={
                              getQuestionOthersMultiResultFilterData
                            }
                            getQuestion2OthersResultFilterData={
                              getQuestion2OthersResultFilterData
                            }
                          />
                        </>
                      ) : null}

                      <OrgCoreDriver
                        getOrgIndexV2Data={getOrgIndexV2Data}
                        getSurveyData={getSurveyData}
                        isFilter={isFilter}
                        surveyLogo={surveyLogo}
                        GetParameterLabel={GetParameterLabel}
                        CountRestrict={CountRestrict}
                        getOrgDriverOverallData={getOrgDriverOverallData}
                      />


                      <Strength
                        getOrgIndexV2Data={getOrgIndexV2Data}
                        getSurveyData={getSurveyData}
                        getEngagementRWAData={getEngagementRWAData}
                        getQuestionFavorabilityData={
                          getQuestionFavorabilityData
                        }
                        getRespondantDetailsData={getRespondantDetailsData}
                        GetParameterLabel={GetParameterLabel}
                        surveyLogo={surveyLogo}
                        CountRestrict={CountRestrict}
                        setPDF={setPDF}
                      />

                      <Weakness
                        getOrgIndexV2Data={getOrgIndexV2Data}
                        getSurveyData={getSurveyData}
                        getEngagementRWAData={getEngagementRWAData}
                        getQuestionFavorabilityData={
                          getQuestionFavorability2Data
                        }
                        getRespondantDetailsData={getRespondantDetailsData}
                        GetParameterLabel={GetParameterLabel}
                        surveyLogo={surveyLogo}
                        CountRestrict={CountRestrict}
                        setPDF={setPDF}
                      />

                      {!isFilter || true ? (
                        <>
                          <EngagementDMG
                            getDemographicIndexMultiFilterData={
                              getDemographicIndexMultiFilterData
                            }
                            surveyLogo={surveyLogo}
                            GetParameterLabel={GetParameterLabel}
                            getEMPFilterLabel={getEMPFilterLabel}
                            CountRestrict={CountRestrict}
                          />
                          <OrgDMG
                            getSurveyData={getSurveyData}
                            getOrgIndexV2Data={getOrgIndexV2Data}
                            getDemographicOrgReportData={
                              getDemographicOrgReportData
                            }
                            GetParameterLabel={GetParameterLabel}
                            surveyLogo={surveyLogo}
                            getEMPFilterLabel={getEMPFilterLabel}
                            CountRestrict={CountRestrict}
                            setPDF={setPDF}
                            getOrgDriverOverallData={getOrgDriverOverallData}
                          />
                        </>
                      ) : null}

                      {OutPutData56 && OutPutData56.length > 0
                        ? OutPutData56.map((item) => (
                          <>
                            <ManagerOverall
                              SummaryName={item["SummaryName"]}
                              getIndexData={getIndexData}
                              indexTemplate={item["IndexNumber"]}
                              GetParameterLabel={GetParameterLabel}
                              CountRestrict={CountRestrict}
                              getQuestionFilterData={
                                item["getIndexQuestionData"]
                              }
                              surveyLogo={surveyLogo}
                              getOverallIndexData={getOverallIndexData}
                              setPDF={setPDF}
                              getQuesOverallData={
                                item["getIndexOverallQuesData"]
                              }
                            />

                            <Behaviors
                              SummaryName={item["SummaryName"]}
                              getQuestionData={
                                item["getIndexQuestionData"]
                              }
                              GetParameterLabel={GetParameterLabel}
                              CountRestrict={CountRestrict}
                              surveyLogo={surveyLogo}
                              indexTemplate={item["IndexNumber"]}
                              setPDF={setPDF}
                              getQuesOverallData={
                                item["getIndexOverallQuesData"]
                              }
                            />



                            <OrgCoreItems
                              SummaryName={item["SummaryName"]}
                              getIndexData={getIndexData}
                              indexTemplate={item["IndexNumber"]}
                              GetParameterLabel={GetParameterLabel}
                              CountRestrict={CountRestrict}
                              getQuestionFilterData={
                                item["getIndexQuestionData"]
                              }
                              surveyLogo={surveyLogo}
                              setPDF={setPDF}
                              getQuesOverallData={
                                item["getIndexOverallQuesData"]
                              }
                            />
                          </>
                        ))
                        : null}


                      <OrgCoreItems
                        SummaryName={"Organization Core"}
                        getIndexData={getIndexData}
                        indexTemplate={8}
                        GetParameterLabel={GetParameterLabel}
                        CountRestrict={CountRestrict}
                        getQuestionFilterData={getQuestionFilterData}
                        surveyLogo={surveyLogo}
                        setPDF={setPDF}
                        getQuesOverallData={getOrgQuestionOverall}
                      />
                    </>
                  ) : null}
                  <EngagementComparison
                    getDemographicIndexMultiFilterEngCompData={
                      getDemographicIndexMultiFilterEngCompData
                    }
                    surveyLogo={surveyLogo}
                    GetParameterLabel={GetParameterLabel}
                    getEMPFilterLabel={getEMPFilterLabel}
                    CountRestrict={CountRestrict}
                    getDemographicIndexEngCompOverallData={
                      getDemographicIndexEngCompOverallData
                    }
                  />
                </PDFExport>
              </div>
            </div>
          </div>
        )} */}


        <div className="w-full h-[calc(100vh-7rem)]">
          <header className="border-b md:px-6 px-4 2xl:px-16 py-2 flex items-center justify-between !sticky bg-white top-0 z-10">
            <div className="space-x-4 flex items-center ">
              <h1 className="lg:text-xl text-base font-medium text-[#212121]">Download Report</h1>
            </div> 
          </header>
          <section className="flex lg:flex-row flex-col lg:p-6 p-4 2xl:px-16 lg:divide-x lg:gap-0 gap-6">
            {/* filter section */}
            <div className="lg:w-7/12 w-full border bg-white rounded-md lg:p-6 p-4 mr-6  ">
              <h2 className="font-medium xl:text-lg text-base text-[#212121] pb-4 border-b">Filters</h2>
              {!isSelectTypeOverall ? (
                <div className="rounded-md">
                  <Filter
                    showFilter={showFilter}
                    showValue={showValue}
                    closeFilter={closeFilter}
                    EmpFilterData2={DMGLevelsList}
                    getIndexFilterData={getIndexByFilterDMGData}
                    handleMultifilterIndexScore={
                      handleMultifilterIndexScore2
                    }
                    getEMPFilterLabel={getEMPFilterLabel}
                  />
                </div>
              ) : (
                <div className="" />
              )}

              <div className="grid grid-cols-2 gap-4">
                <button onClick={() => loadIndexReport(2, !loadReport)} className="bg-[#2196F3] text-white p-2 rounded-[4px] w-full cursor-pointer">Apply</button>
                <button className=" bg-white p-2 px-4 rounded-md border border-[#212112]/10 text-[#212121]/70 group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center justify-center font-medium space-x-2 uppercase ">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-x"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg>

                  <span>
                    Clear All</span>
                </button>

                {/* {showExport ? (
                  <button onClick={() => loadIndexReport(2, !loadReport)} className=" bg-white p-2 px-4 rounded-md border border-[#2196f3] text-[#2196f3] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center justify-center font-medium space-x-2 ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="  transition-all duration-150   icon icon-tabler icon-tabler-report-analytics"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
                      <rect x={9} y={3} width={6} height={4} rx={2} />
                      <path d="M9 17v-5" />
                      <path d="M12 17v-1" />
                      <path d="M15 17v-3" />
                    </svg>

                    <span>
                      View Report
                    </span>
                  </button> 

                ) : (
                  <button className="flex items-center justify-center p-2 px-3 text-base border text-[#212121]/80 bg-white cursor-pointer rounded-[4px] w-full" onClick={() => { this.setState({ setPDF: true }, () => { this.handleExportWithComponent(); }); }}>
                    <FaRegFilePdf className="w-5 h-5 mr-2" />
                    <span>  Download PDF</span>
                  </button> 
                )} */}

                <div className="pt-4 col-span-2">
                  <button onClick={() => loadIndexReport(2, !loadReport)} className=" bg-white p-2 px-4 rounded-md border border-[#2196f3] text-[#2196f3] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center justify-center font-medium space-x-2  uppercase w-full">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="  transition-all duration-150   icon icon-tabler icon-tabler-report-analytics"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
                      <rect x={9} y={3} width={6} height={4} rx={2} />
                      <path d="M9 17v-5" />
                      <path d="M12 17v-1" />
                      <path d="M15 17v-3" />
                    </svg>

                    <span>
                      View Report
                    </span>
                  </button>
                </div>


                {/* <div className="relative w-full ">
                      <Select className="text-[#212121] text-sm"
                        options={colourOptions}
                        // defaultValue={colourOptions[0]}
                        placeholder="Select excel"
                      />
                    </div> */}

              </div>


            </div>
            <div className="lg:px-6  space-y-4 pb-6 lg:w-5/12 w-full">
              <h2 className="font-medium xl:text-lg text-base text-[#212121] pb-4 border-b">Download Excel</h2>
              <div className="space-y-2 ">
                <div className="bg-white border p-4 py-2 flex items-center justify-between rounded-[4px]">
                  <p className="font-medium text-sm  ">Question wise Demographic Score</p>
                  <button className="flex items-center space-x-2 border p-2 py-1 text-sm uppercase text-[#212121]/70 rounded-sm flex-shrink-0 ">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-reload"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M19.933 13.041a8 8 0 1 1 -9.925 -8.788c3.899 -1 7.935 1.007 9.425 4.747" /><path d="M20 4v5h-5" /></svg>
                    <span>Load excel</span>
                  </button>
                </div>
                <div className="bg-white border p-4 py-2 flex items-center justify-between rounded-[4px]">
                  <p className="font-medium text-sm  ">Question wise Demographic Score</p>
                  <button className="flex items-center space-x-2 border p-2 py-1 text-sm uppercase text-[#212121]/70 rounded-sm flex-shrink-0 ">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-reload"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M19.933 13.041a8 8 0 1 1 -9.925 -8.788c3.899 -1 7.935 1.007 9.425 4.747" /><path d="M20 4v5h-5" /></svg>
                    <span>Load excel</span>
                  </button>
                </div>
              </div>

            </div>

          </section>
          {/* Reports page reports */}
          <section className="md:w-full  2xl:px-0 px-0 bg-white space-y-4 overflow-y-auto overflow-x-hidden h-[calc(100vh-7rem)] relative hidden">
            <header className="border-b px-6 py-2 flex items-center justify-between !sticky bg-white top-0 z-10">
              <div className="space-x-4 flex items-center ">
                <button className="text-[#212121]/70 hover:text-[#2196f3] bg-gray-100 rounded-full cursor-pointer hover:bg-blue-100 trasition duration-150 p-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-arrow-left  "
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <line x1={5} y1={12} x2={19} y2={12} />
                    <line x1={5} y1={12} x2={11} y2={18} />
                    <line x1={5} y1={12} x2={11} y2={6} />
                  </svg>
                </button>


                <h1 className="text-xl font-medium text-[#212121]"> Report preview</h1>
              </div>
              <button className=" bg-white p-2 px-4 rounded-md border text-[#3D405B] group  hover:text-[#2196f3] transition-all duration-150  cursor-pointer text-sm flex items-center font-medium space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="group-hover:text-[#2196f3] transition-all duration-150  text-[#3D405B] icon icon-tabler icon-tabler-report-analytics"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
                  <rect x={9} y={3} width={6} height={4} rx={2} />
                  <path d="M9 17v-5" />
                  <path d="M12 17v-1" />
                  <path d="M15 17v-3" />
                </svg>
                <span> Download Report</span>
              </button>

            </header>

            {!loadReport ? (
              <div className="flex flex-col items-center justify-between space-y-10 2xl:w-10/12 lg:w-11/12 w-full mx-auto border">
                <div className="space-y-8 text-center flex flex-col items-center">
                  <div className="flex justify-center items-center pt-10 w-full">
                    <img src="/img/logo/happypluslogo.png" className="object-cover w-56" alt="default_survey_logo" />
                    <div />
                  </div>
                  <div className="space-y-4 text-center w-full">
                    <div className="text-4xl text-[#212121] font-normal text-centers">
                      <p className="pb-2">Employee Wellness Survey</p>
                      <p className=""> Report - 2024</p>
                    </div>
                    <p className="text-base font-medium text-[#212121]/80 italic">John Smith | 1 August, 2022</p>
                  </div>
                  <div className="flex justify-center">
                    <img src="/img/employee-wellness-report.webp" className="w-full h-full mx-auto" />
                  </div>
                  <div class="text-center space-y-2 pt-6 pb-20">
                    <p class="text-lg text-[#212121] font-medium">
                      {"Powered by HappyPlus © 2024"}
                    </p>
                    <p class="text-sm text-[#212121]/80">
                      {"This report is confidential and should not be distributed without permission"}
                    </p>
                  </div>
                </div>
              </div>

            ) : (
              <div className="bg-white rounded-md 2xl:w-8/12 xl:w-11/12  w-full mx-auto border">
                {/* full reports */}
                <div
                  className=" "
                >
                  <PDFExport ref={this.pdfExportComponent}
                    pageTemplate={(pageObj) => <PageTemplate pageNum={pageObj.pageNum} surveyLogo={surveyLogo} />}
                    scale={0.7}
                    forcePageBreak=".page-break"
                    paperSize="A4"
                    margin={0}
                    author="Happyplus Team"
                    landscape={false}
                    fileName={`${ReportFilterObj && ReportFilterObj["value"]
                      ? (getSurveyData && getSurveyData.name
                        ? getSurveyData.name +
                        (getSurveyData && getSurveyData.createdAt
                          ? moment
                            .unix(getSurveyData.createdAt / 1000)
                            .format(" - YYYY")
                          : "")
                        : "Dashboard Report") + ReportFilterObj["value"]
                      : getSurveyData && getSurveyData.name
                        ? getSurveyData.name +
                        (getSurveyData && getSurveyData.createdAt
                          ? moment
                            .unix(getSurveyData.createdAt / 1000)
                            .format(" - YYYY")
                          : "")
                        : "Dashboard Report"
                      }`}

                  >
                    <ReportIntro
                      getSurveyData={getSurveyData}
                      surveyLogo={surveyLogo}
                      ReportFilterObj={ReportFilterObj}
                      FilterValues={FilterValues}
                      getEMPFilterLabel={getEMPFilterLabel}
                    />

                    <h3 className="page-break"></h3>

                    <FilterSummary
                      getEMPFilterLabel={getEMPFilterLabel}
                      FilterValues={FilterValues}
                    />
                    <h3 className="page-break"></h3>

                    {allowAPI ? (
                      <>
                        {OutPutData1234 && OutPutData1234.length > 0
                          ? OutPutData1234.map((item, index) => (
                            <>
                              {index !== 0 ? (
                                <h3 className="page-break"></h3>
                              ) : null}

                              <Summary
                                SummaryName={item["SummaryName"]}
                                getIndexV2Data={item["getIndexV2Data"]}
                                getIndexData={getIndexData}
                                getRespondantDetailsData={
                                  getRespondantDetailsData
                                }
                                getAllBenchMarkData={getAllBenchMarkData}
                                getEngagementIndexDriverData={
                                  item["getIndexDriverData"]
                                }
                                indexTemplate={item["IndexNumber"]}
                                isFilter={isFilter}
                                surveyLogo={surveyLogo}
                                GetParameterLabel={GetParameterLabel}
                                getOverallIndexData={getOverallIndexData}
                                getOverallDistribution={
                                  item["getOverallDistribution"]
                                }
                                getOverallDriver={item["getOverallDriver"]}
                              />


                              <SummaryTwo
                                SummaryName={item["SummaryName"]}
                                getIndexV2Data={item["getIndexV2Data"]}
                                getIndexData={getIndexData}
                                getRespondantDetailsData={
                                  getRespondantDetailsData
                                }
                                getAllBenchMarkData={getAllBenchMarkData}
                                getEngagementIndexDriverData={
                                  item["getIndexDriverData"]
                                }
                                indexTemplate={item["IndexNumber"]}
                                isFilter={isFilter}
                                surveyLogo={surveyLogo}
                                GetParameterLabel={GetParameterLabel}
                                getOverallIndexData={getOverallIndexData}
                                getOverallDistribution={
                                  item["getOverallDistribution"]
                                }
                                getOverallDriver={item["getOverallDriver"]}
                              />

                              {item["IndexNumber"] !== 4 ? (
                                <>
                                  <Behaviors
                                    SummaryName={item["SummaryName"]}
                                    getQuestionData={
                                      item["getIndexQuestionData"]
                                    }
                                    GetParameterLabel={GetParameterLabel}
                                    CountRestrict={CountRestrict}
                                    surveyLogo={surveyLogo}
                                    indexTemplate={item["IndexNumber"]}
                                    setPDF={setPDF}
                                    getQuesOverallData={
                                      item["getIndexOverallQuesData"]
                                    }
                                  />

                                  <OrgCoreItems
                                    SummaryName={item["SummaryName"]}
                                    getIndexData={getIndexData}
                                    indexTemplate={item["IndexNumber"]}
                                    GetParameterLabel={GetParameterLabel}
                                    CountRestrict={CountRestrict}
                                    getQuestionFilterData={
                                      item["getIndexQuestionData"]
                                    }
                                    surveyLogo={surveyLogo}
                                    setPDF={setPDF}
                                    getQuesOverallData={
                                      item["getIndexOverallQuesData"]
                                    }
                                  />
                                </>
                              ) : null}
                            </>
                          ))
                          : null}

                        {item &&
                          item.answerList &&
                          item.answerList[0] &&
                          item.answerList[0].template &&
                          item.answerList[0].template === 6 ? (
                          <>
                            <h3 className="page-break"></h3>

                            <div className="pt-4">
                              <Header surveyLogo={surveyLogo} />

                              <div className>
                                <div className="w-full px-8 py-6 capitalize ">
                                  <div className="text-indigo-700 opacity-90"></div>
                                  <h1 className="pb-2 text-xl font-semibold text-gray-800">
                                    NPS Summary
                                  </h1>
                                </div>
                              </div>
                            </div>

                            {item &&
                              item.answerList &&
                              item.answerList[0] &&
                              item.answerList[0].template &&
                              item.answerList[0].template === 6 ? (
                              <NPSChart
                                AnswerObj={AnswerObj}
                                name={name}
                                templateId={6}
                                questionName={item.name}
                                stage={1}
                                index={1}
                              />
                            ) : null}

                            <h3 className="page-break"></h3>

                            <NPSDmg
                              getDemographicIndexMultiFilterEngCompData={
                                getDemographicIndexMultiFilterEngCompData
                              }
                              surveyLogo={surveyLogo}
                              GetParameterLabel={GetParameterLabel}
                              getEMPFilterLabel={getEMPFilterLabel}
                              CountRestrict={CountRestrict}
                              getQuestionOthersMultiResultFilterData={
                                getQuestionOthersMultiResultFilterData
                              }
                              getQuestion2OthersResultFilterData={
                                getQuestion2OthersResultFilterData
                              }
                            />
                          </>
                        ) : null}

                        <OrgCoreDriver
                          getOrgIndexV2Data={getOrgIndexV2Data}
                          getSurveyData={getSurveyData}
                          isFilter={isFilter}
                          surveyLogo={surveyLogo}
                          GetParameterLabel={GetParameterLabel}
                          CountRestrict={CountRestrict}
                          getOrgDriverOverallData={getOrgDriverOverallData}
                        />


                        <Strength
                          getOrgIndexV2Data={getOrgIndexV2Data}
                          getSurveyData={getSurveyData}
                          getEngagementRWAData={getEngagementRWAData}
                          getQuestionFavorabilityData={
                            getQuestionFavorabilityData
                          }
                          getRespondantDetailsData={getRespondantDetailsData}
                          GetParameterLabel={GetParameterLabel}
                          surveyLogo={surveyLogo}
                          CountRestrict={CountRestrict}
                          setPDF={setPDF}
                        />

                        <Weakness
                          getOrgIndexV2Data={getOrgIndexV2Data}
                          getSurveyData={getSurveyData}
                          getEngagementRWAData={getEngagementRWAData}
                          getQuestionFavorabilityData={
                            getQuestionFavorability2Data
                          }
                          getRespondantDetailsData={getRespondantDetailsData}
                          GetParameterLabel={GetParameterLabel}
                          surveyLogo={surveyLogo}
                          CountRestrict={CountRestrict}
                          setPDF={setPDF}
                        />

                        {!isFilter || true ? (
                          <>
                            <EngagementDMG
                              getDemographicIndexMultiFilterData={
                                getDemographicIndexMultiFilterData
                              }
                              surveyLogo={surveyLogo}
                              GetParameterLabel={GetParameterLabel}
                              getEMPFilterLabel={getEMPFilterLabel}
                              CountRestrict={CountRestrict}
                            />
                            <OrgDMG
                              getSurveyData={getSurveyData}
                              getOrgIndexV2Data={getOrgIndexV2Data}
                              getDemographicOrgReportData={
                                getDemographicOrgReportData
                              }
                              GetParameterLabel={GetParameterLabel}
                              surveyLogo={surveyLogo}
                              getEMPFilterLabel={getEMPFilterLabel}
                              CountRestrict={CountRestrict}
                              setPDF={setPDF}
                              getOrgDriverOverallData={getOrgDriverOverallData}
                            />
                          </>
                        ) : null}

                        {OutPutData56 && OutPutData56.length > 0
                          ? OutPutData56.map((item) => (
                            <>
                              <ManagerOverall
                                SummaryName={item["SummaryName"]}
                                getIndexData={getIndexData}
                                indexTemplate={item["IndexNumber"]}
                                GetParameterLabel={GetParameterLabel}
                                CountRestrict={CountRestrict}
                                getQuestionFilterData={
                                  item["getIndexQuestionData"]
                                }
                                surveyLogo={surveyLogo}
                                getOverallIndexData={getOverallIndexData}
                                setPDF={setPDF}
                                getQuesOverallData={
                                  item["getIndexOverallQuesData"]
                                }
                              />

                              <Behaviors
                                SummaryName={item["SummaryName"]}
                                getQuestionData={
                                  item["getIndexQuestionData"]
                                }
                                GetParameterLabel={GetParameterLabel}
                                CountRestrict={CountRestrict}
                                surveyLogo={surveyLogo}
                                indexTemplate={item["IndexNumber"]}
                                setPDF={setPDF}
                                getQuesOverallData={
                                  item["getIndexOverallQuesData"]
                                }
                              />



                              <OrgCoreItems
                                SummaryName={item["SummaryName"]}
                                getIndexData={getIndexData}
                                indexTemplate={item["IndexNumber"]}
                                GetParameterLabel={GetParameterLabel}
                                CountRestrict={CountRestrict}
                                getQuestionFilterData={
                                  item["getIndexQuestionData"]
                                }
                                surveyLogo={surveyLogo}
                                setPDF={setPDF}
                                getQuesOverallData={
                                  item["getIndexOverallQuesData"]
                                }
                              />
                            </>
                          ))
                          : null}


                        <OrgCoreItems
                          SummaryName={"Organization Core"}
                          getIndexData={getIndexData}
                          indexTemplate={8}
                          GetParameterLabel={GetParameterLabel}
                          CountRestrict={CountRestrict}
                          getQuestionFilterData={getQuestionFilterData}
                          surveyLogo={surveyLogo}
                          setPDF={setPDF}
                          getQuesOverallData={getOrgQuestionOverall}
                        />
                      </>
                    ) : null}
                    <EngagementComparison
                      getDemographicIndexMultiFilterEngCompData={
                        getDemographicIndexMultiFilterEngCompData
                      }
                      surveyLogo={surveyLogo}
                      GetParameterLabel={GetParameterLabel}
                      getEMPFilterLabel={getEMPFilterLabel}
                      CountRestrict={CountRestrict}
                      getDemographicIndexEngCompOverallData={
                        getDemographicIndexEngCompOverallData
                      }
                    />
                  </PDFExport>
                </div>
              </div>
            )}

          </section>
        </div>




      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(OverallReport);
