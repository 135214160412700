import React, { Component } from "react";
import moment from "moment";
// import Header from "../Components/Header";

export default class Engagement_Index_Report extends Component {
  render() {
    let {
      getEMPFilterLabel,
      FilterValues,
      getSurveyData,
      surveyLogo,
      ReportFilterObj,
    } = this.props;

    console.log("getSurveyData--------->", getSurveyData);

    return (
      <main>
        <section className="flex flex-col items-center justify-between space-y-10 ">
          <div className="flex flex-col items-center space-y-8 text-center">
           <div className="flex justify-center pt-6">
           <img
              src="/img/logo/happypluslogo.png"
              className="w-auto "
              alt="default_survey_logo"
            />
           </div>
            <div className="py-2 space-y-4 text-center">
              <h1 className="text-3xl font-semibold text-slate-800 ">
                {getSurveyData && getSurveyData.name
                  ? getSurveyData.name +
                    (getSurveyData && getSurveyData.createdAt
                      ? moment
                          .unix(getSurveyData.createdAt / 1000)
                          .format(" - YYYY")
                      : "")
                  : ""}
              </h1>
              <h1 className="text-xl font-medium capitalize text-sky-500 ">
                Overall | {moment().format("DD/MM/YYYY")}{" "}
              </h1>
            </div>
            <div className="flex justify-center pt-6">
              <img src="/img/managerreport/talentplus_assessment_report.png" className="w-full mx-auto" />
            </div>
          </div>
          <div className="text-center">
            {/* <p className="text-base text-[#3D405B] font-medium">
            HappyPlus Online Assessments © 2022
            </p> */}
            <p className="text-sm text-[#3D405B]/70">
              This report is confidential and should not be distributed without permission
            </p>
          </div>
        </section>
      </main>
    );
  }
}
