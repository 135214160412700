import React, { Component } from "react";
import Header from "../Components/Header";

import BarIndexDistribution from "./chart/BarDistribution/BarIndexDistribution";
import HorizontalBarDistChart from "./chart/HorizontalBarChart/HorizontalBarDistChart";


// import HappinessHorizontalBarChart from "./chart/HorizontalBarChart/HappinessHorizontalBarChart";
// import EngagementHorizontalBarChart from "./chart/HorizontalBarChart/EngagementHorizontalBarChart";
// import StressHorizontalBarChart from "./chart/HorizontalBarChart/StressHorizontalBarChart";

// import CircleChart from "./chart/CircleChart";
// import ScoreCircleChart from "./chart/ScoreCircleChart";



// import HorizontalBarChart from "./chart/HorizontalBarChart/HorizontalBarChart";


import BarChart from "./chart/BarChart";
import GaugeScore from "./chart/GaugeScore";


export default class Engagement_Index_Report extends Component {
  render() {
    let {
      getOverallDistribution,
      getOverallDriver,
      getOverallIndexData,
      surveyLogo,
      isFilter,
      SummaryName,
      indexTemplate,
      getIndexV2Data,
      getIndexDistributionData,
      getIndexData,
      getAllBenchMarkData,
      getRespondantDetailsData,
      getEngagementIndexDriverData,
      GetParameterLabel,
    } = this.props;
    ////console.log("getRespondantDetailsData-->",getRespondantDetailsData);

    // ActivelyDisengaged: 4.436557231588288
    // Engagement: 78.88198757763976
    // HighlyEngaged: 27.24046140195209
    // ModeratelyEngaged: 51.641526175687666
    // Passive: 16.68145519077196

    let indexValue =
      getIndexData &&
        getIndexData[indexTemplate] &&
        parseFloat(getIndexData[indexTemplate].toFixed(2))
        ? parseFloat(getIndexData[indexTemplate].toFixed(2))
        : 0;

    let indexOverallValue =
      getOverallIndexData &&
        getOverallIndexData[indexTemplate] &&
        parseFloat(getOverallIndexData[indexTemplate].toFixed(2))
        ? parseFloat(getOverallIndexData[indexTemplate].toFixed(2))
        : 0;

    let ParticipationObj = {
      total: 0,
      completed: 0,
      mailSent: 0,
      clicked: 0,
      participated: 0,
      performance: 0,
      perfPlusSign: true,
    };

    if (getRespondantDetailsData && getRespondantDetailsData[0]) {
      let apiData = getRespondantDetailsData[0];
      ParticipationObj["total"] = apiData["total"] ? apiData["total"] : 0;
      ParticipationObj["completed"] = apiData["completed"]
        ? apiData["completed"]
        : 0;
      ParticipationObj["mailSent"] = apiData["mailSent"]
        ? apiData["mailSent"]
        : 0;
      ParticipationObj["clicked"] = apiData["clicked"] ? apiData["clicked"] : 0;
      ParticipationObj["participated"] =
        apiData["completed"] && apiData["total"]
          ? ((apiData["completed"] * 100) / apiData["total"]).toFixed(2)
          : 0;
    }

    let indexType = 2;
    let Benchmark = 0;
    if (getAllBenchMarkData) {
      Benchmark =
        (typeof getAllBenchMarkData[indexType] !== "string" ||
          getAllBenchMarkData[indexType] instanceof Number) &&
          getAllBenchMarkData &&
          getAllBenchMarkData[indexType]
          ? getAllBenchMarkData[indexType].toFixed(2)
          : "";
    }

    let isScoreFormat = false;
    if (SummaryName === "Happiness" || SummaryName === "Stress") {
      isScoreFormat = true;
    }

    return (
      <div className="relative py-6 px-8 page-break">
        {/* report header */}
        <div class="flex items-center justify-between border-b py-4 text-[#212121]/80 ">
          <p class="font-medium italic text-base ">Employee Wellness Survey</p>
          <p class="font-medium italic text-base">15 March, 2024</p>
        </div>
        <div className="">
          <Header surveyLogo={surveyLogo} />
          <div className>
            <div className="capitalize w-full   py-6 ">
              <div className="text-indigo-700 opacity-90"></div>
              <h1 className="text-2xl text-[#212121] font-medium  capitalize">
                {SummaryName + " Summary"}
              </h1>
            </div>
          </div>
        </div>
        <main className="w-full  grid grid-cols-2 gap-6 mx-auto pb-4">
          {/* <div className=" space-y-4 ">
            <h1 className="text-[#212121] text-xl font-medium capitalize">
              {SummaryName + " Score"}
            </h1>
            <div className="flex justify-center">
              <GaugeScore
                score={indexValue.toString()}
                benchmark={40}
              />
            </div>


            {indexTemplate === 1 && (<>
              <div className="grid grid-cols-2 justify-center gap-2 text-sm text-[#212121]/70 font-medium">

                <div className="flex items-center "><div style={{ background: "#e53935" }} className="p-1 rounded-sm" /><p className="ml-2">Unhappy (0-48)</p></div>
                <div className="flex items-center "><div style={{ background: "#ffc107" }} className="p-1 rounded-sm" /><p className="ml-2">Moderate (49-60)</p></div>
                <div className="flex items-center "><div style={{ background: "#a2e37d" }} className="p-1 rounded-sm" /><p className="ml-2">Happier (61-66)</p></div>
                <div className="flex items-center "><div style={{ background: "#00cc66" }} className="p-1 rounded-sm" /><p className="ml-2">Happiest (67-72)</p></div>

              </div>
            </>)}

            {indexTemplate === 2 && (<>
              <div className="grid grid-cols-2 justify-center gap-2 text-sm text-[#212121]/70 font-medium">

                <div className="flex items-center "><div style={{ background: "#d12e2e" }} className="p-1 rounded-sm" /><p className="ml-2">Actively Disengaged</p></div>
                <div className="flex items-center "><div style={{ background: "#f4ae34" }} className="p-1 rounded-sm" /><p className="ml-2">Passive</p></div>
                <div className="flex items-center "><div style={{ background: "#a2e37d" }} className="p-1 rounded-sm" /><p className="ml-2">Moderately Engaged</p></div>
                <div className="flex items-center "><div style={{ background: "#00cc66" }} className="p-1 rounded-sm" /><p className="ml-2">Highly Engaged</p></div>

              </div>
            </>)}

            {indexTemplate === 4 && (<>
              <div className="grid grid-cols-2 justify-center gap-2 text-sm text-[#212121]/70 font-medium">

                <div className="flex items-center "><div className="p-1 bg-pink-400 rounded-sm" /><p className="ml-2">{"Well Managed Stess (=6)"}</p></div>
                <div className="flex items-center "><div className="p-1 bg-green-400 rounded-sm" /><p className="ml-2">{"Moderately Well (4.5-6)"}</p></div>
                <div className="flex items-center "><div className="p-1 bg-yellow-400 rounded-sm" /><p className="ml-2">{"Often Stressed (3-4.5)"}</p></div>
                <div className="flex items-center "><div className="p-1 bg-red-500 rounded-sm" /><p className="ml-2">{"Need Immediate Attention (<3)"}</p></div>

              </div>
            </>)}


          </div> */}


          {/* <div className="w-full space-y-6">

            <div className="w-full space-y-4">
              <div className="space-y-2 ">
                <h1 className="text-[#212121] text-xl font-medium capitalize">
                  Response Rate
                </h1>

                <div className="flex flex-col  ">
                  <div className="w-full flex justify-start">
                    <BarChart ParticipationObj={ParticipationObj} />
                  </div>
                </div>
                <div className="space-y-4 w-full">
                  <p className="text-[#212121] font-medium text-sm">
                    <b className="font-semibold ">{ParticipationObj["participated"] + "%"} - </b> <b>{ParticipationObj["total"]}</b> Sent of  <b> {ParticipationObj["completed"]}</b> Response have completed the survey</p>
                </div>

              </div>
            </div>


            <table className="w-full bg-[#f5f5f5]">
              <thead>
                <tr className="border-b-2 border-white  divide-x-2 divide-white whitespace-nowrap text-sm">
                  <th className="w-[30%] p-2 px-2 text-[#212121] font-semibold text-right">Comparison</th>
                  <th className="w-[40%] p-2 px-2 text-[#212121] font-semibold text-left">Score</th>
                  <th className=" w-[30%] p-2 px-2 text-[#212121] font-semibold text-center">Difference</th>
                </tr>
              </thead>
              <tbody className="divide-y-2  divide-white">
                <tr className=" divide-x-2 divide-white text-sm">
                  <td className="p-2 text-[#212121] font-medium text-right ">Score</td>
                  <td className="p-2 text-[#212121] font-medium ">
                    <div className="h-6 w-full bg-[#CFD8DC]"><div className="w-full flex items-center  h-6 bg-[#1E88E5]"><span className="text-xs font-medium text-white px-2"> {indexValue.toString() + (isScoreFormat ? "" : "%")}{" "}</span></div></div>
                  </td>
                  <td rowSpan={2} className="  px-2 text-sm font-medium text-center text-[#4CAF50]">+12</td>
                </tr>
                <tr className=" divide-x-2 divide-white text-sm">
                  <td className="p-2 text-[#212121] font-medium text-right">
                    Overall
                  </td>
                  <td className="p-2 text-[#212121] font-medium">  <div className="h-6 w-full bg-[#CFD8DC]"><div className="w-full flex items-center  h-6 bg-[#9C27B0]"><span className="text-xs font-medium text-white px-2"> {indexValue.toString() + (isScoreFormat ? "" : "%")}{" "}</span></div></div></td>
                </tr>
              </tbody>

            </table>

          </div> */}


          {/* <div className=" col-span-2 space-y-4">
            <h1 className="text-[#212121] text-xl font-medium capitalize">
              {SummaryName + " Distribution"}
            </h1>

            {indexTemplate === 1 ? (
              <BarIndexDistribution
                DistributionData={getIndexV2Data}
                getOverallDistribution={getOverallDistribution}

                List={['Unhappy', 'ModeratelyHappy', 'Happy', 'Happiest']}
                labelObj={{
                  'Unhappy': `Unhappy`,
                  'ModeratelyHappy': `Moderate`,
                  'Happy': `Happy`,
                  'Happiest': `Happiest`
                }}
                colorObj={{
                  'Unhappy': '#e53935',
                  'ModeratelyHappy': '#ffc107',
                  'Happy': '#a2e37d',
                  'Happiest': '#00cc66'
                }}
              />
            ) : null}
            {indexTemplate === 2 ? (
              <BarIndexDistribution
                DistributionData={getIndexV2Data}
                getOverallDistribution={getOverallDistribution}

                List={['ActivelyDisengaged', 'Passive', 'ModeratelyEngaged', 'HighlyEngaged']}
                labelObj={{
                  'ActivelyDisengaged': `Actively Disengaged`,
                  'Passive': `Passive`,
                  'ModeratelyEngaged': ` Moderately Engaged`,
                  'HighlyEngaged': `Highly Engaged`
                }}
                colorObj={{
                  'ActivelyDisengaged': '#d12e2e',
                  'Passive': '#f4ae34',
                  'ModeratelyEngaged': '#a2e37d',
                  'HighlyEngaged': '#00cc66'
                }}
              />
            ) : null}
            {indexTemplate === 4 ? (
              <BarIndexDistribution
                DistributionData={getIndexV2Data}
                getOverallDistribution={getOverallDistribution}

                List={['NeedImmediateattention', 'OftenStressed', 'ModeratelyWell', 'WellManagedStess']}
                labelObj={{
                  'NeedImmediateattention': `Need
                    Immediate
                    attention(<3)`,

                  'OftenStressed': `Often
                    Stressed 
                    (<4.5 and >=3)`,

                  'ModeratelyWell': `Moderately
                    Well
                    (<6 and >=4.5)`,

                  'WellManagedStess': `Well
                    Managed
                    Stess(=6)`
                }}
                colorObj={{
                  'NeedImmediateattention': '#e53935',
                  'OftenStressed': '#ffc107',
                  'ModeratelyWell': '#00cd7e',
                  'WellManagedStess': '#ff4081'
                }}
              />
            ) : null}

          </div> */}

          {indexTemplate !== 4 ? (
            <div className=" col-span-2  space-y-4">
              <h1 className="text-[#212121] font-medium text-xl capitalize">
                {SummaryName + " Driver"}
              </h1>


              {indexTemplate === 1 ? (
                <HorizontalBarDistChart
                  getEngagementIndexDriverData={getEngagementIndexDriverData}
                  isFilter={isFilter}
                  GetParameterLabel={GetParameterLabel}
                  getOverallDriver={getOverallDriver}

                />
              ) : null}

              {indexTemplate === 2 ? (
                <HorizontalBarDistChart

                  getEngagementIndexDriverData={getEngagementIndexDriverData}
                  isFilter={isFilter}
                  GetParameterLabel={GetParameterLabel}
                  getOverallDriver={getOverallDriver}
                />
              ) : null}

              {indexTemplate === 4 ? (
                <HorizontalBarDistChart
                  getEngagementIndexDriverData={getEngagementIndexDriverData}
                  isFilter={isFilter}
                  GetParameterLabel={GetParameterLabel}
                  getOverallDriver={getOverallDriver}
                />
              ) : null}


            </div>
          ) : null}
        </main>


      </div>
    );
  }
}