import React from 'react';
import ReactSpeedometer from "react-d3-speedometer";


export default function GaugeScore(props) {

  let { score } = props;

  return (
    <>
      <div className="flex justify-center items-center !w-full" style={{ height: "100px" }}>
        <ReactSpeedometer className="meter"
          width={280}
          height={165}
          value={score}
          minValue={0}
          maxValue={100}
          needleColor="#4db8ff"
          segments={1}
          segmentColors={[
            "#33cc33",
            "#e3e6e3"
          ]}
          ringWidth={30}
          customSegmentStops={[0, Number(score), 100]}
          // fluidWidth={true}
          needleTransitionDuration={3333}
          needleTransition="easeElastic"
        />

      </div>

    </>
  );
}
