import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class CircleChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  
  render() {
    let { value } = this.props;


    let responseRate = value? value:0;


    let DataNew = [
      { value: (100-parseFloat(responseRate)).toFixed(2), 
        itemStyle:{
          color:"#CFD8DC"
        },
        name: '', },
      { value: parseFloat(responseRate), 
        itemStyle:{
          color:"#3399ff"
        },
        name: 
           `${parseFloat(responseRate)}%` }
    ]



    return (
      <>
          <div className='flex justify-center items-center'>
              {true?
              <>
                            <ReactEcharts
                              style={{height:'100px', width:'100px'
                            }}
                            option={{
                              // tooltip: {
                              //   trigger: 'item'
                              // },
                              series: [
                                {
                                  name: 'Access From',
                                  type: 'pie',
                                  radius: ['65%', '75%'],
                                  label: {
                                    show: true,
                                    position: 'center',
                                    fontSize: '12',
                                    fontWeight: 'bold'
                                  },
                                  labelLine: {
                                    show: false
                                  },
                                  data: DataNew
                                }
                              ]
                            }}
                          />
                </>
              :null}
          </div>
         


      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(CircleChart);
