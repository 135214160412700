import React, { Component } from "react";
// import EmpChart from './Chart/EmpChart';
// import BarDistribution from './Chart/BarDistribution';
import QuestionScore from "./chart/QuestionScore";
import QuestionStack from "./chart/QuestionStack";

import CircleChart from "./chart/CircleChart";

import Header from "../Components/Header";
import HeaderSub from "../Components/HeaderSub";
import BarDistribution from "./chart/BarDistribution";

class OrgCoreItems extends Component {
  render() {
    let {
      getQuesOverallData,
      setPDF,
      SummaryName,
      getQuestionFilterData,
      getIndexData,
      indexTemplate,
      GetParameterLabel,
      CountRestrict,
      surveyLogo,
    } = this.props;

    let QuestionData = getQuestionFilterData;

    //console.log('getQuestionFilterData--->', getQuestionFilterData)

    let paraList = [];

    let count = 0;
    let rowSpan = 0;
    let NewParaList = [];

    if (QuestionData && QuestionData.length > 0) {
      QuestionData.forEach((ques) => {
        if (
          paraList.findIndex((prev) => prev === ques.parameterDimensionName) ===
          -1
        ) {
          paraList.push(ques.parameterDimensionName);
          count += 1;
        } else {
        }
      });

      let obj = {};
      paraList.forEach((para) => {
        obj[para] = 0;
        QuestionData.forEach((ques) => {
          if (para === ques.parameterDimensionName) {
            obj[para] += 1;
          }
        });
      });

      NewParaList = Object.keys(obj).map((key) => [key, obj[key]]);
      //   console.log('data NewParaList: ',NewParaList)

      rowSpan = QuestionData.length / count;

      // console.log('Your Engagement paraList: ',NewParaList,count,rowSpan,QuestionData.length)
    }
    // console.log('ORG ==== NewParaList ===== > : ',NewParaList)
    // console.log('ORG ==== QuestionData ===== > : ',QuestionData)

    let NewQuesList = [];
    if (QuestionData && QuestionData.length > 0) {
      QuestionData.forEach((ques) => {
        let total =
          ques["1"] + ques["2"] + ques["3"] + ques["4"] + ques["5"] + ques["6"];

        let score = parseFloat(
          (((ques["5"] + ques["6"]) * 100) / total).toFixed(2)
        );

        NewQuesList.push({
          ...ques,
          score: CountRestrict(ques.count) ? "NaN" : score,
        });
      });
    }

    // NewQuesList.sort((a, b) => b.score - a.score);
    NewQuesList.sort(
      (a, b) =>
        (a && a.parameterDimensionName && a.parameterDimensionName.toString()
          ? a.parameterDimensionName.toString().charCodeAt(0)
          : "") -
        (b && b.parameterDimensionName && b.parameterDimensionName.toString()
          ? b.parameterDimensionName.toString().charCodeAt(0)
          : "")
    );

    // console.log('ORG ==== NewQuesList ===== > : ',NewQuesList)

    function GetScore(question) {
      let Score = CountRestrict(question.count) ? "NaN" : question.score;

      return Score;
    }

    // NewQuesList

    // let indexData = {
    //   "a":0,
    //   "b":0
    // }
    let ColorList = [
      "#757575",
      "#ff6600",
      "#ff944d",
      "#ffb380",
      "#9fbfdf",
      "#6699cc",
      "#336699",
    ];

    let colorTheme = 1;

    if (colorTheme === 3) {
      ColorList = [
        "#757575",
        "#E57373",
        "#D32F2F",
        "#81C784",
        "#4CAF50",
        "#388E3C",
      ];
    } else if (colorTheme === 2) {
      ColorList = [
        "#757575",
        "#ff6600",
        "#ff944d",
        "#ffb380",
        "#9fbfdf",
        "#6699cc",
        "#336699",
      ];
    } else if (colorTheme === 1) {
      ColorList = [
        "#757575",
        "#e60000",
        "#ff3333",
        "#ff8080",
        "#99e699",
        "#70db70",
        "#33cc33",
      ];
    }

    function calCScore(question, type) {
      let obj = {};
      let total = 0;
      if (question) {
        total =
          question["1"] +
          question["2"] +
          question["3"] +
          question["4"] +
          question["5"] +
          question["6"];

        let score1 = ((parseFloat(question[type]) * 100) / total).toFixed(0);
        let score = CountRestrict(question.count) ? "NaN" : score1;
        let temValue = parseFloat(score);
        obj = {
          value: score,
          length: temValue > 0 ? (temValue < 3 ? "3" : temValue) : 0,
          color: ColorList[parseInt(type)],
        };
      }

      return obj;
    }

    function optionLabel(number) {
      let obj = {
        1: "Strongly Disagree",
        2: "Disagree",
        3: "Slighty Disagree",
        4: "Slighty Agree",
        5: "Agree",
        6: "Strongly Agree",
      };

      return obj && obj[number] ? obj[number] : number;
    }

    function calcFavourability(quesZ) {
      let total =
        quesZ["1"] +
        quesZ["2"] +
        quesZ["3"] +
        quesZ["4"] +
        quesZ["5"] +
        quesZ["6"];
      let scoreX = parseFloat(
        (((quesZ["5"] + quesZ["6"]) * 100) / total).toFixed(2)
      );
      return scoreX;
    }

    function calcOverall(queItem) {
      let overallX = 0;
      if (getQuesOverallData && getQuesOverallData.length > 0) {
        let getIndex = getQuesOverallData.findIndex(
          (prev) => prev.name === queItem.name
        );
        let overallQue = getQuesOverallData[getIndex];
        overallX = calcFavourability(overallQue);
      }
      return overallX;
    }

    let optionLists = [
      {
        name: "Strongly Disagree",
        value: "1",
      },
      {
        name: "Disagree",
        value: "2",
      },
      {
        name: "Slightly Disagree",
        value: "3",
      },
      {
        name: "Slightly Agree",
        value: "4",
      },
      {
        name: "Agree",
        value: "5",
      },
      {
        name: "Strongly Agree",
        value: "6",
      },
    ];



    function chunkList(array) {
      const chunkSize = 6;
      let list = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        const chunk = array.slice(i, i + chunkSize);
        list.push(chunk);
      }
      //console.log("list--->", list);
      return list;
    }




    return (

      <>
        {NewQuesList && NewQuesList.length > 0 ?
          chunkList(NewQuesList).map((NewQues, indexX) =>
            <div className="px-8 py-6 page-break">
              {/* report header */}
              <div class="flex items-center justify-between border-b py-4 text-[#212121]/80 ">
                <p class="font-medium italic text-base ">Employee Wellness Survey</p>
                <p class="font-medium italic text-base">15 March, 2024</p>
              </div>
              <main className="w-full ">

                {setPDF ? (
                  <>
                    <table
                      className="w-full"
                      id={"OrgCoreItems" + indexTemplate.toString()}
                    >
                      <tbody className="divide-y-2 divide-white ">
                        {NewQues && NewQues.length > 0
                          ? NewQues.map((question, index) => (
                            <>
                              {index % 6 === 0 ? (
                                <>


                                  <tr className="my-2 page-break">
                                    <td colSpan={5} className="text-left ">


                                      <Header surveyLogo={surveyLogo} />


                                      <div className="  -mx-8">
                                        <div className>
                                          <div className="w-full px-8 py-6 capitalize ">
                                            <h1 className="text-2xl text-[#212112] font-medium   capitalize">
                                              {SummaryName +
                                                " Item level Detail Analysis" + (indexX > 0 ? " (Continue)" : "")}{" "}
                                            </h1>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>

                                  <tr className="my-2">
                                    <td
                                      colSpan={5}
                                      className="w-full whitespace-nowrap"
                                    >
                                      <div className="flex justify-start w-full pb-4 space-x-6">
                                        <div className="flex items-center text-xs capitalize text-[#212121] space-x-1">
                                          <span
                                            style={{ background: ColorList[3] }}
                                            className="w-4 h-4 bg-indigo-700"
                                          />
                                          <span> Slightly Disagree</span>
                                        </div>
                                        <div className="flex items-center text-xs capitalize text-[#212121] space-x-1">
                                          <span
                                            style={{ background: ColorList[2] }}
                                            className="w-4 h-4 bg-indigo-700"
                                          />
                                          <span>Disagree</span>
                                        </div>
                                        <div className="flex items-center text-xs capitalize text-[#212121] space-x-1">
                                          <span
                                            style={{ background: ColorList[1] }}
                                            className="w-4 h-4 bg-indigo-700"
                                          />
                                          <span>Strongly Disagree</span>
                                        </div>
                                        <div className="flex items-center text-xs capitalize text-[#212121] space-x-1">
                                          <span
                                            style={{ background: ColorList[4] }}
                                            className="w-4 h-4 bg-indigo-700"
                                          />
                                          <span> Slightly Agree</span>
                                        </div>

                                        <div className="flex items-center text-xs capitalize text-[#212121] space-x-1">
                                          <span
                                            style={{ background: ColorList[5] }}
                                            className="w-4 h-4 bg-indigo-700"
                                          />
                                          <span> Agree</span>
                                        </div>
                                        <div className="flex items-center text-xs capitalize text-[#212121] space-x-1">
                                          <span
                                            style={{ background: ColorList[6] }}
                                            className="w-4 h-4 bg-indigo-700"
                                          />
                                          <span> Strongly Agree</span>
                                        </div>




                                      </div>
                                    </td>
                                  </tr>

                                  <tr className="text-left text-[#212121] capitalize  border-b border-white bg-[#DBEEFD] text-sm border-l border-t">
                                    <th className="font-medium p-2  text-left border-r border-white">
                                      Dimension
                                    </th>
                                    <th className="font-medium p-2  text-left border-r border-white"  >
                                      Question
                                    </th>
                                    <th className="font-medium p-2  text-right border-r border-white">
                                      Comparison
                                    </th>
                                    <th className=" font-medium p-2  text-left border-r border-white">
                                      Score
                                    </th>
                                    <th className=" font-medium p-2  text-center border-r border-white">
                                      Distribution (%)
                                    </th>
                                  </tr>
                                </>
                              ) : null}

                              <tr className="border-b-2 text-[#212121] text-sm border-l  bg-[#F5F5F5] border-r border-white ">
                                <td rowSpan={2} className=" p-2 font-medium capitalize border-r border-white">
                                  {question && question.parameterDimensionName
                                    ? GetParameterLabel(
                                      question.parameterDimensionName
                                    )
                                    : ""}
                                </td>
                                <td rowSpan={2} className="  p-2 text-sm border-r border-white ">
                                  {question && question.name ? question.name : ""}
                                </td>

                                <td className="p-2 text-sm text-right border-r-2 border-white bg-[#E9E9E9]">
                                  Score
                                </td>

                                <td className=" p-2 text-sm text-left border-r border-white bg-[#E9E9E9]  ">
                                  <div className="h-6 w-full bg-[#CFD8DC]">
                                    <div className="w-full flex items-center  h-6 bg-[#1E88E5]">
                                      <span className="text-xs font-medium text-white px-2">
                                        {GetScore(question)}%{" "}
                                      </span>
                                    </div>
                                  </div>
                                </td>

                                <td rowSpan={2} className="p-2 capitalize border-r border-white text-slate-800">
                                  <BarDistribution
                                    calCScore={calCScore}
                                    question={question}
                                    optionLists={optionLists}
                                  />
                                </td>
                              </tr>
                              <tr className=" bg-[#F5F5F5]">
                                <td className="p-2 text-sm text-right border-r-2 border-white  ">
                                  Overall
                                </td>
                                <td className="p-2 text-sm border-r-2 border-white">
                                  <div className="h-6 w-32 bg-[#CFD8DC]">
                                    <div
                                      className="w-full flex items-center  h-6 bg-[#9C27B0]"
                                    >
                                      <span className="text-xs font-medium text-white px-2">
                                        {calcOverall(question)}%{" "}
                                      </span>
                                    </div>
                                  </div>

                                </td>
                              </tr>
                            </>
                          ))
                          : null}

{indexX < chunkList(NewQuesList).length - 1 ? (
                          <tr className="text-lg text-gray-500 capitalize bg-white">
                            <td
                              colSpan={5}
                              className="font-normal p-2 text-sm text-left text-[#3D405B]/70 "
                            >
                              Continue...
                            </td>
                          </tr>
                        ) : null}



                      </tbody>
                    </table>
                  </>
                ) : (
                  <>
                    <table id={"OrgCoreItems" + indexTemplate.toString()}>
                      <thead>
                        <tr>
                          <th
                            data-pptx-min-width="2.6"
                            style={{
                              textAlign: "left",
                              width: "10%",
                              fontSize: 10,
                              background: "#e6e6e6",
                            }}
                          >
                            Dimension
                          </th>
                          <th
                            data-pptx-min-width="2.0"
                            style={{
                              textAlign: "left",
                              width: "30%",
                              fontSize: 10,
                              background: "#e6e6e6",
                            }}
                          >
                            Question
                          </th>
                          <th
                            data-pptx-min-width="0.8"
                            style={{
                              textAlign: "left",
                              width: "10%",
                              fontSize: 10,
                              background: "#e6e6e6",
                            }}
                          >
                            Favorability
                          </th>
                          <th
                            data-pptx-min-width="0.8"
                            style={{
                              textAlign: "left",
                              width: "7%",
                              fontSize: 10,
                              background: "#e6e6e6",
                            }}
                          >
                            Overall
                          </th>

                          <th
                            data-pptx-min-width="0.5"
                            style={{
                              textAlign: "center",
                              width: "5%",
                              fontSize: 7,
                              background: "#e6e6e6",
                            }}
                          >
                            {optionLabel(1)}
                          </th>
                          <th
                            data-pptx-min-width="0.5"
                            style={{
                              textAlign: "center",
                              width: "5%",
                              fontSize: 7,
                              background: "#e6e6e6",
                            }}
                          >
                            {optionLabel(2)}
                          </th>
                          <th
                            data-pptx-min-width="0.5"
                            style={{
                              textAlign: "center",
                              width: "5%",
                              fontSize: 7,
                              background: "#e6e6e6",
                            }}
                          >
                            {optionLabel(3)}
                          </th>
                          <th
                            data-pptx-min-width="0.5"
                            style={{
                              textAlign: "center",
                              width: "5%",
                              fontSize: 7,
                              background: "#e6e6e6",
                            }}
                          >
                            {optionLabel(4)}
                          </th>
                          <th
                            data-pptx-min-width="0.5"
                            style={{
                              textAlign: "center",
                              width: "5%",
                              fontSize: 7,
                              background: "#e6e6e6",
                            }}
                          >
                            {optionLabel(5)}
                          </th>
                          <th
                            data-pptx-min-width="0.5"
                            style={{
                              textAlign: "center",
                              width: "5%",
                              fontSize: 7,
                              background: "#e6e6e6",
                            }}
                          >
                            {optionLabel(6)}
                          </th>




                        </tr>
                      </thead>
                      <tbody>
                        {NewQuesList && NewQuesList.length > 0
                          ? NewQuesList.map((question, index) => (
                            <tr>
                              <td
                                data-pptx-min-width="2.6"
                                style={{
                                  textAlign: "left",
                                  width: "10%",
                                  fontSize: 10,
                                  background:
                                    index % 2 === 0 ? "#f1f1f1" : "#ffffff",
                                }}
                              >
                                {question && question.parameterDimensionName
                                  ? GetParameterLabel(
                                    question.parameterDimensionName
                                  )
                                  : ""}
                              </td>
                              <td
                                data-pptx-min-width="2.0"
                                style={{
                                  textAlign: "left",
                                  width: "30%",
                                  fontSize: 10,
                                  background:
                                    index % 2 === 0 ? "#f1f1f1" : "#ffffff",
                                }}
                              >
                                {" "}
                                {question.name}
                              </td>

                              <td
                                data-pptx-min-width="0.8"
                                style={{
                                  width: "7%",
                                  fontSize: 10,
                                  background:
                                    index % 2 === 0 ? "#f1f1f1" : "#ffffff",
                                }}
                              >
                                {GetScore(question) + "%"}
                              </td>
                              <td
                                data-pptx-min-width="0.8"
                                style={{
                                  width: "7%",
                                  fontSize: 10,
                                  background:
                                    index % 2 === 0 ? "#f1f1f1" : "#ffffff",
                                }}
                              >
                                {calcOverall(question) + "%"}
                              </td>



                              <td
                                data-pptx-min-width="0.5"
                                style={{
                                  textAlign: "center",
                                  width: "5%",
                                  fontSize: 10,
                                  background: calCScore(question, "1").color,
                                  color: "white",
                                }}
                              >
                                {calCScore(question, "1").value + "%"}
                              </td>
                              <td
                                data-pptx-min-width="0.5"
                                style={{
                                  textAlign: "center",
                                  width: "5%",
                                  fontSize: 10,
                                  background: calCScore(question, "2").color,
                                  color: "white",
                                }}
                              >
                                {calCScore(question, "2").value + "%"}
                              </td>
                              <td
                                data-pptx-min-width="0.5"
                                style={{
                                  textAlign: "center",
                                  width: "5%",
                                  fontSize: 10,
                                  background: calCScore(question, "3").color,
                                  color: "white",
                                }}
                              >
                                {calCScore(question, "3").value + "%"}
                              </td>
                              <td
                                data-pptx-min-width="0.5"
                                style={{
                                  textAlign: "center",
                                  width: "5%",
                                  fontSize: 10,
                                  background: calCScore(question, "4").color,
                                  color: "white",
                                }}
                              >
                                {calCScore(question, "4").value + "%"}
                              </td>
                              <td
                                data-pptx-min-width="0.5"
                                style={{
                                  textAlign: "center",
                                  width: "5%",
                                  fontSize: 10,
                                  background: calCScore(question, "5").color,
                                  color: "white",
                                }}
                              >
                                {calCScore(question, "5").value + "%"}
                              </td>
                              <td
                                data-pptx-min-width="0.5"
                                style={{
                                  textAlign: "center",
                                  width: "5%",
                                  fontSize: 10,
                                  background: calCScore(question, "6").color,
                                  color: "white",
                                }}
                              >
                                {calCScore(question, "6").value + "%"}
                              </td>





                            </tr>
                          ))
                          : null}
                      </tbody>
                    </table>
                  </>
                )}
              </main>
            </div>
          ) : null}
      </>

    );
  }
}
export default OrgCoreItems;
