import React, { Component } from "react";
import { connect } from "react-redux";
import DemographicFilter from "./Filters/DemographicFilter";
import DemographicMultiFilter from "./Filters/DemographicMultiFilter";

import QuestionFilter from "./Filters/QuestionFilter";
import BoxPlot from "./Content/BoxPlot";
import Histogram from "./Content/Histogram";
import Comments from "./Content/Comments";
import Commentary from "../../../Index/Components/Commentary/Commentary";
import LenghtSentences from "./Content/LenghtSentences";

import TreeMapChart from "./Content/TreeMapChart";

class TextAnalysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentQuesId: "",
      DemographicFilterName: "",
      DemographicFilterValue: "",
      FilterList: [],
      showFilter: false,
      showValue: "",

      FilterList2: [],
      showViewBy: false,
      crrSentenceList: [],

      showFilter2: false,
      showValue2: "",
      dmgStateValue: "",
    };
  }

  componentDidMount() { }

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  };
  closeFilter2 = (check1, check2) => {
    this.setState({ showFilter2: check1, showValue2: check2 });
  };
  closeFilterQues = (check1) => {
    this.setState({ showViewBy: check1 });
  };

  handleChangeQuesIdSave = (Id) => {
    this.setState({ currentQuesId: Id });

    // callQuestionWordCloud(item.id);

    let { FilterList2, currentQuesId, graphType } = this.state;
    let { getTextQuestionData, handleWordCloudDemographicFilter } = this.props;

    let initQuesId = "";
    if (currentQuesId) {
      initQuesId = currentQuesId;
    } else {
      initQuesId =
        getTextQuestionData && getTextQuestionData.length > 0
          ? getTextQuestionData[0].id
          : "";
    }

    // handleWordCloudDemographicFilter(initQuesId,FilterList, graphType, "textDistTreeMap");
    handleWordCloudDemographicFilter(
      initQuesId,
      FilterList2,
      graphType,
      "textDist"
    );
  };

  FilterSave = (name, value) => {
    this.setState({
      DemographicFilterName: name,
      DemographicFilterValue: value,
    });
  };

  handleKeywordCall = (keyWord) => {
    let { currentQuesId, FilterList } = this.state;
    let { getTextQuestionData, callWordCloudText } = this.props;

    let initQuesId = "";
    if (currentQuesId) {
      initQuesId = currentQuesId;
    } else {
      initQuesId =
        getTextQuestionData && getTextQuestionData.length > 0
          ? getTextQuestionData[0].id
          : "";
    }

    callWordCloudText(initQuesId, keyWord, FilterList);

  };

  handleSentencesList = (sentences, dmgValue) => {
    let { onTextDistSentence } = this.props;
    onTextDistSentence(sentences, dmgValue)
    this.setState({ crrSentenceList: sentences, dmgStateValue: dmgValue });
  };

  handleMultifilterIndexScore2 = (finalFilter) => {
    let { getTextQuestionData } = this.props;
    let { currentQuesId, graphType } = this.state;
    let initQuesId = "";
    if (currentQuesId) {
      initQuesId = currentQuesId;
    } else {
      initQuesId =
        getTextQuestionData && getTextQuestionData.length > 0
          ? getTextQuestionData[0].id
          : "";
    }

    let { handleWordCloudDemographicFilter } = this.props;

    this.setState({ FilterList: finalFilter });

    handleWordCloudDemographicFilter(
      initQuesId,
      finalFilter,
      graphType,
      "textDistTreeMap"
    );
  };

  handleMultifilterIndexScore3 = (finalFilter) => {
    let { getTextQuestionData } = this.props;
    let { currentQuesId, graphType } = this.state;
    let initQuesId = "";
    if (currentQuesId) {
      initQuesId = currentQuesId;
    } else {
      initQuesId =
        getTextQuestionData && getTextQuestionData.length > 0
          ? getTextQuestionData[0].id
          : "";
    }

    let { handleWordCloudDemographicFilter } = this.props;

    this.setState({ FilterList2: finalFilter });

    handleWordCloudDemographicFilter(
      initQuesId,
      finalFilter,
      graphType,
      "textDist"
    );
  };

  render() {
    let {
      ErrorOtherObj,
      textDistError,
      getIndexByFilterDMGData,
      getEMPFilterLabel,
      indexType,
      optionType,
      textDistTreeMapData,
      getCommentData,
      loading,
      callQuestionWordCloud,
      wordTextKeyWord,
      wordCloudTextData,
      getTextQuestionData,
      handleChangeQuestion,
      textDistData,
      EmpFilterData,
      getTextDistHighlightData
    } = this.props;

    let { dmgStateValue, crrSentenceList, FilterList } = this.state;

    console.log('getTextDistHighlightData: -----> ', getTextDistHighlightData)

    // let initQuesId = '';

    // if(currentQuesId){
    //   initQuesId = currentQuesId;
    // }
    // else{
    //   initQuesId = getTextQuestionData && getTextQuestionData.length>0?getTextQuestionData[0].id:'';
    // }

    let { CountRestrict } = this.props;

    let count = "NaN";

    let valueList = textDistData && textDistData.list ? textDistData.list : [];
    let BoxPlotData =
      textDistData && textDistData.boxplot ? textDistData.boxplot : [];
    let SentencesData =
      textDistData && textDistData.sentences ? textDistData.sentences : [];

    let BoxPlotObj = {};
    if (BoxPlotData && BoxPlotData.length > 0) {
      BoxPlotData.forEach((item) => {
        if (item && item[0]) {
          BoxPlotObj[item[0]] = item[1];
        }
      });
    }

    let CloudData = CountRestrict(count) ? [] : valueList;
    // let CloudTextData = CountRestrict(count)?[]:wordCloudTextData;
    // let wordTextKeyWordData = CountRestrict(count)?"":wordTextKeyWord;

    let countList = [];
    let allDATA = [];

    if (CloudData && CloudData.length > 0) {
      CloudData.forEach((item, index1) => {
        if (item) {
          allDATA.push(item);
          let getIndex = countList.findIndex((prev) => prev.value === item);
          if (getIndex !== -1) {
            countList[getIndex]["count"] = countList[getIndex]["count"] + 1;
            if (SentencesData && SentencesData[index1]) {
              countList[getIndex]["sentences"].push(SentencesData[index1]);
            }
          } else {
            let temp1 = {
              value: item,
              count: 1,
              sentences: [],
            };

            if (SentencesData && SentencesData[index1]) {
              temp1["sentences"].push(SentencesData[index1]);
            }
            countList.push(temp1);
          }
        }
      });
    }

    countList.sort((a, b) => a.value - b.value);

    console.log("EmpFilterData----->>>", EmpFilterData);

    // ////////console.log("textDistData: ",textDistData);

    let xaXis1 = "Demographics";
    if (FilterList && FilterList.length > 0) {
      xaXis1 = FilterList[0]["level"];
    } else {
      xaXis1 =
        EmpFilterData && EmpFilterData.length > 0
          ? EmpFilterData[0]["name"]
          : "Demographics";
    }

    console.log("textDistTreeMapData: ", textDistTreeMapData);


    return (
      <>
        <div onClick={() => this.closeFilter(false)}>
          <div className="flex flex-wrap items-start justify-between w-full gap-4">
            <QuestionFilter
              showViewBy={this.state.showViewBy}
              closeFilterQues={this.closeFilterQues}
              callQuestionWordCloud={callQuestionWordCloud}
              getTextQuestionData={getTextQuestionData}
              handleChangeQuestion={handleChangeQuestion}
              handleChangeQuesIdSave={this.handleChangeQuesIdSave}
            />
          </div>
        </div>

        <div className="items-center justify-between space-y-4 md:flex lg:space-y-0">
          <DemographicMultiFilter
            showFilter={this.state.showFilter2}
            showValue={this.state.showValue2}
            closeFilter={this.closeFilter2}
            EmpFilterData2={EmpFilterData}
            getIndexFilterData={getIndexByFilterDMGData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore3}
            getEMPFilterLabel={getEMPFilterLabel}
          />
          <Commentary
            indexType={indexType}
            optionType={optionType}
            getCommentData={getCommentData}
          />
        </div>


        {getTextQuestionData && getTextQuestionData.length > 0 ? (
          <div>
            <div
              onClick={() => {
                this.closeFilter2(false);
                this.closeFilterQues(false);
              }}
              className="w-full"
            >
              {ErrorOtherObj && ErrorOtherObj["textDistTreeError"] ? (
                <div className="py-6 my-24 text-2xl font-medium text-center text-gray-400 bg-white">
                  {ErrorOtherObj["textDistTreeError"]}
                </div>
              ) : (
                <div className="grid w-full grid-cols-1 gap-4">
                  {allDATA && allDATA.length > 0 ? (
                    <BoxPlot
                      BoxPlotObj={BoxPlotObj}
                      loading={loading}
                      CloudData={allDATA}
                      handleKeywordCall={this.handleKeywordCall}
                    />
                  ) : null}

                  <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
                    <div className="md:p-6 p-4 space-y-6 transition duration-150 bg-white border rounded-lg hover:shadow-lg">
                    
                        <div className="flex items-center justify-between ">
                          <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] capitalize">Demographic Wise Total No. Of Comments</h1>
                          <DemographicFilter
                            showFilter={this.state.showFilter}
                            showValue={this.state.showValue}
                            closeFilter={this.closeFilter}
                            EmpFilterData2={EmpFilterData}
                            getIndexFilterData={getIndexByFilterDMGData}
                            handleMultifilterIndexScore={
                              this.handleMultifilterIndexScore2
                            }
                            getEMPFilterLabel={getEMPFilterLabel}
                            xaXis={xaXis1}
                          />
                        </div>

                        {textDistTreeMapData && textDistTreeMapData.length > 0 ? (
                          <Histogram
                            xaXis={xaXis1}
                            handleSentencesList={this.handleSentencesList}
                            textDistTreeMapData={textDistTreeMapData}
                            loading={loading}
                            handleKeywordCall={this.handleKeywordCall}
                          />
                        ) : null}
                  
                    </div>

                    <div className="flex w-full">
                      {countList && countList.length > 0 ? (
                        <LenghtSentences
                          countList={countList}
                          loading={loading}
                          handleKeywordCall={this.handleKeywordCall}
                          xaXis={xaXis1}
                          dmgStateValue={dmgStateValue}
                          crrSentenceList={crrSentenceList}
                          getTextDistHighlightData={getTextDistHighlightData}
                        />
                      ) : (
                        <div className="py-10 text-3xl text-center text-gray-400">
                          {"Text length distribution tree map"}
                        </div>
                      )}

                      {/* <>
                        {countList && countList.length > 0 ? (
                          <LenghtSentences
                            countList={countList}
                            loading={loading}
                            handleKeywordCall={this.handleKeywordCall}
                            xaXis={xaXis1}
                            dmgStateValue={dmgStateValue}
                            crrSentenceList={crrSentenceList}
                          />
                        ) : (
                          countList ? (
                            <div className="py-10 text-3xl text-center text-gray-400">
                              Text length distribution tree map
                            </div>
                          ) : (
                            <div className="pt-40 text-3xl text-center text-gray-400">
                              We have not calculated this section!
                            </div>
                          )
                        )}
                      </> */}


                      <div />
                    </div>
                  </div>
                </div>
              )}

            </div>
          </div>
        ) : (
          <>
            {!loading ? (
              <div className="pt-40 text-3xl text-center text-gray-400">
                We have not calculated this section!
              </div>
            ) : null}
          </>
        )}
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn,
  };
}
export default connect(mapStateToProps)(TextAnalysis);
