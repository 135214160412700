import React, { Component } from "react";
import Header from "../Components/Header";

class Employee extends Component {
  render() {
    let {
      getDemographicIndexEngCompOverallData,

      getDemographicIndexMultiFilterEngCompData,
      getDemographicIndexMultiFilterEngComp2Data,

      getEMPFilterLabel,
      GetParameterLabel,
      getOrgIndexV2Data,
      getSurveyData,
      surveyLogo,
      CountRestrict,
      showYearData,
      yearValue1,
      yearValue2,
      indexTemplate
    } = this.props;

    function calcOverall(axisItem, dataItem) {
      let overallX = 0;
      if (
        getDemographicIndexEngCompOverallData &&
        getDemographicIndexEngCompOverallData.length > 0
      ) {
        let getIndex1 = getDemographicIndexEngCompOverallData.findIndex(
          (prev) => prev.xAxis === axisItem
        );
        if (getIndex1 !== -1) {
          let overallData = getDemographicIndexEngCompOverallData[getIndex1];
          let innerOverallList =
            overallData && overallData.Data ? overallData.Data : [];
          let getIndex2 = innerOverallList.findIndex(
            (prev) => prev.name === dataItem
          );
          let innerData = innerOverallList[getIndex2];
          overallX = innerData && innerData[2] ? innerData[2] : 0;
        }
      }
      return overallX && overallX.toFixed(2) ? overallX.toFixed(2) : 0;
    }

    function getFormattedList(DemographicIndexMultiFilterEngCompData) {
      let list2 = []
      if (
        DemographicIndexMultiFilterEngCompData &&
        DemographicIndexMultiFilterEngCompData.length > 0
      ) {
        DemographicIndexMultiFilterEngCompData.forEach((item) => {
          let tempData = {
            xAxis: item.xAxis,
            Data: [],
          };
          let innerList = [];
          if (item && item.Data && item.Data.length > 0) {
            item.Data.forEach((inner) => {
              let score = CountRestrict(inner.count) ? "NA" : inner[2];

              innerList.push([inner.name, score]);
            });
          }

          if (item.xAxis.trim() === "Grade") {
            let ageLabelList = [
              "L1",
              "L2",
              "L3",
              "L4",
              "L5",
              "L6",
              "L7",
              "L8",
              "L9",
              "L10",
              "L11",
              "L12",
              "L13",
              "L14",
              "L15",
              "L16",
              "L17",
              "L18",
              "L19",
              "L20",
              "L7A",
              "SA",
              "Sa",
            ];

            innerList.sort(
              (a, b) => ageLabelList.indexOf(a[0]) - ageLabelList.indexOf(b[0])
            );
          } else if (item.xAxis.trim() === "Age") {
            let ageLabelList = [
              "25 Yrs & Below",
              "Between 25 to 30 yrs",
              "Between 31 to 34 yrs",
              "Between 35 to 44 yrs",
              "Between 45 to 55 yrs",
              "Above 55 Yrs",
            ];

            innerList.sort(
              (a, b) => ageLabelList.indexOf(a[0]) - ageLabelList.indexOf(b[0])
            );
          } else {
            innerList.sort(
              (a, b) =>
                (a && a[0] && a[0].toString()
                  ? a[0].toString().charCodeAt(0)
                  : "") -
                (b && b[0] && b[0].toString()
                  ? b[0].toString().charCodeAt(0)
                  : "")
            );
          }

          tempData["Data"] = innerList;
          list2.push(tempData);
        });
      }

      return list2
    }

    let FinalData = getFormattedList(getDemographicIndexMultiFilterEngCompData);
    let Final2Data = getFormattedList(getDemographicIndexMultiFilterEngComp2Data);

    console.log("item-FinalData--------->", FinalData)
    console.log("item-Final2Data--------->", Final2Data)

    let listValue = []
    if (FinalData && FinalData.length > 0) {
      for (var i = 0; i < FinalData.length; i++) {
        let question1 = FinalData[i];
        let questionTemp = {
          'Data': question1['Data'],
          'xAxis': question1['xAxis']
        }

        if (questionTemp && questionTemp['Data'] && questionTemp['Data'].length > 0) {
          for (var i = 0; i < questionTemp['Data'].length; i++) {
            let ele = questionTemp['Data'][i];

            listValue.push({
              "name": ele[0],
              "score": ele[1],
              "score2": 0,
              "xAxis": questionTemp["xAxis"]
            })
          }

        }

      }
    }
    if (Final2Data && Final2Data.length > 0) {
      for (var i2 = 0; i2 < Final2Data.length; i2++) {
        let question2 = Final2Data[i2];

        let question2Temp = {
          'Data': question2['Data'],
          'xAxis': question2['xAxis']
        }

        let list2 = listValue.filter(prev => prev.xAxis === question2Temp['xAxis'])
        if (list2 && list2.length > 0) {
          if (question2Temp && question2Temp['Data'] && question2Temp['Data'].length > 0) {
            for (var i4 = 0; i4 < question2Temp['Data'].length; i4++) {
              let ele = question2Temp['Data'][i4];
              let itemMatch2 = listValue.findIndex(prev => prev.name === ele[0])
              if (itemMatch2 !== -1) {
                listValue[itemMatch2]["score2"] = ele[1];
              } else {
                listValue.push({
                  "name": ele[0],
                  "score": 0,
                  "score2": ele[1],
                  "xAxis": question2Temp["xAxis"]
                })
              }

            }
          }
        } else {
          if (question2Temp && question2Temp['Data'] && question2Temp['Data'].length > 0) {
            for (var i3 = 0; i3 < question2Temp['Data'].length; i3++) {
              let ele = question2Temp['Data'][i3];
              listValue.push({
                "name": ele[0],
                "score": 0,
                "score2": ele[1],
                "xAxis": question2Temp["xAxis"]
              })
            }
          }



        }



      }
    }



    console.log("listValue---->", listValue)

    let newList = [];
    if (
      listValue &&
      listValue.length > 0
    ) {
      listValue.forEach((element, index) => {
        let getIndex = newList.findIndex(
          (prev) => prev.xAxis === element.xAxis
        );
        if (getIndex === -1) {
          let temp = {
            xAxis: element.xAxis,
            Data: [
              [
                element.name,
                element.score,
                element.score2
              ]
            ],
          };
          newList.push(temp);
        } else {
          newList[getIndex]["Data"].push([
            element.name,
            element.score,
            element.score2
          ]);
        }
      });
    }

    console.log(
      "newList--->",
      newList
    );



    function chunkList(array) {
      const chunkSize = 11;
      let list = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        const chunk = array.slice(i, i + chunkSize);
        list.push(chunk);
      }
      //console.log("list--->", list);
      return list;
    }



    return (
      <>
        {FinalData && FinalData.length > 0 ?
          chunkList(FinalData).map((NewQues, indexX) =>
            <section className="px-8 py-6 page-break">
              {/* report header */}
              <div class="flex items-center justify-between border-b py-4 text-[#212121]/80 ">
                <p class="font-medium italic text-base ">Employee Wellness Survey</p>
                <p class="font-medium italic text-base">15 March, 2024</p>
              </div>


              <div className="mb-12 page-break">
                {/* Engagement Comparison - Gender9999999 report design */}
                <main className="w-full px-8 space-y-6">
                  {NewQues && NewQues.length > 0
                    ? NewQues.map((item, index0) => (
                      <>
                        <div className="">
                          <div className="text-left ">
                            <div className="pt-4 -mx-12">
                              <Header surveyLogo={surveyLogo} />
                            </div>
                            <div className>
                              <div className="w-full py-6 capitalize ">
                                <h1 className="text-2xl text-[#3D405B] font-semibold border-b border-[#DDDDDD] pb-4 capitalize">
                                  {"Engagement Comparison - " +
                                    getEMPFilterLabel(item.xAxis)}
                                </h1>
                              </div>
                            </div>
                          </div>
                        </div>

                        <table className="w-full border border-white">
                          <tbody>
                            {item && item.Data && item.Data.length > 0
                              ? item.Data.map((itemData, index) => (
                                <>
                                  {index % 15 === 0 ? (
                                    <>
                                      {index ? (
                                        <tr className="text-lg text-gray-500 capitalize">
                                          <th className="w-12 p-2 font-bold text-left text-gray-700" colSpan={5}>
                                            Continue...
                                          </th>
                                        </tr>
                                      ) : null}

                                      <tr className="page-break text-left text-[#3D405B] capitalize border-b bg-[#F1F5F9] border-white text-sm border-l border-t">

                                        <th className="w-[20%] font-medium p-2  text-left border-r border-white">
                                          {getEMPFilterLabel(item.xAxis)}
                                        </th>
                                        <th className="w-[60%] font-medium p-2  text-left border-r border-white" colSpan={3}>
                                          Score
                                        </th>
                                        <th className="w-[5%] font-medium p-2 text-center border-r border-white">
                                          overall
                                        </th>
                                      </tr>
                                    </>
                                  ) : null}

                                  <tr className="border-b text-[#3D405B] bg-[#F1F5F9] border-white text-sm border-l">
                                    <td className="p-2 font-medium capitalize border-r border-white">
                                      {GetParameterLabel(itemData[0])}
                                    </td>
                                    <td className="p-2 font-medium capitalize border-r border-white" colSpan={3}>
                                      <div className="w-full space-y-0">

                                        {showYearData(yearValue1, indexTemplate) ?
                                          <div className="flex flex-row py-0.5 items-center gap-1.5">
                                            <p className="flex justify-end overflow-hidden text-right w-14">{yearValue1}</p>

                                            <div className="h-3 w-full bg-[#CFD8DC]">
                                              <div
                                                className="h-3 w-full bg-[#2196F3]"
                                                style={{
                                                  background: "#2196F3",
                                                  width:
                                                    (itemData &&
                                                      itemData[1] &&
                                                      itemData[1] !== "NA"
                                                      ? itemData[1]
                                                      : "0") + "%",
                                                }}
                                              />
                                            </div>
                                            <div className=" text-sm  font-medium text-left w-[90px] flex   overflow-hidden">
                                              {itemData &&
                                                itemData[1] &&
                                                itemData[1] !== "NA" &&
                                                itemData[1].toFixed(2)
                                                ? itemData[1].toFixed(2) + "%"
                                                : "NA"}

                                            </div>
                                          </div>
                                          : null}

                                        {showYearData(yearValue2, indexTemplate) ?
                                          <div className="flex flex-row py-0.5 items-center gap-1.5">
                                            <p className="flex justify-end overflow-hidden text-right w-14">{yearValue2}</p>

                                            <div className="h-3 w-full bg-[#CFD8DC]">
                                              <div
                                                className="h-3 w-full bg-[#9576CD]"
                                                style={{
                                                  background: "#9576CD",
                                                  width:
                                                    (itemData &&
                                                      itemData[2] &&
                                                      itemData[2] !== "NA"
                                                      ? itemData[2]
                                                      : "0") + "%",
                                                }}
                                              />
                                            </div>
                                            <div className="  text-sm  font-medium text-left w-[90px] flex   overflow-hidden">
                                              {itemData &&
                                                itemData[2] &&
                                                itemData[2] !== "NA" &&
                                                itemData[2].toFixed(2)
                                                ? itemData[2].toFixed(2) + "%"
                                                : "NA"}
                                            </div>
                                          </div>
                                          : null}

                                      </div>

                                    </td>
                                    <td className="p-2 font-medium capitalize border-r border-white">
                                      <div className="h-6 px-2 text-sm font-medium text-center text-gray-800">
                                        {calcOverall(item.xAxis, itemData[0]) + "%"}{" "}
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              ))
                              : null}
                          </tbody>
                        </table>
                      </>
                    ))
                    : null}
                </main>
              </div>

            </section>
          ) : null}
      </>

    );
  }
}
export default Employee;
