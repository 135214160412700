import React, { Component } from 'react';
import { connect } from 'react-redux';
import { accessModule } from '../../_helpers';

//api call
import { dashboardActions, questionsActions, surveyActions, userActions, generalActions } from '../../_actions';

//Layout
import Sidebar from './Sidebar/Sidebar';


//libraries
import LoadingOverlay from 'react-loading-overlay';

import Overview from './Overview/Overview';

import Header from '../CommonComponents/DashboardHeader/Header';

import { CONST } from '../../_config';


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      indexType: 0,
      CurrentSurveyName: '',
      CurrentSurveyId: this.props.match.params.id,
      optionType: 0,
      showFilterDropdownName: false,
      showFilterDropdownValue: false,
      CurrentIndexName: '',
      filterSelectIndex: 0,
      loaderActive: false,
      filterOn: false,
      FilterList2: [],
      showFilter: true,

      loadReport: false,
      isFilter: false,

      showMobileSidebar: false,

      showOverview: true,
      showIndex: false,
      showManagerList: false,
      showOrgCore: false,
      showAdvanceComparision: false,
      showTextAnalysis: false,
      showStrengthAndWeaknesses: false,
      showCustomScoreCard: false,
      showManagerActionPlan: false,
      showManagerGoalPlan: false,
      showReport: false,
      showOtherTypes: false,
      showPeopleToMeet: false,

      StackObject: [],
      StackObjectTotal: [],
      ReturnFinalFilterList: [],
      selectedFilterList: [],
      selectedFilterValue: 'none',
      HeatMapSelectedFilter: 'Department',

      DynaObj: [],

      Mindextype: '',

      customeScoreData: "",

      ManagerDynaObj: [],

      QuesDynaObj: {},

      QuesMngrHappinessReportObject: {},
      QuesMngrEngagementReportObject: {},
      QuesMngrStressReportObject: {},
      QuesMngrManagerReportObject: {},

      DriverMngrHappinessReportObject: {},
      DriverMngrEngagementReportObject: {},
      DriverMngrStressReportObject: {},
      DriverMngrManagerReportObject: {},

      MQuesIndextype: '',
      rwaIndexType: 1,
      ManagerListDasboardDataObj: [],
      ManagerListQuestionDataObj: [],

      showSidebar: false,
      currentQuestionId: '',
      currentQuestionTemplate: '',
      showAllQuestions: true,

      showReportDMGModal: false,

      ReportDMGindexType: "",
      ReportDMGcheck: "",
      ReportDMGfiltersListNew: "",
      ReportxAxis: "",
      isReportOverall: false,

      ManagerReportFConfig: {
        "managerIdType": CONST.MANAGER_ID_TYPE //Int or String
      }

    }
  }


  componentDidMount() {


    this.LoadOverviewInitFn()

  }


  LoadOverviewInitFn = () => {
    let data1 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 500
    }

    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "filters": []
    }


    let data5 = {
      "surveyId": this.state.CurrentSurveyId
    }



    let data51 = {
      "parentName": "Feedback_360"
    }

    this.props.dispatch(dashboardActions.getParameterLibraryLabelByParentName(data51));

    // this.props.dispatch(dashboardActions.getOverallIndex(data5));

    // this.props.dispatch(dashboardActions.getOverallScoreBySurveyId(data5));

    //=============== org core


    // let data19 = {
    //   filters: [{level: "ManagerPID", value: "11000341"}],
    //   surveyId: "60b9df102f68b86828ce1539",
    //   template: 2
    // }
    // this.props.dispatch(dashboardActions.getQuestion(data19));



    //-------


    this.props.dispatch(surveyActions.getParameterLabelByName({
      "parameterIndexName": "Feedback_360"
    }));

    this.props.dispatch(dashboardActions.getCompanyNameOnly());

    this.props.dispatch(surveyActions.getIndexByFilterDMG());

    //   let data122 = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 1000
    // }

    //   this.props.dispatch(dashboardActions.getParameterList(data122));



    this.props.dispatch(dashboardActions.getRespondantDetails(data5));
    // this.props.dispatch(dashboardActions.getIndex(data5));
    this.props.dispatch(surveyActions.getSurveyById(data5));




    this.props.dispatch(dashboardActions.getClientProfile());


    this.props.dispatch(dashboardActions.getEmpFilter());

    // this.props.dispatch(dashboardActions.getCompanyEmployeeList(data1));

    // this.props.dispatch(dashboardActions.getIndexFilter(data2));

    // this.props.dispatch(dashboardActions.getAllBenchMark());


    // this.props.dispatch(dashboardActions.getComment(
    //   {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 60
    // }
    // ));



    // HAPPINESS_INDEX: 1,
    // ENGAGEMENT_INDEX: 2,
    // HOPE_INDEX: 3,
    // STRESS_INDEX: 4,
    // MANAGER_INDEX: 5,
    // LEADER_INDEX: 6,
    // SENIORLEADERSHIP_INDEX: 7,
    // ORGANIZATIONCORE_INDEX: 8,



    //   let data5 = {
    //     "surveyId": "5fa04d316ede0f0336066cce",
    //     "xAxis": "",
    //     "filters": null
    // }
    //   this.props.dispatch(dashboardActions.getOrgCoreIndex(data5));




    // this.props.dispatch(generalActions.getQuestionAvgScore({"surveyId": this.state.CurrentSurveyId,qtemplateList:[1]}));

    // this.props.dispatch(generalActions.getQuestionOthersResult({
    //   "surveyId": this.state.CurrentSurveyId,
    //   "qtemplateList":[1,2,6,11,16]
    // }));

    // this.props.dispatch(surveyActions.getIndexByFilterDMG());







    let data10 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 1000
    }


    this.props.dispatch(surveyActions.getParameterList(data10));








    this.get360Overview();
    this.get360DimensionItemDetails()


    this.props.dispatch(dashboardActions.getAnsweredEmployees(data5));



  }





  handleLogout = () => {
    this.props.dispatch(userActions.logout());
  }

  handleSidebar = (check) => {
    this.setState({ showSidebar: check })
  }
  handleMobileSidebar = (check) => {
    this.setState({ showMobileSidebar: check })
    this.setState({ showSidebar: check })
  }


  handleSummaryCard = (name) => {
    let { CurrentSurveyId } = this.state;

    let { indexType } = this.state;
    let data = {
      "surveyId": CurrentSurveyId,
      "xAxis": name
    }
    this.props.dispatch(dashboardActions.getDemographicIndex(data));

    if (indexType === 1) {
      this.props.dispatch(dashboardActions.getHappinessIndexV2Demographic(data));
    }
    else if (indexType === 2) {
      this.props.dispatch(dashboardActions.getEngagementIndexV2Demographic(data));
    }
    else if (indexType === 4) {
      this.props.dispatch(dashboardActions.getStressIndexV2Demographic(data));
    }
    else if (indexType === 5) {
      this.props.dispatch(dashboardActions.getDemographicIndexManager(data));
    }
    else if (indexType === 6) {
      this.props.dispatch(dashboardActions.getDemographicIndexLeader(data));
    }
  }

  handleSummaryFilter = (name, value) => {
    let { CurrentSurveyId } = this.state;
    let data = {
      "surveyId": CurrentSurveyId,
      "filters": [
        { "level": name, "value": value }
      ]
    }
    this.props.dispatch(dashboardActions.getIndexByFilter(data));
  }

  handleQuestionFilter = (name, value) => {
    let { indexType, CurrentSurveyId } = this.state;
    let data = {
      "surveyId": CurrentSurveyId,
      "template": indexType,
      "filters": [
        { "level": name, "value": value }
      ]
    }
    // //////console.log('data: ',data)
    this.props.dispatch(dashboardActions.getQuestionFilter(data));
  }

  handleDistributionFilter = (name) => {
    let { indexType } = this.state;
    let data = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis": name
    }
    if (indexType === 1) {
      this.props.dispatch(dashboardActions.getDemographicIndexHappiness(data));
    }
    else if (indexType === 2) {
      this.props.dispatch(dashboardActions.getDemographicIndexEngagement(data));
    }
    else if (indexType === 4) {
      this.props.dispatch(dashboardActions.getDemographicIndexStress(data));
    }
    else if (indexType === 5) {
      this.props.dispatch(dashboardActions.getDemographicIndexManager(data));
    }
    else if (indexType === 6) {
      this.props.dispatch(dashboardActions.getDemographicIndexLeader(data));
    }
    else if (indexType === 8) {
      this.props.dispatch(dashboardActions.getDemographicIndexOrg(data));
    }
  }


  onCustomScoreCard = (item) => {

    this.setState({
      showOverview: false,
      showIndex: false,
      showManagerList: false,
      showOrgCore: false,
      showAdvanceComparision: false,
      showTextAnalysis: false,
      showStrengthAndWeaknesses: false,
      showManagerActionPlan: false,
      showManagerGoalPlan: false,
      showReport: false,
      showOtherTypes: false,
      showAllQuestions: false,
      showCustomScoreCard: true,
      currentQuestionTemplate: '',

      customeScoreData: item,
      showPeopleToMeet: false

    })
  }



  onOverviewFromHeader = () => {
    let { CurrentSurveyId, showOverview } = this.state;
    if (showOverview) {
      this.props.history.goBack()
    }
    else {
      this.onOptionType(CurrentSurveyId, 0, 1);
    }


  }


  onOverview = (surveyId) => {
    this.setState({
      showOverview: true,
      showIndex: false,
      showManagerList: false,
      showOrgCore: false,
      showAdvanceComparision: false,
      showTextAnalysis: false,
      showStrengthAndWeaknesses: false,
      showManagerActionPlan: false,
      showManagerGoalPlan: false,
      showCustomScoreCard: false,
      showReport: false,
      showOtherTypes: false,
      showPeopleToMeet: false


    })
    // this.setState({CurrentSurveyId:surveyId})

  }






  get360DimensionItemDetails = (FilterValues, isFilterTwice, name, black, emp_id) => {
    //console.log("A3 get360DimensionItemDetails--------->",FilterValues,isFilterTwice,name, black, emp_id)

    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }


    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "filters": Filters
    }
    if (emp_id) {
      data7["emp_id"] = emp_id
    }

    //console.log("data7------------>",emp_id)
    this.props.dispatch(dashboardActions.get360DimensionQuestions(data7));
    this.props.dispatch(dashboardActions.get360RelationResponse(data7));
    this.props.dispatch(dashboardActions.getFeedbackAndQuestionName(data7));
    this.props.dispatch(dashboardActions.getTextAndQuestionName(data7));

    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "filters": Filters
    }

    this.props.dispatch(dashboardActions.getAnsweredEmployees(data2));

  }



  get360Overview = (FilterValues, isFilterTwice, name, black, emp_id) => {

    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }





    let data = {
      "surveyId": this.state.CurrentSurveyId,
      "filters": Filters
    }
    if (emp_id) {
      data["emp_id"] = emp_id
    }

    this.props.dispatch(dashboardActions.get360DimensionRelationScore(data));

    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "filters": Filters
    }

    this.props.dispatch(dashboardActions.getAnsweredEmployees(data2));

  }






  get360RelationResponse = (FilterValues, isFilterTwice, name, black, emp_id) => {

    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }





    let data = {
      "surveyId": this.state.CurrentSurveyId,
      "filters": Filters
    }
    if (emp_id) {
      data["emp_id"] = emp_id
    }

    this.props.dispatch(dashboardActions.get360RelationResponse(data));

  }

  getFeedbackAndQuestionName = (FilterValues, isFilterTwice, name, black, emp_id) => {


    let XAxis = "";
    let Filters = [];
    if (FilterValues && FilterValues.length > 0) {
      let getIndex = FilterValues.findIndex(prev => prev.value === "All");
      if (getIndex !== -1) {
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item) => {
          if (item.value !== "All") {
            Filters.push(item);
          }
        });
      }
      else {
        XAxis = FilterValues[FilterValues.length - 1].level;
        Filters = FilterValues
      }

    }


    let data = {
      "surveyId": this.state.CurrentSurveyId,
      "filters": Filters
    }
    if (emp_id) {
      data["emp_id"] = emp_id
    }


    this.props.dispatch(dashboardActions.getFeedbackAndQuestionName(data));
    this.props.dispatch(dashboardActions.getTextAndQuestionName(data));

  }


  getTemp360EmpStrengthCall = (emp_id) => {

    if (emp_id) {
      let data = {
        "surveyId": this.state.CurrentSurveyId,
        "emp_id": emp_id
      }
      this.props.dispatch(dashboardActions.getTemp360EmpStrength(data));

    }

  }



  render() {

    let { dashboard, questions, survey, general } = this.props;
    let { questionsList } = questions;


    let { getParameterLabelByNameData } = survey;

    let { getQuestionAvgScoreData, getQuestion2OthersResultFilterData, getQuestionOthersResultData, getQuestionOthersResultFilterData, getQuestionOthersMultiResultFilterData } = general;



    //////console.log("----------------------------------------------------------------------------------------------------------")
    // //console.log("getParameterLibraryLabelByParentNameData: ",getParameterLibraryLabelByParentNameData)
    //////console.log("----------------------------------------------------------------------------------------------------------")


    let { showPeopleToMeet, customeScoreData, showCustomScoreCard, showReportDMGModal, ManagerReportFConfig, loadReport, isFilter, currentQuestionId, currentQuestionTemplate } = this.state;

    let {

      getTemp360EmpStrengthData,
      get360DimensionRelationScoreData,
      get360DimensionQuestionsData,
      getAnsweredEmployeesData,
      get360RelationResponseData,
      getFeedbackAndQuestionNameData,
      getTextAndQuestionNameData,
      getParameterLibraryLabelByParentNameData,



      clusterAnalysisError,
      textDistError,
      TreeMapError,
      neighborGraphError,
      wordCloudError,
      topicModellingError,
      getCorrelationDimenionVsDimensionError,
      crossTabError,

      getQuestionCustomTemplateData,
      getQuestionMapData,
      getCompanyNameOnlyData,
      getIDPLastDateData,
      getAllManagerGoalPlanData,
      clusterAnalysisData,
      clusterAnalysisCount,

      getOverallScoreBySurveyIdData,

      getAllManagerScoresData,

      getMngrReportDownloadStatusData,
      getMngrReportDownloadStatusTotal,

      getTopicListData,

      clusterAnalysisMSG,

      topicModellingData,

      getClusterEmpInfoListData,

      getParameterLibraryListData,

      ClientProfileData, SurveyList, HappinessIndexMNGRData,
      EngagementIndexMNGRData, HopeIndexMNGRData, StressIndexMNGRData,

      getDemographicIndexMultiFilterEngCompData,
      getQuestionCommentsData,

      getOrgIndexV2FilterData,

      TreeMapData,
      textDistTreeMapData,
      EmpFilterData,
      getEngagementRWAData,
      getDemographicIndexFilterReportData,

      textDistData,
      getDemographicIndexMultiFilterData,

      ManagerListData, getDemographicIndexData,

      wordCloudMultiTextData,
      wordMultiTextKeyWord,

      loading,

      crossTabAnalysisData,
      getQuestionFavorability2Data,

      getManagerListDashboardManagerIndexData,


      getDemographicOrgReportData,

      getDemographicIndexEngCompOverallData,


      HappinessIndexNewData,
      EngagementIndexNewData,
      HopeIndexNewData,
      StressIndexNewData,
      ManagerIndexNewData,




      getQuestionFilterData,

      OrgCoreIndexData,

      getIndexFilterData,
      getManagerListDomainFilterData,

      getQuestionData,

      getDemographicIndexHappinessData,
      getDemographicIndexEngagementData,
      getDemographicIndexHopeData,
      getDemographicIndexStressData,
      getDemographicIndexManagerData,
      getDemographicIndexLeaderData,

      getQuestionManagerOfHappinessData,
      getQuestionManagerOfEngagementData,
      getQuestionManagerOfStressData,
      getQuestionManagerOfManagerData,


      // getHappinessIndexV2Data,
      // getEngagementIndexV2Data,
      // getStressIndexV2Data,
      // getManagerIndexV2Data,

      getDemographicIndexScatterData,


      getHappinessCorrelationData,
      getEngagementCorrelationData,
      getStressCorrelationData,
      getManagerCorrelationData,
      getLeaderCorrelationData,


      getRWAnalysisData,


      getTextQuestionData,

      wordCloudData,
      AdvanceWordCloudData,

      wordTextKeyWord,

      cooccurmatrixData,


      getQuestionFavorabilityData,



      getCommentData,

      crossTabData,
      crossTabDMGData,

      getQuestionOverallData,

      getCorrelationDimenionVsDimensionData,
      getCorrelationDimensionVsDimensionFilterData,
      getOrgIndexV2Data,
      getAllBenchMarkData,




      getHappinessQuestionData,
      getHappinessManagerQuestionData,
      getHappinessDMGQuestionData,

      getEngagementQuestionData,
      getEngagementManagerQuestionData,
      getEngagementDMGQuestionData,

      getHopeQuestionData,

      getStressQuestionData,
      getStressManagerQuestionData,
      getStressDMGQuestionData,


      getManagerQuestionData,
      getManagerManagerQuestionData,
      getManagerDMGQuestionData,


      getLeaderQuestionData,
      getLeaderManagerQuestionData,
      getLeaderDMGQuestionData,

      getHappinessIndexV2Data,
      getEngagementIndexV2Data,
      getHopeIndexV2Data,
      getStressIndexV2Data,
      getManagerIndexV2Data,
      getLeaderIndexV2Data,

      getHappinessIndexDriverData,
      getEngagementIndexDriverData,
      getHopeIndexDriverData,
      getStressIndexDriverData,
      getManagerIndexDriverData,
      getLeaderIndexDriverData,


      getHappinessIndexV2DemographicData,
      getEngagementIndexV2DemographicData,
      getHopeIndexV2DemographicData,
      getStressIndexV2DemographicData,
      getDemographicIndexOrgData,


      getHappinessIndexByFilterData,
      getEngagementIndexByFilterData,
      getStressIndexByFilterData,
      getManagerIndexByFilterData,
      getLeaderIndexByFilterData,
      getOverallIndexData,


      getHappinessIndexV2FilterData,
      getEngagementIndexV2FilterData,
      getHopeIndexV2FilterData,
      getStressIndexV2FilterData,

      getHappinessIndexDriverFilterData,
      getEngagementIndexDriverFilterData,
      getHopeIndexDriverFilterData,
      getStressIndexDriverFilterData,




    } = dashboard;

    let { showManagerGoalPlan, Mindextype, MQuesIndextype, ManagerListDasboardDataObj, ManagerListQuestionDataObj, SelectedAPDimension, SelectedAPAction, SelectedAPTask } = this.state;


    //ROLE MANAGEMENT ----------------------------------------------
    let moduleAccess = {
      "isAdmin": false,
      "access": {}
    };
    if (accessModule()) {
      moduleAccess["access"] = accessModule();
    }






    let SHOW_ELEMENT = false;
    let isCrole = false;
    let clientData = this.props.dashboard.ClientProfileData;
    if (clientData && clientData.role) {

      if (clientData.role === "ADMIN") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = true;
      }
      else if (clientData.role === "MNGR") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (clientData.role === "CROLE") {
        isCrole = true
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (clientData.role === "EMP") {
        SHOW_ELEMENT = false;
        moduleAccess["isAdmin"] = false;
      }
    }
    function accessAllow(item) {
      return (moduleAccess.isAdmin ? true : (moduleAccess && moduleAccess.access && moduleAccess.access[item] ? true : false))
    }

    //END -------------------------------------------------------




    let ManagerIndexQuestionData = '';

    if (MQuesIndextype === 1) {
      ManagerIndexQuestionData = getQuestionManagerOfHappinessData;
    }
    else if (MQuesIndextype === 2) {
      ManagerIndexQuestionData = getQuestionManagerOfEngagementData;
    }
    else if (MQuesIndextype === 4) {
      ManagerIndexQuestionData = getQuestionManagerOfStressData;
    }
    else if (MQuesIndextype === 5) {
      ManagerIndexQuestionData = getQuestionManagerOfManagerData;
    }




    let ManagerIndexDriverData = [];

    if (Mindextype === 1) {
      ManagerIndexDriverData = getDemographicIndexHappinessData;
    }
    else if (Mindextype === 2) {
      ManagerIndexDriverData = getDemographicIndexEngagementData;
    }
    else if (Mindextype === 4) {
      ManagerIndexDriverData = getDemographicIndexStressData;
    }
    else if (Mindextype === 5) {
      ManagerIndexDriverData = getDemographicIndexManagerData;
    }




    let { showAllQuestions, showOtherTypes, showReport, showSidebar, CurrentSurveyId, indexType, showOverview, showIndex, showManagerList, showAdvanceComparision, showTextAnalysis, showStrengthAndWeaknesses, showManagerActionPlan, DynaObj, ManagerDynaObj, QuesDynaObj } = this.state;
    let { getAllParameterListData, getAllManagerActionPlanData, getManagerActionPlanData, getIndexData, getDemographicIndexForManagerData, wordCloudTextData, neighborGraphData, getRespondantDetailsData } = dashboard;

    // ////console.log('showOverview: ',showOverview)
    // ////console.log('showIndex: ',showIndex)
    // ////console.log('showManagerList: ',showManagerList)
    // ////console.log('sshowAdvanceComparision: ',showAdvanceComparision)
    let ManagerNameIdList = getDemographicIndexForManagerData;

    if (ClientProfileData && ClientProfileData.role && ClientProfileData.role === "MNGR") {
      this.props.history.push(`/app/manageractionplan`)
    } else if (ClientProfileData && ClientProfileData.role && ClientProfileData.role === "EMP") {
      this.props.history.push(`/app/employeeactionplan`)
    }


    //console.log('------------( getDemographicIndexEngCompOverallData )----->',getDemographicIndexEngCompOverallData)


    function checkIndex(key) {
      let check = false
      if (key === 1) { check = true }
      else if (key === 2) { check = true }
      else if (key === 3) { check = true }
      else if (key === 4) { check = true }
      else if (key === 5) { check = true }
      else if (key === 6) { check = true }
      else if (key === 7) { check = true }
      else if (key === 9) { check = true }
      else { check = false }

      return check;
    }

    let IndexList = []
    let newFilterObj = {}
    if (getIndexData) {



      IndexList = Object.keys(getIndexData).map((key) => [Number(key), getIndexData[key]]);

      if (getIndexFilterData) {
        if (IndexList && IndexList.length > 0) {
          IndexList.forEach((data) => {
            if (checkIndex(data[0])) {
              newFilterObj[data[0]] = getIndexFilterData[data[0]];
            }
          });
        };
      }
    }



    let IndexDimension = {
      "Happiness": ['Time', 'Being', 'Relationship', 'WorkLife', 'Achivement', 'Meeningfull'],
      "Engagement": ['Pride', 'Presence', 'Performance'],
      "Stress": ['Recognition', 'WorkSatisfaction', 'Inclusion', 'Collaboration', 'Empowerment'],
      "Manager": ['Connect', 'ACT', 'Respect', 'Empower'],
      "Leader": ['Vision', 'Impact', 'Execute', 'Wisdom']
    }


    function CountRestrict(count, isOverall, section) {
      let isCountLessThanFive = false;

      // ////////console.log("count---",count)

      if (count !== "NaN") {
        if (isOverall) {
          isCountLessThanFive = false;
        }
        else {
          if (section === "rwa") {
            if (parseInt(count) < 30) {
              isCountLessThanFive = true;
            }
          }
          else {
            if (parseInt(count) < 5) {
              isCountLessThanFive = true;
            }
          }

        }
      }
      // ////////console.log("isCountLessThanFive---",isCountLessThanFive)

      return isCountLessThanFive
    }

    let { getSurveyData, getIndexByFilterDMGData, parameterList } = survey;

    function GetParameterLabel(name) {
      let AllParameterObj = {};
      let NewParameterList = getParameterLabelByNameData && getParameterLabelByNameData.length > 0 ? getParameterLabelByNameData : []
      if (NewParameterList && NewParameterList.length > 0) {
        NewParameterList.forEach((item) => {
          AllParameterObj[item.name] = item && item.label ? item.label : item.name;
        });
      }
      return (AllParameterObj && AllParameterObj[name] ? AllParameterObj[name] : name);
    }

    //console.log("1 getParameterLibraryLabelByParentNameData------>",getParameterLibraryLabelByParentNameData)

    function GetParameterDesc(name) {
      let AllParameterObj = {};
      if (getParameterLibraryLabelByParentNameData && getParameterLibraryLabelByParentNameData.length > 0) {
        getParameterLibraryLabelByParentNameData.forEach((item) => {
          //console.log("name------>",name)
          //console.log("item------>",item)
          AllParameterObj[item.name] = item && item.desc ? item.desc : "";
        });
      }
      //console.log("name------>",name)
      //console.log("AllParameterObj------>",AllParameterObj)
      //console.log("getParameterLibraryLabelByParentNameData------>",getParameterLibraryLabelByParentNameData)

      return (AllParameterObj && AllParameterObj[name] ? AllParameterObj[name] : "");
    }





    let surveyLogo = "";

    let surveyId = getSurveyData && getSurveyData.id ? getSurveyData.id : "";

    if (surveyId === "618b673c9503f41bad61667f") {
      surveyLogo = 'img/logo/kotaklifelogo.png';
    }
    else if (surveyId === "61541d6cf0f59b75a9b50d44") {
      surveyLogo = 'img/logo/tcillogo.jpeg';
    }
    else if (surveyId === "61b1850ada0e4ed403288e52") {
      surveyLogo = 'img/logo/tcellogo.jpg';
    }
    else if (surveyId === "61c06c871a708fbb5cf1c9a3") {
      surveyLogo = 'img/logo/bppllogo.png';
    }
    else if (surveyId === "5fa04d316ede0f0336066cce") {
      surveyLogo = '';
    }
    else if (surveyId === "621dc1de0f73b3a8e532f1b5") {
      surveyLogo = 'img/logo/axissecuritylogo.png';
    }
    else if (surveyId === "639ac5961282de9a6f9b545f") {
      surveyLogo = 'img/logo/gennovalogo.png';
    }
    else if (surveyId === "63d754f650a5f4d496779086") {
      surveyLogo = 'img/logo/gennovalogo2.jpg';
    }
    else if (surveyId === "65cef2f7fb55390f82124cca") {
      surveyLogo = 'img/logo/udyoglogo.png';
    }
    else if (surveyId === "634e45f5103735abef369ba7") {
      surveyLogo = 'img/logo/rillogo.jpeg';
    }


    ////////console.log("<><><><< getQuestionCommentsData- <><><><<--->",getQuestionCommentsData)


    function getEMPFilterLabel(name) {

      let label = name;
      if (EmpFilterData && EmpFilterData.length > 0) {
        let getIndex = EmpFilterData.findIndex(prev => prev.name === name);
        if (getIndex !== 1) {
          label = EmpFilterData && EmpFilterData[getIndex] && EmpFilterData[getIndex].label ? EmpFilterData[getIndex].label : name
        }

      }
      return label
    }




    function getSurveyDetails(name) {
      return getSurveyData && getSurveyData[name] ? getSurveyData[name] : "NaN";
    }

    let isLifecycle = false
    if (getSurveyData && getSurveyData.isRiskSurveyType === "DEFAULTDAYS") {
      isLifecycle = true
    }



    let OptionList = [
      {
        "name": "Dashboard",
        "template": 1
      },
      {
        "name": "Question Analysis",
        "template": 2
      }
    ]



    return (
      <>
        <LoadingOverlay
          active={loading}
          // active={false}

          spinner
          text='Loading Data...'
          className="loader"
        >


          <div className='h-screen overflow-hidden bg-gray-50'>

            <Header
              ClientProfileData={ClientProfileData}
              handleLogout={this.handleLogout}
              SurveyList={SurveyList}
              CurrentSurveyId={CurrentSurveyId}
              props={this.props}
              onOverviewFromHeader={this.onOverviewFromHeader}
              getSurveyData={getSurveyData}
              companyName={getCompanyNameOnlyData && getCompanyNameOnlyData.name ? getCompanyNameOnlyData.name : ""}
            />



            {/* header end */}
            {/*sub nav bar 1 */}
            <div className="px-4  md:flex  justify-between items-center bg-white
      border-b py-2.5">
              <div className="flex justify-between w-full space-x-2 text-gray-700">

                <div className='flex space-x-2'>
                  {/* <span className="material-icons-outlined -mt-0.5">description</span> */}
                  <h2 className="text-sm font-medium truncate"> {getSurveyDetails("name")} </h2>
                </div>

                <div onClick={() => this.handleMobileSidebar(!this.state.showMobileSidebar)}>
                  <span className="cursor-pointer material-icons lg:hidden">menu_open</span>
                </div>

              </div>

              {/* <div className="inline-flex items-center">
          <button className="px-4 py-2 text-xs font-medium uppercase border rounded-full border-sky-500 text-sky-500 ">VIEW CAMPAIGN</button>
        </div> */}

            </div>
            {/* end */}
            <div className="flex w-full" style={{ height: 'calc(100% - 6.5rem)' }}>
              {/*this is side bar  */}


              <Sidebar
                SurveyList={SurveyList}
                onOverview={this.onOverview}
                onOptionType={this.onOptionType}
                onManagerList={this.onManagerList}
                onOrganizationCore={this.onOrganizationCore}
                indexType={indexType}
                getIndexData={newFilterObj}
                CurrentSurveyId={CurrentSurveyId}
                showSidebar={showSidebar}
                handleSidebar={this.handleSidebar}
                showMobileSidebar={this.state.showMobileSidebar}
                isLifecycle={isLifecycle}
              />







              {showOverview ?
                <Overview
                  getIndexData={newFilterObj}
                  SurveyList={SurveyList}
                  CurrentSurveyId={CurrentSurveyId}
                  onOptionType={this.onOptionType}

                  getHappinessIndexV2Data={getHappinessIndexV2Data}
                  getEngagementIndexV2Data={getEngagementIndexV2Data}
                  getStressIndexV2Data={getStressIndexV2Data}
                  getManagerIndexV2Data={getManagerIndexV2Data}
                  getRespondantDetailsData={getRespondantDetailsData}

                  EmpFilterData={EmpFilterData}
                  getIndexFilterData={getIndexFilterData}

                  getQuestionOthersResultData={getQuestionOthersResultData}

                  onAllQuestions={this.onAllQuestions}

                  getQuestionAvgScoreData={getQuestionAvgScoreData}

                  onCustomScoreCard={this.onCustomScoreCard}




                  get360Overview={this.get360Overview}
                  get360DimensionItemDetails={this.get360DimensionItemDetails}

                  get360DimensionRelationScoreData={get360DimensionRelationScoreData}

                  get360DimensionQuestionsData={get360DimensionQuestionsData}

                  getIndexByFilterDMGData={getIndexByFilterDMGData}
                  getEMPFilterLabel={getEMPFilterLabel}
                  CountRestrict={CountRestrict}
                  GetParameterLabel={GetParameterLabel}
                  loading={loading}
                  indexType={indexType}
                  getCommentData={getCommentData}
                  handle360QuestionFilter={this.get360DimensionItemDetails}
                  getAnsweredEmployeesData={getAnsweredEmployeesData}

                  get360RelationResponse={this.get360RelationResponse}
                  get360RelationResponseData={get360RelationResponseData}

                  getFeedbackAndQuestionName={this.getFeedbackAndQuestionName}
                  getFeedbackAndQuestionNameData={getFeedbackAndQuestionNameData}
                  getTextAndQuestionNameData={getTextAndQuestionNameData}
                  GetParameterDesc={GetParameterDesc}

                  getSurveyData={getSurveyData}

                  surveyLogo={surveyLogo}

                  getTemp360EmpStrengthCall={this.getTemp360EmpStrengthCall}

                  getTemp360EmpStrengthData={getTemp360EmpStrengthData}

                />
                : null}




            </div>
          </div>



        </LoadingOverlay>
      </>
    );
  }
}
function mapStateToProps(state) {
  // //////////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard, questions, survey, general } = state;
  return {
    loggingIn,
    users,
    dashboard,
    questions,
    survey,
    general
  };
}
export default connect(mapStateToProps)(Dashboard);
