import React from "react";
export default function ConfirmMail(props) {
  let { showLoadButton, closeLoadReport, onLoadDMGReportConfirmation } = props;

  return (

    <div className="fixed top-0 bottom-0 left-0 right-0 z-40 flex items-center justify-center w-full h-full bg-black bg-opacity-40 " >



      <div className="block w-5/6 mx-auto overflow-hidden bg-white rounded-lg shadow xl:w-4/12 lg:w-3/6 md:w-4/6">
        <div className="text-white cursor-default bg-white-600"> </div>
        <div className="bg-white-600">
          <div className="">

            {showLoadButton ?

              <div className="">
                <div className="flex items-center justify-between p-4 border-b">
                  <h1 className="text-lg font-semibold md:text-xl line-clamp-1 text-slate-800"> Your report is ready for load</h1>

                  <div onClick={() => onLoadDMGReportConfirmation(false)} className="inline-flex items-center justify-center w-10 h-10 text-gray-500 bg-gray-100 rounded-full cursor-pointer hover:text-gray-800" >
                    <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
                    </svg>
                  </div>

                </div>
                <div class="p-4">
                  <div class=" bg-blue-100 p-6">
                    <img src="img/managerreport/manager_scorecard.png" class="w-2/6 mx-auto" />
                  </div>
                </div>

                {/* <svg xmlns="http://www.w3.org/2000/svg" className="w-20 h-20 mx-auto my-10 text-gray-800" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                </svg> */}
              </div>


              :
              <div className="pt-1 text-lg font-semibold text-center">
                Report will not be shown for the following conditions<br />
                <br />
                1. Response count is less than 5<br />
                or<br />
                2. Report access is limited<br />

              </div>

            }
          </div>

          <div className="flex items-center justify-center p-4 mb-3">
            {showLoadButton ?
              <button onClick={() => onLoadDMGReportConfirmation(true)} class="cursor-pointer bg-[#2196F3] text-white space-x-1 text-base  border-[#2196F3] flex justify-center items-center px-4 py-2.5 rounded-md w-full ">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-plus" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"></path><rect x="9" y="3" width="6" height="4" rx="2"></rect><line x1="9" y1="12" x2="9.01" y2="12"></line><line x1="13" y1="12" x2="15" y2="12"></line><line x1="9" y1="16" x2="9.01" y2="16"></line><line x1="13" y1="16" x2="15" y2="16"></line>
                </svg>
                <span> Load Report</span>
              </button>
              // <button onClick={() => onLoadDMGReportConfirmation(true)} className="bg-blue-600 rounded-md font-medium text-base text-white py-2.5 px-6">Load Report11111</button>
              :
              <button onClick={() => onLoadDMGReportConfirmation(false)} className="cursor-pointer bg-[#2196F3] text-white space-x-1 text-base  border-[#2196F3] flex justify-center items-center px-4 py-2.5 rounded-md w-full ">OK</button>
            }
          </div>
        </div>
      </div>



    </div>

  );
}
