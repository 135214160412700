import React, { Component } from "react";
// import EmpChart from './Chart/EmpChart';
import Header from "../Components/Header";
import HeaderSub from "../Components/HeaderSub";

class Employee extends Component {
  render() {
    let {
      getEMPFilterLabel,
      getDemographicIndexMultiFilterData,
      surveyLogo,
      CountRestrict,
      GetParameterLabel,
    } = this.props;

    // let OrgCoreDriverData = {};
    // if(getOrgIndexV2Data && getSurveyData && getSurveyData.orgList && getSurveyData.orgList.length>0){
    //   getSurveyData.orgList.forEach((org)=>{
    //     OrgCoreDriverData[org.name] = getOrgIndexV2Data[org.name];
    //   })
    // }

    // if(OrgCoreDriverData){
    //   valueList = Object.keys(OrgCoreDriverData).map((key) => [ key, OrgCoreDriverData[key] ]);
    // }

    let FinalData = [];
    if (
      getDemographicIndexMultiFilterData &&
      getDemographicIndexMultiFilterData.length > 0
    ) {
      getDemographicIndexMultiFilterData.forEach((item) => {
        //////console.log("0-----0000--->",item)

        let tempData = {
          xAxis: item.xAxis,
          data: [],
        };
        let innerList = [];
        if (item && item.Data && item.Data.length > 0) {
          item.Data.forEach((inner) => {
            let score = CountRestrict(inner.count) ? "NA" : inner[2];

            innerList.push([inner.name, score]);
          });
        }

        innerList.sort(
          (a, b) =>
            (a && a[0] && a[0].toString()
              ? a[0].toString().charCodeAt(0)
              : "") -
            (b && b[0] && a[0].toString() ? b[0].toString().charCodeAt(0) : "")
        );
        tempData["data"] = innerList;
        FinalData.push(tempData);
      });
    }


    function chunkList(array) {
      const chunkSize = 11;
      let list = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        const chunk = array.slice(i, i + chunkSize);
        list.push(chunk);
      }
      //console.log("list--->", list);
      return list;
    }



    return (
      <>
        {FinalData && FinalData.length > 0 ?
          chunkList(FinalData).map((NewQues, indexX) =>

            <section className=" px-8 py-6 page-break">
              {/* report header */}
              <div class="flex items-center justify-between border-b py-4 text-[#212121]/80 ">
                <p class="font-medium italic text-base ">Employee Wellness Survey</p>
                <p class="font-medium italic text-base">15 March, 2024</p>
              </div>


              {/* Engagement Across Demographics101010101010 report design */}
              <main className="w-full ">
                {NewQues && NewQues.length > 0
                  ? NewQues.map((item) => (
                    <>
                      {/* <div className="p-2 pl-10 text-xl font-medium text-blue-500 capitalize" style={{ color: "#2196f3" }}>{getEMPFilterLabel(item.xAxis)}</div> */}

                      <table className="w-full ">
                        <tbody className="divide-y-2 divide-white ">
                          {item && item.data && item.data.length > 0
                            ? item.data.map((itemData, index) => (
                              <>
                                {index % 11 === 0 ? (
                                  <>
                                    {index ? (
                                      <tr className="text-lg text-gray-500 capitalize ">
                                        <th
                                          className="w-12 p-2 font-bold text-left text-gray-700 "
                                          colSpan={4}
                                        >
                                          Continue...
                                        </th>
                                      </tr>
                                    ) : null}

                                    <tr className="my-2 page-break">
                                      <td colSpan={4} className="text-left ">
                                        <div className=" -mx-8">
                                          <Header surveyLogo={surveyLogo} />

                                          <div className>
                                            <div className="w-full px-8 py-6 capitalize ">
                                              <div className="text-indigo-700 opacity-90">
                                                {/* <p className="flex items-center">Emerging Areas of Strength for </p> */}
                                              </div>
                                              <h1 className="text-2xl text-[#3D405B] font-medium  capitalize">
                                                Engagement Across Demographics
                                              </h1>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>

                                    <tr className="text-left text-[#212121] capitalize border-white border-b bg-[#DBEEFD] text-sm border-l border-t">
                                      <th className="w-[25%] font-medium p-2 text-left border-r-2 border-white" >
                                        {getEMPFilterLabel(item.xAxis)}
                                      </th>
                                      <th className="w-[15%] font-medium p-2 text-right border-r-2 border-white" >
                                        Compare
                                      </th>
                                      <th className="w-[35%] font-medium p-2 text-left border-r-2 border-white" >
                                        Score
                                      </th>
                                      <th className="w-[15%] font-medium p-2 text-center border-r-2 border-white" >
                                        Difference
                                      </th>
                                    </tr>
                                  </>
                                ) : null}

                                <tr className="border-b-2 text-[#212121] text-sm border-l border-white bg-[#f5f5f5]">
                                  <td className="p-2 font-medium capitalize border-r-2 border-white" rowSpan={2}>
                                    {GetParameterLabel(itemData[0])}
                                  </td>
                                  <td className="p-2 text-sm text-right border-r-2 border-white bg-[#e9e9e9]">
                                    score
                                  </td>
                                  <td className="p-2 text-sm border-r-2 border-white bg-[#e9e9e9]" >
                                    <div className="h-6 w-full bg-[#CFD8DC]">
                                      <div
                                        className="w-full h-6 bg-blue-400 flex items-center"
                                        style={{
                                          background: "#1E88E5",
                                          width:
                                            (itemData &&
                                              itemData[1] &&
                                              itemData[1] !== "NA"
                                              ? itemData[1]
                                              : "0") + "%",
                                        }}
                                      >
                                        <span className="text-sm text-white px-2">  {itemData &&
                                          itemData[1] &&
                                          itemData[1] !== "NA" &&
                                          itemData[1].toFixed(2)
                                          ? itemData[1].toFixed(2) + "%"
                                          : "NA"}</span>
                                      </div>
                                    </div>

                                  </td>
                                  <td rowSpan={2} className=" px-2 text-sm font-medium text-center text-[#4CAF50] ">+88.29 </td>
                                </tr>
                                <tr className="bg-[#f5f5f5]">
                                  <td className="p-2 text-sm text-right border-r-2 border-white">
                                    Overall
                                  </td>
                                  <td className="p-2 text-sm border-r-2 border-white" >

                                    <div className="h-6 w-full bg-[#CFD8DC]">
                                      <div
                                        className="w-full h-6 bg-blue-400 flex items-center"
                                        style={{
                                          background: "#9C27B0",
                                          width:
                                            (itemData &&
                                              itemData[1] &&
                                              itemData[1] !== "NA"
                                              ? itemData[1]
                                              : "0") + "%",
                                        }}
                                      >
                                        <span className="text-sm text-white px-2">  {itemData &&
                                          itemData[1] &&
                                          itemData[1] !== "NA" &&
                                          itemData[1].toFixed(2)
                                          ? itemData[1].toFixed(2) + "%"
                                          : "NA"}</span>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            ))
                            : null}
                        </tbody>
                      </table>
                    </>
                  ))
                  : null}
              </main>


              {indexX < chunkList(FinalData).length - 1 ? (
                <tr className="text-lg text-gray-500 capitalize bg-white">
                  <td
                    colSpan={5}
                    className="font-normal p-2 text-sm text-left text-[#3D405B]/70 "
                  >
                    Continue...
                  </td>
                </tr>
              ) : null}


            </section>
          ) : null}
      </>

    );
  }
}
export default Employee;
