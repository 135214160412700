import React, { Component } from "react";
import moment from "moment";
import Header from "../Components/Header";

export default class FilterSummary extends Component {
  render() {
    let {
      FilterValues,
      getEMPFilterLabel
    } = this.props;

    console.log("FilterValues--------->", FilterValues);

    return (
      <main>
        <section className="  space-y-10">
          <div className="capitalize w-full px-8 py-6 space-y-8 flex flex-col">
            <h1 className="text-2xl text-[#3D405B] font-semibold border-b border-[#DDDDDD] pb-4 capitalize w-full">
              Filter Summary
            </h1>

            <p>Selected filter name and it's values</p>

            <div className="grid grid-cols-2 gap-6">

              {FilterValues && FilterValues.length>0?
                FilterValues.map((element)=>
                  <div className="border p-4 rounded-md space-y-4">
                    <p>{element.level}</p>
                    <div className="flex justify-start">
                      {element && element.valueList && element.valueList.length?
                       element.valueList.map((element)=>
                       <div className="flex items-center flex-row gap-2 bg-[#F1F5F9] px-4 py-1 rounded-full">
                        <img src="/img/icon/done-check.svg" alt="done-check" className="w-4"/> <p className="text-base font-medium">{element}</p></div>
                      ):null}
                      </div>

                  </div>
                ):null}

              

            </div>
          </div>
        </section>
      </main>
    );
  }
}
