import React, { Component } from "react";
import Header from "../Components/Header";

class OrgCoreItems extends Component {
  render() {
    let {
      getQuesOverallData,
      setPDF,
      SummaryName,
      getQuestionFilterData,
      getIndexData,
      indexTemplate,
      GetParameterLabel,
      CountRestrict,
      surveyLogo,
      getIndexRWAData,
    } = this.props;

    let QuestionData = getQuestionFilterData;


    let paraList = [];

    let count = 0;
    let rowSpan = 0;
    let NewParaList = [];

    if (QuestionData && QuestionData.length > 0) {
      QuestionData.forEach((ques) => {
        if (
          paraList.findIndex((prev) => prev === ques.parameterDimensionName) ===
          -1
        ) {
          paraList.push(ques.parameterDimensionName);
          count += 1;
        } else {
        }
      });

      let obj = {};
      paraList.forEach((para) => {
        obj[para] = 0;
        QuestionData.forEach((ques) => {
          if (para === ques.parameterDimensionName) {
            obj[para] += 1;
          }
        });
      });

      NewParaList = Object.keys(obj).map((key) => [key, obj[key]]);
      //   console.log('data NewParaList: ',NewParaList)

      rowSpan = QuestionData.length / count;

      // console.log('Your Engagement paraList: ',NewParaList,count,rowSpan,QuestionData.length)
    }
    // console.log('ORG ==== NewParaList ===== > : ',NewParaList)
    // console.log('ORG ==== QuestionData ===== > : ',QuestionData)

    let NewQuesList = [];
    if (QuestionData && QuestionData.length > 0) {
      QuestionData.forEach((ques) => {
        let total =
          ques["1"] + ques["2"] + ques["3"] + ques["4"] + ques["5"] + ques["6"];

        let score = parseFloat(
          (((ques["5"] + ques["6"]) * 100) / total).toFixed(2)
        );

        NewQuesList.push({
          ...ques,
          score: CountRestrict(ques.count) ? "NaN" : score,
        });
      });
    }

    // NewQuesList.sort((a, b) => b.score - a.score);
    NewQuesList.sort(
      (a, b) =>
        (a && a.parameterDimensionName && a.parameterDimensionName.toString()
          ? a.parameterDimensionName.toString().charCodeAt(0)
          : "") -
        (b && b.parameterDimensionName && b.parameterDimensionName.toString()
          ? b.parameterDimensionName.toString().charCodeAt(0)
          : "")
    );


    function calcFavourability(quesZ) {
      let total =
        quesZ["1"] +
        quesZ["2"] +
        quesZ["3"] +
        quesZ["4"] +
        quesZ["5"] +
        quesZ["6"];
      let scoreX = parseFloat(
        (((quesZ["5"] + quesZ["6"]) * 100) / total).toFixed(2)
      );
      return scoreX;
    }

    function calcOverall(queItem) {
      let overallX = 0;
      if (getQuesOverallData && getQuesOverallData.length > 0) {
        let getIndex = getQuesOverallData.findIndex(
          (prev) => prev.name === queItem.name
        );
        let overallQue = getQuesOverallData[getIndex];
        overallX = calcFavourability(overallQue);
      }
      return overallX;
    }



    console.log("8 NewQuesList---->", NewQuesList)


    // itemsArray.sort(function(a, b){  
    //   return sortingArr.indexOf(a) - sortingArr.indexOf(b);
    // });

    console.log('getIndexRWAData--->', getIndexRWAData)

    let rwaDrivers = getIndexRWAData && getIndexRWAData.rwa && getIndexRWAData.rwa.driver && getIndexRWAData.rwa.driver.length > 0 ? getIndexRWAData.rwa.driver : []

    let QuestionList = [];

    for (var ix = 0; ix < rwaDrivers.length; ix++) {
      let rwaName = rwaDrivers[ix];
      for (var iy = 0; iy < NewQuesList.length; iy++) {
        let question = NewQuesList[iy];
        if (question.parameterDimensionName === rwaName) {
          QuestionList.push(question);
        }
      }
    }

    console.log('QuestionList--->', QuestionList)


    return (
      <>
        <div>
          {/* Engagement RWA Dimension Priorities1111111111111 report design */}
          <div className="pt-4">
            <Header surveyLogo={surveyLogo} />

            <div className>
              <div className="w-full px-8 py-6 capitalize ">
                <h1 className="text-2xl text-[#3D405B] font-semibold border-b border-[#DDDDDD] pb-4 capitalize">
                  {SummaryName + " RWA Dimension Priorities"}
                </h1>
              </div>
            </div>
          </div>


          <main className="w-full px-8">
            {setPDF ? (
              <>
                <table
                  className="w-full border border-white"
                  id={"Organization" + indexTemplate.toString()}
                >
                  <tbody className="bg-[#F1F5F9]">
                    <tr className="text-left text-[#3D405B] capitalize  border-b border-white bg-[#F1F5F9] text-sm border-l border-t">
                      <th className="w-[25%] font-medium p-2  text-left border-r border-white">
                        Dimension
                      </th>
                      <th className="w-[50%] font-medium p-2  text-left border-r border-white">
                        Question
                      </th>
                      <th className="w-[25%] font-medium p-2 text-center border-r border-white">
                        favorability score
                      </th>
                    </tr>

                    {QuestionList && QuestionList.length > 0
                      ? QuestionList.map((question, index) => (
                        <>
                          {index % 12 === 0 && index !== 0 ? (
                            <>
                              <tr className="page-break bg-[#F1F5F9] text-[#3D405B] capitalize border-b border-white text-sm">
                                <th className="w-[20%] font-medium p-2 text-left border-r border-white">
                                  Dimension
                                </th>
                                <th className="w-[30%] font-medium p-2 text-left border-r border-white">
                                  Question
                                </th>
                                <th className="w-[30%] font-medium p-2 text-center border-r border-white">
                                  favorability score
                                </th>
                              </tr>
                            </>
                          ) : null}

                          <tr className="border-b text-[#3D405B] text-sm border-l border-white">
                            <td className="p-2 font-medium capitalize border-r border-white">
                              {question && question.parameterDimensionName
                                ? GetParameterLabel(
                                  question.parameterDimensionName
                                )
                                : ""}
                            </td>
                            <td className="p-2 text-sm border-r border-white">
                              {question.name}
                            </td>

                            <td className="p-2 text-sm border-r border-white">
                              <div className="flex items-center pl-2 ">
                                <div className="w-full space-y-0">
                                  <div className="flex flex-row py-2 items-center gap-1.5">

                                    <div className="h-3 w-full bg-[#CFD8DC]">
                                      <div
                                        className="h-3 w-full bg-[#2196F3]"
                                        style={{
                                          background: "#2196F3",
                                          width:
                                            (question &&
                                              question.score &&
                                              question.score !== "NA"
                                              ? question.score
                                              : 0) + "%",
                                        }}
                                      />
                                    </div>
                                    <div className=" text-sm  font-medium text-left w-[90px] flex   overflow-hidden">
                                      {question && question.score
                                        ? question.score
                                        : 0}
                                      %
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </td>

                          </tr>
                        </>
                      ))
                      : null}
                  </tbody>
                </table>
              </>
            ) : null}
          </main>
        </div>
      </>
    );
  }
}
export default OrgCoreItems;
