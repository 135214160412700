import React, { Component } from "react";
import Header from "../Components/Header";

class Employee extends Component {
  render() {
    let {
      getDemographicIndexEngCompOverallData,

      getDemographicIndexMultiFilterEngCompData,
      getDemographicIndexMultiFilterEngComp2Data,

      getEMPFilterLabel,
      GetParameterLabel,
      getOrgIndexV2Data,
      getSurveyData,
      surveyLogo,
      CountRestrict,
      showYearData,
      yearValue1,
      yearValue2,
      indexTemplate,

      questionData
    } = this.props;



    console.log("questionData-------->", questionData)

    let topicCloudData = questionData && questionData.response && questionData.response.topic && questionData.response.topic.length > 0 ? questionData.response.topic : []
    console.log("topicCloudData-------->", topicCloudData)

    let topicObjList = topicCloudData && topicCloudData.length > 0 ? topicCloudData.map((ele) => ({
      "sentence": ele["sentence"],
      "sentiment": ele["sentiment"],
      "suggestion": ele["suggestion"],
      "keyword": ele["keyword"]
    })) : [];

    const groupTopics = topicObjList.reduce((groups, item) => {
      const group = (groups[item.keyword] || []);
      group.push(item);
      groups[item.keyword] = group;
      return groups;
    }, {});

    function getSentences(obj, key) {
      let sentence = []
      let list = []
      if(key && obj && obj[key].length > 0){
        list = obj[key];
        list.sort((b,a)=>a.sentence.length -b.sentence.length);
      }
      if (list && list.length > 0) {
        for (let i = 0; i < list.length; i++) {
          if (i < 5) {
            sentence.push(list[i])
          } else {
            break;
          }
        }
      }
      return sentence
    }

    let TopicList = Object.keys(groupTopics).map((key) => ({
      "keyword": key,
      "sentences": getSentences(groupTopics, key)
    }))


    console.log("TopicList-------->", TopicList)


    return (
      <div>

        <div className="mb-12 page-break">
          <main className="w-full px-8 space-y-6">
          <div className="">
                    <div className="text-left ">
                      <div className="pt-4 -mx-12">
                        <Header surveyLogo={surveyLogo} />
                      </div>
                      <div className>
                        <div className="w-full py-6 capitalize ">
                          <h1 className="text-2xl text-[#3D405B] font-semibold border-b border-[#DDDDDD] pb-4 capitalize">
                            Top 5 Topic with Comments
                          </h1>
                        </div>
                      </div>
                    </div>
                    <p>{questionData && questionData.name}</p>
                  </div>

                  <table className="w-full border border-white">
                    <tbody>
                      {TopicList && TopicList.length > 0
                        ? TopicList.map((ele, index) => (
                          <>
                            {index % 15 === 0 ? (
                              <>
                                {index ? (
                                  <tr className="text-lg text-gray-500 capitalize">
                                    <th className="w-12 p-2 font-bold text-left text-gray-700" colSpan={5}>
                                      Continue...
                                    </th>
                                  </tr>
                                ) : null}

                                <tr className="page-break text-left text-[#212121] capitalize border-b bg-[#F1F5F9] border-white text-sm border-l border-t">
                                  <th className="w-[20%] font-medium p-2  text-left border-r border-white text-base">
                                    Topics
                                  </th>
                                  <th className="w-[60%] font-medium p-2  text-left border-r border-white text-base" colSpan={3}>
                                    Comments
                                  </th>

                                </tr>
                              </>
                            ) : null}

                            <tr className="border-b text-[#3D405B] bg-[#F1F5F9] border-white text-sm border-l">
                              <td className="p-2 font-medium capitalize border-r border-white">
                                {ele && ele.keyword}
                              </td>
                              <td className=" font-normal capitalize border-r border-white" colSpan={3}>
                                {ele && ele.sentences && ele.sentences.length > 0 ?
                                  ele.sentences.map((text) =>
                                    <>
                                      <p className="w-full space-y-0 border-b border-white p-2">
                                        {text && text.sentence}
                                      </p>
                                    </>) : null}

                              </td>
                            </tr>

                          </>
                        ))
                        : null}
                    </tbody>
                  </table>
          </main>
        </div>
      </div>
    );
  }
}
export default Employee;
