import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";
import BarSet from './BarSet';


class NPSChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensionValueList: [],
      EmpIndexA: 0,
      EmpIndexB: 4
    }
  }
  componentDidMount() {

  }

  render() {
    let { AnswerObj, size, name, questionName } = this.props;

    // function ColorFn(key){
    //   if(templateId===11){
    //     return {color:key==="Yes"?'#00cc66':'#ff5050'}
    //   }
    //   else{
    //     return {}
    //   }
    // }

    let DataList = [];

    let totalCount = 0;

    let Promoters = 0;
    let Passsives = 0;
    let Distractors = 0;

    if (AnswerObj) {
      Object.keys(AnswerObj).forEach(key => {
        let score = parseInt(AnswerObj[key]);

        totalCount += score;

        if (parseInt(key) >= 9 && parseInt(key) <= 10) {
          Promoters += score;
        }
        if (parseInt(key) >= 7 && parseInt(key) <= 8) {
          Passsives += score;
        }
        if (parseInt(key) >= 1 && parseInt(key) <= 6) {
          Distractors += score;
        }

      });
    }


    let PromotersPer = (Promoters * 100 / totalCount);
    let PasssivesPer = (Passsives * 100 / totalCount);
    let DistractorsPer = (Distractors * 100 / totalCount);

    let npsScore = ((PromotersPer) - (DistractorsPer)).toFixed(2);

    let PromotersColorRange = PromotersPer / 100;
    let PasssivesColorRange = (PasssivesPer / 100) + PromotersColorRange;
    let DistractorsColorRange = (DistractorsPer / 100) + PasssivesColorRange;


    let NPSObj = {
      "PromotersPer": PromotersPer.toFixed(2),
      "PasssivesPer": PasssivesPer.toFixed(2),
      "DistractorsPer": DistractorsPer.toFixed(2)
    }


    // DataList.push({
    //   name: key,
    //   value: score
    // });

    //console.log('NPSObj: ',NPSObj);

    // //console.log('npsScore: ',npsScore);



    let { stage, index } = this.props;

    return (
      <>

        <div className="flex justify-between pb-2 px-8 py-4  ">
          <div className="dropdown inline-block relative">
            <h1 className="text-lg font-medium truncate cursor-pointer">
              {/* {stage==2? 
                                <b>{"Q"+(index+1)+"."}</b>
                                :
                                <b>Q1.</b>
                                } */}
              {/* {questionName && questionName.length>30?questionName.slice(0,30)+"...":questionName}</h1> */}
              <span className='text-blue-400 pr-2'>Question:</span>{questionName ? questionName : ""}</h1>
            {/* 
                            <div className="cursor-pointer dropdown-menu hidden bg-white font-semibold absolute -left-4 -top-12 z-30 pt-1 text-lg rounded-md shadow-md p-4">
                            {questionName}
                            </div> */}
          </div>
        </div>


        <ReactEcharts
          style={{ height: size === 2 ? "260px" : "230px" }}
          option={{
            series: [{
              type: 'gauge',
              min: -100,
              max: 100,
              axisLine: {
                lineStyle: {
                  width: 15,
                  color: [
                    [0.6, '#ff4d4d'],
                    [0.8, '#ff9933'],
                    [1, '#00cc99']
                  ]
                }
              },
              pointer: {
                itemStyle: {
                  color: 'auto'
                }
              },
              axisTick: {
                distance: -30,
                length: 8,
                lineStyle: {
                  color: '#fff',
                  width: 2
                }
              },
              splitLine: {
                distance: -30,
                length: 30,
                lineStyle: {
                  color: '#fff',
                  width: 4
                }
              },
              axisLabel: {
                color: 'auto',
                distance: 25,
                fontSize: 10
              },
              detail: {
                valueAnimation: false,
                formatter: '{value}',
                color: '#757575',
                fontSize: 0

              },
              data: [{
                value: npsScore
              }]
            }]
          }}

        />
        <div className="text-center text-xl  font-bold -mt-14">{npsScore}</div>


        {size === 2 ?
          <div>
            <BarSet NPSObj={NPSObj} />
          </div>
          : null}

        {size !== 2 ?


          <div className="flex items-center justify-between mt-4 w-5/6 mx-auto">
            {/* 1 */}
            <div className="flex flex-col items-center justify-center text-xs">
              {/* <span className="material-symbols-outlined  text-green-500 mb-2" style={{ fontSize: '1.6rem' }}>thumb_up</span> */}
              
              <img src="img/icon/like.png" className='w-10 h-10'/>

              <p className="text-gray-600 text-sm font-medium">Promoters </p>
              <h1 className="text-sm ">({NPSObj["PromotersPer"]}%)</h1></div>
            <span className="font-semibold text-3xl text-gray-500">- </span>
            {/* 1 */}
            <div className="flex flex-col items-center justify-center text-xs">
              {/* <span className="material-symbols-outlined text-red-500 mb-2" style={{ fontSize: '1.6rem' }}>thumb_down_off_alt</span>  */}
              
              <img src="img/icon/unlike.png" className='w-10 h-10'/>

              <p className="text-gray-600 text-sm font-medium">Detractors </p>
              <h1 className="text-sm">({NPSObj["DistractorsPer"]}%)</h1></div>
            <span className="font-semibold text-xl text-gray-500">= </span>
            <div className="flex flex-col items-center justify-center text-xs">
              <span className="bg-green-400 w-16 h-16 rounded-full inline-flex p-2 justify-center items-center font-bold text-white text-lg">{npsScore}</span>
              <p className="text-gray-600 text-sm font-medium">NPS Score </p></div>


            {/* <div> <span className="bg-green-400 w-14 h-14 rounded-full inline-flex p-2 justify-center items-center font-bold text-gray-800 ">{npsScore}</span></div> */}
          </div>



          : null}





      </>
    );
  }
}
function mapStateToProps(state) {
  // //////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(NPSChart);
