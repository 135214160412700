import React, { Component } from "react";
import { connect } from "react-redux";
import ReactEcharts from "echarts-for-react";

class StackChart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    let { indexValue } = this.props;

    let responseRate = indexValue ? indexValue : 0;

    let DataNew = [
      {
        value: (100 - parseFloat(responseRate)).toFixed(2),
        itemStyle: {
          color: "#CFD8DC",
        },
        name: "",
      },
      {
        value: parseFloat(responseRate),
        itemStyle: {
          // color:"#6536d1"
          color: "#9576CD",
        },
        name: `${parseFloat(responseRate)}%`,
      },
    ]
    //////console.log("responseRate--->",responseRate)
    return (
      <>
        {/* <div className="stack-title">
            {currFilterName} 
          </div> */}
        <div>
          {true ? (
            <>
              <ReactEcharts
                style={{ height: "140px", width: "140px" }}
                option={{
                  series: [
                    {
                      name: "Access From",
                      type: "pie",
                      radius: ["80%", "70%"],
                      avoidLabelOverlap: true,
                      label: {
                        show: true,
                        position: "center",
                        fontSize: "18",
                        fontWeight: "bold",
                      },
                      emphasis: {
                        label: {
                          show: true,
                          fontSize: "18",
                          fontWeight: "bold",
                        },
                      },
                      labelLine: {
                        show: true,
                      },
                      data: DataNew,
                    },
                  ],
                }}
              />
            </>
          ) : null}
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // ////console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard,
  };
}
export default connect(mapStateToProps)(StackChart);
