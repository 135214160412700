import React from "react";

export default function OverallLabels(props) {
  let { Overall, labelObj, year1, year2, year1Label, year2Label } = props;

  return (
    <>
      <div className="flex  p-1 py-4 ">
        <div className="font-semibold  text-[#3D405B] flex justify-center items-center p-4 px-8 bg-[#F6F6F6] flex-shrink-0">
          <span>  OVERALL</span>
        </div>
        <div className="grid grid-cols-2 text-xs text-[#3D405B] w-full flex-1 border-collapse">
          {Overall && Overall.length > 0
            ? Overall.map((item) => (
              <div className="  w-full border p-4 ">
                <div className="flex flex-col gap-2 ">
                  <div className="flex items-center space-x-2">
                    <div
                      className="w-4 h-4 bg-indigo-700 "
                      style={{ background: item.color }}
                    />
                    <p className="text-sm text-[#212121]/80">
                      {labelObj && labelObj[item.name]
                        ? labelObj[item.name]
                        : item.name}{" "}
                    </p>
                  </div>

                  <div className="  space-y-2"> 
                    <div className="flex items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 8 9" fill="none">
                        <path d="M2.99995 1.87625H4.99995V4.54292H6.61328L3.99995 7.15625L1.38661 4.54292H2.99995V1.87625Z" fill="#F44336" />
                      </svg>
                      <div className="flex divide-x">


                        {year1?
                        <div className="flex space-x-2 items-center text-sm px-2 text-[#212121]">
                        <div
                            className="w-4 h-4 bg-gradient-to-b from-[#90CBF9] to-[#2196F3] " 
                          />

                          <span>{year1Label}</span>
                          <b className="font-medium">
                            {" ("}
                            {item && item.score && item.score.toFixed(2)
                              ? item.score.toFixed(2)
                              : 0}
                            {"%)"}
                          </b>
                        </div>
                        :null}
                        

                        {year2?
                        <div className="flex space-x-2 items-center text-sm px-2 text-[#212121]">
                        <div
                          className="w-4 h-4 bg-gradient-to-b from-[#B39DDB] to-[#673AB7]" 
                        />

                        <span>{year2Label}</span>
                        <b className="font-medium   ">
                          {"("}
                          {item && item.score2 && item.score2.toFixed(2)
                            ? item.score2.toFixed(2)
                            : 0}
                          {"%)"}
                        </b>
                      </div>
                        :null}
                        



                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
            : null}
        </div>
      </div>
    </>
  );
}
